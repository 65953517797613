// TS/VUE NOTES
// https://github.com/vuejs/vue/issues/7392
// https://stackoverflow.com/questions/47357763/vue-is-not-a-constructor-error-using-typescript-webpack
// https://vuejs.org/v2/guide/typescript.html
// https://medium.com/@FizzyInTheHall/run-typescript-mocha-tests-in-visual-studio-code-58e62a173575
// https://medium.com/@stefanledin/solve-the-you-are-using-the-runtime-only-build-of-vue-error-e675031f2c50
// https://vuejs.org/v2/guide/installation.html#Runtime-Compiler-vs-Runtime-only
// https://vue-multiselect.js.org/#sub-getting-started

// https://blog.devartis.com/custom-select-with-vue-js-39b010ddc1fb
// https://vuejs.org/v2/guide/computed.html#Computed-Setter
// https://vuejs.org/v2/guide/forms.html#Select-Options
// https://vuejs.org/v2/cookbook/form-validation.html#Base-Example
// https://vuejs.org/v2/guide/computed.html#Watchers

// https://stackoverflow.com/questions/12742082/nodejs-require-inside-typescript-file
// http://johnnyreilly.github.io/globalize-so-what-cha-want/#/?currency=false&date=false&message=false&number=true&plural=false&relativeTime=false&unit=false
// https://mariusschulz.com/blog/typescript-2-9-importing-json-modules
// https://hackernoon.com/import-json-into-typescript-8d465beded79
// https://github.com/globalizejs/globalize/blob/master/doc/cldr.md
// https://github.com/globalizejs/globalize/tree/b08574ae714f899e50ff6a4852d9b9b8b0bc2ba0#performance
// https://github.com/globalizejs/globalize/blob/master/doc/cldr.md
// https://github.com/globalizejs/globalize#number-module
// https://github.com/globalizejs/globalize/blob/b08574ae714f899e50ff6a4852d9b9b8b0bc2ba0/doc/api/number/number-formatter.md
// https://github.com/globalizejs/globalize/blob/b08574ae714f899e50ff6a4852d9b9b8b0bc2ba0/doc/api/number/number-parser.md

import Accordions from "./app/accordions";
import Tooltips from "./app/tooltips";
import AppView from "./app/view";
import AppInstallBar from "./app/app-install-bar";

// Load service workers if available
if ('serviceWorker' in navigator) {
	window.addEventListener("load", function () {
		navigator.serviceWorker.register('service-worker.js').then(function (reg) {
			console.log("Service worker registered", reg);
		});
	});
}

// Set up accordions and tooltips
Accordions.init();
Tooltips.init();

// Set up main Vue code
AppView.init();

// Set up PWA
new AppInstallBar().init();

