// https://love2dev.com/blog/beforeinstallprompt/

export default class AppInstallBar {
	init() {
		const a2hsContainer = document.getElementById('a2hs-container');
		const a2hsButton = document.getElementById('a2hs-button');
		const a2hsMessage = document.getElementById('a2hs-message');

		const installCallout = document.getElementById('install-callout');
		const installIcon = document.getElementById('install-icon');

		let deferredPrompt: any;

		/*
			beforeinstallprompt is not supported by all browsers, so we add the eventListener
			and wait for the event.preventDefault to cancel the event it if it isn't supported :)
		*/
		window.addEventListener("beforeinstallprompt", function (event) {
			event.preventDefault();
			console.log("installable");
			deferredPrompt = event;
			a2hsContainer.style.display = "";
			a2hsMessage.style.display = "";

			a2hsButton.addEventListener("click", function (event) {
				event.preventDefault();
				deferredPrompt.prompt()
					.then(function (promptEvent: any) {
						return deferredPrompt.userChoice;
					})
					.then(function (choiceResult: any) {
						if (choiceResult.outcome === 'accepted') {
							installCallout.remove();
							installIcon.remove();
							console.log('User accepted the A2HS');
						} else {
							console.log('User dismissed the A2HS');
						}
						a2hsMessage.style.display = "none";
						a2hsContainer.style.display = "none";
						deferredPrompt = null;
					})
					.catch(function (error: any) {
						console.log(error.message);
					});
			});
		});



		if (installCallout.style.display !== 'none') {
			//*TEST*/console.log("AlertBar is displayed");
			var hideAlertBar = function () {
				//*TEST*/console.log('Install Screen visibile');
				//*TEST*/console.log('hideAlertBar');
				(installCallout.querySelector('.installCallout__close') as HTMLElement).click();
				//*TEST*/console.log('AlertBar mutationObserver cancelled');
				observer.disconnect();
			}
			const observer = new MutationObserver(function (mutationsList: Array<MutationRecord>, observer: MutationObserver) {
				//*TEST*/console.log('AlertBar mutationCallback');
				for (const mutation of mutationsList) {
					if (mutation.type === 'attributes'
						&& mutation.attributeName == "class"
						&& (mutation.target as HTMLElement).classList.contains('screen--install')
					) {
						(document.querySelector('.main--install') as HTMLElement).ontransitionend = hideAlertBar;
					}
				}
			});
			observer.observe(document.body, { attributes: true, childList: false, subtree: false });

			if (document.body.classList.contains('screen--install')) {
				hideAlertBar();
			};
		}
	}
}
