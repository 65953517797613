export enum Units {
	METRIC = "metric",
	US_CUSTOMARY = "usCustomary",
	BAR = "bar",
	PSI = "psi",
	LB = "lb",
	KG = "kg",
	GPM = "gpm",
	LPM = "lpm",
	GAL = "gal.",
	LTR = "ltr.",
	MM = "mm",
	CM = "centimeter",
	CM_CAP = "Centimeter",
	CMS = "centimeters",
	CMS_CAP = "Centimeters",
	INCH = "inch",
	INCH_CAP = "Inch",
	INCHES = "inches",
	INCHES_CAP = "Inches"
}

export const Pages:any = {
	"CALC": "screen--calc",
	"RESULTS": "screen--results",
	"TECHNICAL_SERVICES": "screen--technicalServices",
	"SETTINGS": "screen--settings",
	"ADD_MATERIAL": "screen--settings_addMaterial",
	"SAVE_MATERIAL": "screen--settings_saveMaterial",
	"ADD_ORIFICE": "screen--settings_addOrifice",
	"SAVE_ORIFICE": "screen--settings_saveOrifice",
	"SETTINGS_CURRENCY": "screen--settings_currency",
	"INSTALL": "screen--install"
};

export const Localizations = {
	units: [
		{name: "US Customary", value: Units.US_CUSTOMARY},
		{name: "Metric", value: Units.METRIC}
	],

	currency: [
		{name: "US Dollar", description: "$"},
		{name: "Euro", description: "€"},
		{name: "British Pound", description: "£"},
		{name: "Chinese Yuan", description: "¥"}
	],

	numberFormatting: [
		{name: "USA", value: "en"},
		{name: "Canada", value: "en"},
		{name: "United Kingdom", value: "en"},
		{name: "China", value:"en"},
		{name: "Afghanistan", value:"fa"},
		{name: "Aland Islands", value:"sv"},
		{name: "Albania", value:"sq-AL"},
		{name: "Algeria", value:"ar"},
		{name: "American Samoa", value:"en"},
		{name: "Andorra", value:"ca-AD"},
		{name: "Angola", value:"pt"},
		{name: "Anguilla", value:"en"},
		{name: "Antarctica", value:"en"},
		{name: "Antigua and Barbuda", value:"en"},
		{name: "Argentina", value:"es"},
		{name: "Armenia", value:"hy-AM"},
		{name: "Aruba", value:"nl"},
		{name: "Australia", value:"en"},
		{name: "Austria", value:"de"},
		{name: "Azerbaijan", value:"az-AZ"},
		{name: "Bahamas", value:"en"},
		{name: "Bahrein", value:"ar"},
		{name: "Bangladesh", value:"bn-BD"},
		{name: "Barbados", value:"en"},
		{name: "Belarus", value:"be-BY"},
		{name: "Belgium", value:"fr"},
		{name: "Belize", value:"en"},
		{name: "Benin", value:"fr"},
		{name: "Bermuda", value:"en"},
		{name: "Bhutan", value:"en"},
		{name: "Bolivia", value:"es"},
		{name: "Bosnia and Herzegovina", value:"bs-BA"},
		{name: "Botswana", value:"en"},
		{name: "Bouvet Island", value:"en"},
		{name: "Brazil", value:"pt"},
		{name: "British Indian Ocean Territory", value:"en"},
		{name: "British Virgin Islands", value:"en"},
		{name: "Brunei Darussalam", value:"ms-BN"},
		{name: "Bulgaria", value:"bg-BG"},
		{name: "Burkina Faso", value:"fr"},
		{name: "Burundi", value:"fr"},
		{name: "Cabo Verde", value:"pt"},
		{name: "Cambodia", value:"km-KH"},
		{name: "Cameroon", value:"en"},
		{name: "Caribbean Netherlands", value:"nl"},
		{name: "Cayman Islands", value:"en"},
		{name: "Centrafrican Republic", value:"fr"},
		{name: "Chad", value:"fr"},
		{name: "Chile", value:"es"},
		{name: "Christmas Island", value:"en"},
		{name: "City of the Vatican", value:"it"},
		{name: "Cocos (Keeling) Islands", value:"en"},
		{name: "Colombia", value:"es"},
		{name: "Comores", value:"ar"},
		{name: "Cook Islands", value:"en"},
		{name: "Costa Rica", value:"es"},
		{name: "Croatia", value:"hr-HR"},
		{name: "Cuba", value:"es"},
		{name: "Curaçao", value:"nl"},
		{name: "Cyprus", value:"el"},
		{name: "Czechia", value:"cs-CZ"},
		{name: "Côte d'Ivoire", value:"fr"},
		{name: "Democratic Republic of the Congo", value:"fr"},
		{name: "Denmark", value:"da-DK"},
		{name: "Djibouti", value:"fr"},
		{name: "Dominica", value:"en"},
		{name: "Dominican Republic", value:"es"},
		{name: "Ecuador", value:"es"},
		{name: "Egypt", value:"ar"},
		{name: "El Salvador", value:"es"},
		{name: "Equatorial Guinea", value:"es"},
		{name: "Eritrea", value:"en"},
		{name: "Estonia", value:"et-EE"},
		{name: "Ethiopia", value:"am-ET"},
		{name: "Falkland Islands", value:"en"},
		{name: "Faroe Islands", value:"fr"},
		{name: "Fiji", value:"en"},
		{name: "Finland", value:"fi-FI"},
		{name: "France", value:"fr"},
		{name: "French Guiana", value:"fr"},
		{name: "French Polynesia", value:"fr"},
		{name: "French Southern and Antarctic Lands", value:"fr"},
		{name: "Gabon", value:"fr"},
		{name: "Georgia", value:"ka-GE"},
		{name: "Germany", value:"de"},
		{name: "Ghana", value:"en"},
		{name: "Gibraltar", value:"en"},
		{name: "Greece", value:"el"},
		{name: "Greenland", value:"fr"},
		{name: "Grenada", value:"en"},
		{name: "Guadeloupe", value:"fr"},
		{name: "Guam", value:"en"},
		{name: "Guatemala", value:"es"},
		{name: "Guernsey", value:"en"},
		{name: "Guinea", value:"fr"},
		{name: "Guinea Bissau", value:"pt"},
		{name: "Guyana", value:"en"},
		{name: "Haiti", value:"fr"},
		{name: "Heard Island and McDonald Islands", value:"en"},
		{name: "Honduras", value:"es"},
		{name: "Hong Kong (SAR of China)", value:"en"},
		{name: "Hungary", value:"hu-HU"},
		{name: "Iceland", value:"is-IS"},
		{name: "India", value:"hi-IN"},
		{name: "Indonesia", value:"id-ID"},
		{name: "Iran", value:"fa"},
		{name: "Iraq", value:"ar"},
		{name: "Ireland", value:"en"},
		{name: "Isle of Man", value:"en"},
		{name: "Israel", value:"he-IL"},
		{name: "Italy", value:"it"},
		{name: "Jamaica", value:"en"},
		{name: "Japan", value:"ja-JP"},
		{name: "Jersey", value:"en"},
		{name: "Jordan", value:"ar"},
		{name: "Kazakhstan", value:"kk-KZ"},
		{name: "Kenya", value:"sw"},
		{name: "Kiribati", value:"en"},
		{name: "Kuwait", value:"ar"},
		{name: "Kyrgyzstan", value:"ky-KG"},
		{name: "Laos", value:"lo-LA"},
		{name: "Latvia", value:"lv-LV"},
		{name: "Lebanon", value:"ar"},
		{name: "Lesotho", value:"en"},
		{name: "Liberia", value:"en"},
		{name: "Libya", value:"ar"},
		{name: "Liechtenstein", value:"de"},
		{name: "Lithuania", value:"lt-LT"},
		{name: "Luxembourg", value:"fr"},
		{name: "Macao (SAR of China)", value:"en"},
		{name: "Macedonia", value:"mk-MK"},
		{name: "Madagascar", value:"fr"},
		{name: "Malawi", value:"en"},
		{name: "Malaysia", value:"ms-MY"},
		{name: "Maldives", value:"en"},
		{name: "Mali", value:"fr"},
		{name: "Malta", value:"fr"},
		{name: "Marshall Islands", value:"en"},
		{name: "Martinique", value:"fr"},
		{name: "Mauritania", value:"ar"},
		{name: "Mauritius", value:"en"},
		{name: "Mayotte", value:"fr"},
		{name: "Mexico", value:"es-MX"},
		{name: "Micronesia", value:"en"},
		{name: "Moldova", value:"ro"},
		{name: "Monaco", value:"fr"},
		{name: "Mongolia", value:"mn-MN"},
		{name: "Montenegro", value:"fr"},
		{name: "Montserrat", value:"en"},
		{name: "Morocco", value:"fr"},
		{name: "Mozambique", value:"fr"},
		{name: "Myanmar", value:"my-MM"},
		{name: "Namibia", value:"en"},
		{name: "Nauru", value:"en"},
		{name: "Nepal", value:"ne-NP"},
		{name: "New Caledonia", value:"fr"},
		{name: "New Zealand", value:"en"},
		{name: "Nicaragua", value:"es"},
		{name: "Niger", value:"fr"},
		{name: "Nigeria", value:"en"},
		{name: "Niue", value:"en"},
		{name: "Norfolk Island", value:"en"},
		{name: "North Korea", value:"ko"},
		{name: "Northern Mariana Islands", value:"en"},
		{name: "Norway", value:"nb-NO"},
		{name: "Oman", value:"ar"},
		{name: "Pakistan", value:"ur-PK"},
		{name: "Palau", value:"en"},
		{name: "Palestinian Territory", value:"ar"},
		{name: "Panama", value:"es"},
		{name: "Papua New Guinea", value:"en"},
		{name: "Paraguay", value:"es"},
		{name: "Peru", value:"es"},
		{name: "Philippines", value:"en"},
		{name: "Pitcairn", value:"en"},
		{name: "Poland", value:"pl-PL"},
		{name: "Portugal", value:"pt"},
		{name: "Puerto Rico", value:"es"},
		{name: "Qatar", value:"ar"},
		{name: "Republic of the Congo", value:"fr"},
		{name: "Reunion", value:"fr"},
		{name: "Romania", value:"ro"},
		{name: "Russia", value:"ru-RU"},
		{name: "Rwanda", value:"en"},
		{name: "Saint Helena", value:"en"},
		{name: "Saint Kitts and Nevis", value:"en"},
		{name: "Saint Lucia", value:"en"},
		{name: "Saint Martin (Dutch part)", value:"nl"},
		{name: "Saint Martin (French part)", value:"fr"},
		{name: "Saint Pierre and Miquelon", value:"fr"},
		{name: "Saint Vincent and the Grenadines", value:"en"},
		{name: "Saint-Barthélemy", value:"fr"},
		{name: "Samoa", value:"en"},
		{name: "San Marino", value:"it"},
		{name: "Saudi Arabia", value:"ar"},
		{name: "Serbia", value:"sr-RS"},
		{name: "Seychelles", value:"fr"},
		{name: "Sierra Leone", value:"en"},
		{name: "Singapore", value:"en"},
		{name: "Slovakia", value:"sk-SK"},
		{name: "Slovenia", value:"sl-SI"},
		{name: "Solomon Islands", value:"en"},
		{name: "Somalia", value:"so-SO"},
		{name: "South Africa", value:"en"},
		{name: "South Georgia and the South Sandwich Islands", value:"en"},
		{name: "South Korea", value:"ko"},
		{name: "South Sudan", value:"en"},
		{name: "Spain", value:"es"},
		{name: "Sri Lanka", value:"si-LK"},
		{name: "Sudan", value:"ar"},
		{name: "Suriname", value:"nl"},
		{name: "Svalbard and Jan Mayen", value:"fr"},
		{name: "Swaziland", value:"en"},
		{name: "Sweden", value:"sv"},
		{name: "Switzerland", value:"de"},
		{name: "Syria", value:"ar"},
		{name: "São Tomé and Príncipe", value:"pt"},
		{name: "Sénégal", value:"fr"},
		{name: "Taiwan", value:"en"},
		{name: "Tajikistan", value:"en"},
		{name: "Tanzania", value:"sw"},
		{name: "Thailand", value:"th-TH"},
		{name: "The Gambia", value:"en"},
		{name: "The Netherlands", value:"nl"},
		{name: "Timor-Leste", value:"pt"},
		{name: "Togo", value:"fr"},
		{name: "Tokelau", value:"en"},
		{name: "Tonga", value:"en"},
		{name: "Trinidad and Tobago", value:"en"},
		{name: "Tunisia", value:"ar"},
		{name: "Turkey", value:"tr-TR"},
		{name: "Turkmenistan", value:"tk-TM"},
		{name: "Turks and Caicos Islands", value:"en"},
		{name: "Tuvalu", value:"en"},
		{name: "Uganda", value:"en"},
		{name: "Ukraine", value:"uk-UA"},
		{name: "United Arab Emirates", value:"ar"},
		{name: "United States Minor Outlying Islands", value:"en"},
		{name: "United States Virgin Islands", value:"en"},
		{name: "Uruguay", value:"es"},
		{name: "Uzbekistan", value:"en"},
		{name: "Vanuatu", value:"en"},
		{name: "Venezuela", value:"es"},
		{name: "Vietnam", value:"vi"},
		{name: "Wallis and Futuna", value:"fr"},
		{name: "Western Sahara", value:"ar"},
		{name: "Yemen", value:"ar"},
		{name: "Zambia", value:"en"},
		{name: "Zimbabwe", value:"en"}
	],
	conversion: 1
}

