module.exports={
  "main": {
    "sr": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "sr"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 хиљада",
              "1000-count-few": "0 хиљаде",
              "1000-count-other": "0 хиљада",
              "10000-count-one": "00 хиљада",
              "10000-count-few": "00 хиљаде",
              "10000-count-other": "00 хиљада",
              "100000-count-one": "000 хиљада",
              "100000-count-few": "000 хиљаде",
              "100000-count-other": "000 хиљада",
              "1000000-count-one": "0 милион",
              "1000000-count-few": "0 милиона",
              "1000000-count-other": "0 милиона",
              "10000000-count-one": "00 милион",
              "10000000-count-few": "00 милиона",
              "10000000-count-other": "00 милиона",
              "100000000-count-one": "000 милион",
              "100000000-count-few": "000 милиона",
              "100000000-count-other": "000 милиона",
              "1000000000-count-one": "0 милијарда",
              "1000000000-count-few": "0 милијарде",
              "1000000000-count-other": "0 милијарди",
              "10000000000-count-one": "00 милијарда",
              "10000000000-count-few": "00 милијарде",
              "10000000000-count-other": "00 милијарди",
              "100000000000-count-one": "000 милијарда",
              "100000000000-count-few": "000 милијарде",
              "100000000000-count-other": "000 милијарди",
              "1000000000000-count-one": "0 билион",
              "1000000000000-count-few": "0 билиона",
              "1000000000000-count-other": "0 билиона",
              "10000000000000-count-one": "00 билион",
              "10000000000000-count-few": "00 билиона",
              "10000000000000-count-other": "00 билиона",
              "100000000000000-count-one": "000 билион",
              "100000000000000-count-few": "000 билиона",
              "100000000000000-count-other": "000 билиона"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 хиљ'.'",
              "1000-count-few": "0 хиљ'.'",
              "1000-count-other": "0 хиљ'.'",
              "10000-count-one": "00 хиљ'.'",
              "10000-count-few": "00 хиљ'.'",
              "10000-count-other": "00 хиљ'.'",
              "100000-count-one": "000 хиљ'.'",
              "100000-count-few": "000 хиљ'.'",
              "100000-count-other": "000 хиљ'.'",
              "1000000-count-one": "0 мил'.'",
              "1000000-count-few": "0 мил'.'",
              "1000000-count-other": "0 мил'.'",
              "10000000-count-one": "00 мил'.'",
              "10000000-count-few": "00 мил'.'",
              "10000000-count-other": "00 мил'.'",
              "100000000-count-one": "000 мил'.'",
              "100000000-count-few": "000 мил'.'",
              "100000000-count-other": "000 мил'.'",
              "1000000000-count-one": "0 млрд'.'",
              "1000000000-count-few": "0 млрд'.'",
              "1000000000-count-other": "0 млрд'.'",
              "10000000000-count-one": "00 млрд'.'",
              "10000000000-count-few": "00 млрд'.'",
              "10000000000-count-other": "00 млрд'.'",
              "100000000000-count-one": "000 млрд'.'",
              "100000000000-count-few": "000 млрд'.'",
              "100000000000-count-other": "000 млрд'.'",
              "1000000000000-count-one": "0 бил'.'",
              "1000000000000-count-few": "0 бил'.'",
              "1000000000000-count-other": "0 бил'.'",
              "10000000000000-count-one": "00 бил'.'",
              "10000000000000-count-few": "00 бил'.'",
              "10000000000000-count-other": "00 бил'.'",
              "100000000000000-count-one": "000 бил'.'",
              "100000000000000-count-few": "000 бил'.'",
              "100000000000000-count-other": "000 бил'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 хиљ'.' ¤",
              "1000-count-few": "0 хиљ'.' ¤",
              "1000-count-other": "0 хиљ'.' ¤",
              "10000-count-one": "00 хиљ'.' ¤",
              "10000-count-few": "00 хиљ'.' ¤",
              "10000-count-other": "00 хиљ'.' ¤",
              "100000-count-one": "000 хиљ'.' ¤",
              "100000-count-few": "000 хиљ'.' ¤",
              "100000-count-other": "000 хиљ'.' ¤",
              "1000000-count-one": "0 мил'.' ¤",
              "1000000-count-few": "0 мил'.' ¤",
              "1000000-count-other": "0 мил'.' ¤",
              "10000000-count-one": "00 мил'.' ¤",
              "10000000-count-few": "00 мил'.' ¤",
              "10000000-count-other": "00 мил'.' ¤",
              "100000000-count-one": "000 мил'.' ¤",
              "100000000-count-few": "000 мил'.' ¤",
              "100000000-count-other": "000 мил'.' ¤",
              "1000000000-count-one": "0 млрд'.' ¤",
              "1000000000-count-few": "0 млрд'.' ¤",
              "1000000000-count-other": "0 млрд'.' ¤",
              "10000000000-count-one": "00 млрд'.' ¤",
              "10000000000-count-few": "00 млрд'.' ¤",
              "10000000000-count-other": "00 млрд'.' ¤",
              "100000000000-count-one": "000 млрд'.' ¤",
              "100000000000-count-few": "000 млрд'.' ¤",
              "100000000000-count-other": "000 млрд'.' ¤",
              "1000000000000-count-one": "0 бил'.' ¤",
              "1000000000000-count-few": "0 бил'.' ¤",
              "1000000000000-count-other": "0 бил'.' ¤",
              "10000000000000-count-one": "00 бил'.' ¤",
              "10000000000000-count-few": "00 бил'.' ¤",
              "10000000000000-count-other": "00 бил'.' ¤",
              "100000000000000-count-one": "000 бил'.' ¤",
              "100000000000000-count-few": "000 бил'.' ¤",
              "100000000000000-count-other": "000 бил'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} сат",
          "pluralMinimalPairs-count-few": "{0} сата",
          "pluralMinimalPairs-count-other": "{0} сати",
          "other": "Скрените у {0}. десно."
        }
      }
    }
  }
}
