module.exports={
  "main": {
    "el": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "el"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn",
          "traditional": "grek"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "e",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 χιλιάδα",
              "1000-count-other": "0 χιλιάδες",
              "10000-count-one": "00 χιλιάδες",
              "10000-count-other": "00 χιλιάδες",
              "100000-count-one": "000 χιλιάδες",
              "100000-count-other": "000 χιλιάδες",
              "1000000-count-one": "0 εκατομμύριο",
              "1000000-count-other": "0 εκατομμύρια",
              "10000000-count-one": "00 εκατομμύρια",
              "10000000-count-other": "00 εκατομμύρια",
              "100000000-count-one": "000 εκατομμύρια",
              "100000000-count-other": "000 εκατομμύρια",
              "1000000000-count-one": "0 δισεκατομμύριο",
              "1000000000-count-other": "0 δισεκατομμύρια",
              "10000000000-count-one": "00 δισεκατομμύρια",
              "10000000000-count-other": "00 δισεκατομμύρια",
              "100000000000-count-one": "000 δισεκατομμύρια",
              "100000000000-count-other": "000 δισεκατομμύρια",
              "1000000000000-count-one": "0 τρισεκατομμύριο",
              "1000000000000-count-other": "0 τρισεκατομμύρια",
              "10000000000000-count-one": "00 τρισεκατομμύρια",
              "10000000000000-count-other": "00 τρισεκατομμύρια",
              "100000000000000-count-one": "000 τρισεκατομμύρια",
              "100000000000000-count-other": "000 τρισεκατομμύρια"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 χιλ'.'",
              "1000-count-other": "0 χιλ'.'",
              "10000-count-one": "00 χιλ'.'",
              "10000-count-other": "00 χιλ'.'",
              "100000-count-one": "000 χιλ'.'",
              "100000-count-other": "000 χιλ'.'",
              "1000000-count-one": "0 εκ'.'",
              "1000000-count-other": "0 εκ'.'",
              "10000000-count-one": "00 εκ'.'",
              "10000000-count-other": "00 εκ'.'",
              "100000000-count-one": "000 εκ'.'",
              "100000000-count-other": "000 εκ'.'",
              "1000000000-count-one": "0 δισ'.'",
              "1000000000-count-other": "0 δισ'.'",
              "10000000000-count-one": "00 δισ'.'",
              "10000000000-count-other": "00 δισ'.'",
              "100000000000-count-one": "000 δισ'.'",
              "100000000000-count-other": "000 δισ'.'",
              "1000000000000-count-one": "0 τρισ'.'",
              "1000000000000-count-other": "0 τρισ'.'",
              "10000000000000-count-one": "00 τρισ'.'",
              "10000000000000-count-other": "00 τρισ'.'",
              "100000000000000-count-one": "000 τρισ'.'",
              "100000000000000-count-other": "000 τρισ'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 χιλ'.' ¤",
              "1000-count-other": "0 χιλ'.' ¤",
              "10000-count-one": "00 χιλ'.' ¤",
              "10000-count-other": "00 χιλ'.' ¤",
              "100000-count-one": "000 χιλ'.' ¤",
              "100000-count-other": "000 χιλ'.' ¤",
              "1000000-count-one": "0 εκ'.' ¤",
              "1000000-count-other": "0 εκ'.' ¤",
              "10000000-count-one": "00 εκ'.' ¤",
              "10000000-count-other": "00 εκ'.' ¤",
              "100000000-count-one": "000 εκ'.' ¤",
              "100000000-count-other": "000 εκ'.' ¤",
              "1000000000-count-one": "0 δισ'.' ¤",
              "1000000000-count-other": "0 δισ'.' ¤",
              "10000000000-count-one": "00 δισ'.' ¤",
              "10000000000-count-other": "00 δισ'.' ¤",
              "100000000000-count-one": "000 δισ'.' ¤",
              "100000000000-count-other": "000 δισ'.' ¤",
              "1000000000000-count-one": "0 τρισ'.' ¤",
              "1000000000000-count-other": "0 τρισ'.' ¤",
              "10000000000000-count-one": "00 τρισ'.' ¤",
              "10000000000000-count-other": "00 τρισ'.' ¤",
              "100000000000000-count-one": "000 τρισ'.' ¤",
              "100000000000000-count-other": "000 τρισ'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} ημέρα",
          "pluralMinimalPairs-count-other": "{0} ημέρες",
          "other": "Στρίψτε στην {0}η δεξιά."
        }
      }
    }
  }
}
