module.exports={
  "main": {
    "lt": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "lt"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "−",
          "exponential": "×10^",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tūkstantis",
              "1000-count-few": "0 tūkstančiai",
              "1000-count-many": "0 tūkstančio",
              "1000-count-other": "0 tūkstančių",
              "10000-count-one": "00 tūkstantis",
              "10000-count-few": "00 tūkstančiai",
              "10000-count-many": "00 tūkstančio",
              "10000-count-other": "00 tūkstančių",
              "100000-count-one": "000 tūkstantis",
              "100000-count-few": "000 tūkstančiai",
              "100000-count-many": "000 tūkstančio",
              "100000-count-other": "000 tūkstančių",
              "1000000-count-one": "0 milijonas",
              "1000000-count-few": "0 milijonai",
              "1000000-count-many": "0 milijono",
              "1000000-count-other": "0 milijonų",
              "10000000-count-one": "00 milijonas",
              "10000000-count-few": "00 milijonai",
              "10000000-count-many": "00 milijono",
              "10000000-count-other": "00 milijonų",
              "100000000-count-one": "000 milijonas",
              "100000000-count-few": "000 milijonai",
              "100000000-count-many": "000 milijono",
              "100000000-count-other": "000 milijonų",
              "1000000000-count-one": "0 milijardas",
              "1000000000-count-few": "0 milijardai",
              "1000000000-count-many": "0 milijardo",
              "1000000000-count-other": "0 milijardų",
              "10000000000-count-one": "00 milijardas",
              "10000000000-count-few": "00 milijardai",
              "10000000000-count-many": "00 milijardo",
              "10000000000-count-other": "00 milijardų",
              "100000000000-count-one": "000 milijardas",
              "100000000000-count-few": "000 milijardai",
              "100000000000-count-many": "000 milijardo",
              "100000000000-count-other": "000 milijardų",
              "1000000000000-count-one": "0 trilijonas",
              "1000000000000-count-few": "0 trilijonai",
              "1000000000000-count-many": "0 trilijono",
              "1000000000000-count-other": "0 trilijonų",
              "10000000000000-count-one": "00 trilijonas",
              "10000000000000-count-few": "00 trilijonai",
              "10000000000000-count-many": "00 trilijono",
              "10000000000000-count-other": "00 trilijonų",
              "100000000000000-count-one": "000 trilijonas",
              "100000000000000-count-few": "000 trilijonai",
              "100000000000000-count-many": "000 trilijono",
              "100000000000000-count-other": "000 trilijonų"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tūkst'.'",
              "1000-count-few": "0 tūkst'.'",
              "1000-count-many": "0 tūkst'.'",
              "1000-count-other": "0 tūkst'.'",
              "10000-count-one": "00 tūkst'.'",
              "10000-count-few": "00 tūkst'.'",
              "10000-count-many": "00 tūkst'.'",
              "10000-count-other": "00 tūkst'.'",
              "100000-count-one": "000 tūkst'.'",
              "100000-count-few": "000 tūkst'.'",
              "100000-count-many": "000 tūkst'.'",
              "100000-count-other": "000 tūkst'.'",
              "1000000-count-one": "0 mln'.'",
              "1000000-count-few": "0 mln'.'",
              "1000000-count-many": "0 mln'.'",
              "1000000-count-other": "0 mln'.'",
              "10000000-count-one": "00 mln'.'",
              "10000000-count-few": "00 mln'.'",
              "10000000-count-many": "00 mln'.'",
              "10000000-count-other": "00 mln'.'",
              "100000000-count-one": "000 mln'.'",
              "100000000-count-few": "000 mln'.'",
              "100000000-count-many": "000 mln'.'",
              "100000000-count-other": "000 mln'.'",
              "1000000000-count-one": "0 mlrd'.'",
              "1000000000-count-few": "0 mlrd'.'",
              "1000000000-count-many": "0 mlrd'.'",
              "1000000000-count-other": "0 mlrd'.'",
              "10000000000-count-one": "00 mlrd'.'",
              "10000000000-count-few": "00 mlrd'.'",
              "10000000000-count-many": "00 mlrd'.'",
              "10000000000-count-other": "00 mlrd'.'",
              "100000000000-count-one": "000 mlrd'.'",
              "100000000000-count-few": "000 mlrd'.'",
              "100000000000-count-many": "000 mlrd'.'",
              "100000000000-count-other": "000 mlrd'.'",
              "1000000000000-count-one": "0 trln'.'",
              "1000000000000-count-few": "0 trln'.'",
              "1000000000000-count-many": "0 trln'.'",
              "1000000000000-count-other": "0 trln'.'",
              "10000000000000-count-one": "00 trln'.'",
              "10000000000000-count-few": "00 trln'.'",
              "10000000000000-count-many": "00 trln'.'",
              "10000000000000-count-other": "00 trln'.'",
              "100000000000000-count-one": "000 trln'.'",
              "100000000000000-count-few": "000 trln'.'",
              "100000000000000-count-many": "000 trln'.'",
              "100000000000000-count-other": "000 trln'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 tūkst'.' ¤",
              "1000-count-few": "0 tūkst'.' ¤",
              "1000-count-many": "0 tūkst'.' ¤",
              "1000-count-other": "0 tūkst'.' ¤",
              "10000-count-one": "00 tūkst'.' ¤",
              "10000-count-few": "00 tūkst'.' ¤",
              "10000-count-many": "00 tūkst'.' ¤",
              "10000-count-other": "00 tūkst'.' ¤",
              "100000-count-one": "000 tūkst'.' ¤",
              "100000-count-few": "000 tūkst'.' ¤",
              "100000-count-many": "000 tūkst'.' ¤",
              "100000-count-other": "000 tūkst'.' ¤",
              "1000000-count-one": "0 mln'.' ¤",
              "1000000-count-few": "0 mln'.' ¤",
              "1000000-count-many": "0 mln'.' ¤",
              "1000000-count-other": "0 mln'.' ¤",
              "10000000-count-one": "00 mln'.' ¤",
              "10000000-count-few": "00 mln'.' ¤",
              "10000000-count-many": "00 mln'.' ¤",
              "10000000-count-other": "00 mln'.' ¤",
              "100000000-count-one": "000 mln'.' ¤",
              "100000000-count-few": "000 mln'.' ¤",
              "100000000-count-many": "000 mln'.' ¤",
              "100000000-count-other": "000 mln'.' ¤",
              "1000000000-count-one": "0 mlrd'.' ¤",
              "1000000000-count-few": "0 mlrd'.' ¤",
              "1000000000-count-many": "0 mlrd'.' ¤",
              "1000000000-count-other": "0 mlrd'.' ¤",
              "10000000000-count-one": "00 mlrd'.' ¤",
              "10000000000-count-few": "00 mlrd'.' ¤",
              "10000000000-count-many": "00 mlrd'.' ¤",
              "10000000000-count-other": "00 mlrd'.' ¤",
              "100000000000-count-one": "000 mlrd'.' ¤",
              "100000000000-count-few": "000 mlrd'.' ¤",
              "100000000000-count-many": "000 mlrd'.' ¤",
              "100000000000-count-other": "000 mlrd'.' ¤",
              "1000000000000-count-one": "0 trln'.' ¤",
              "1000000000000-count-few": "0 trln'.' ¤",
              "1000000000000-count-many": "0 trln'.' ¤",
              "1000000000000-count-other": "0 trln'.' ¤",
              "10000000000000-count-one": "00 trln'.' ¤",
              "10000000000000-count-few": "00 trln'.' ¤",
              "10000000000000-count-many": "00 trln'.' ¤",
              "10000000000000-count-other": "00 trln'.' ¤",
              "100000000000000-count-one": "000 trln'.' ¤",
              "100000000000000-count-few": "000 trln'.' ¤",
              "100000000000000-count-many": "000 trln'.' ¤",
              "100000000000000-count-other": "000 trln'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} obuolys",
          "pluralMinimalPairs-count-few": "{0} obuoliai",
          "pluralMinimalPairs-count-many": "{0} obuolio",
          "pluralMinimalPairs-count-other": "{0} obuolių",
          "other": "{0}-ame posūkyje sukite į dešinę."
        }
      }
    }
  }
}
