module.exports={
  "main": {
    "et": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "et"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "−",
          "exponential": "×10^",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tuhat",
              "1000-count-other": "0 tuhat",
              "10000-count-one": "00 tuhat",
              "10000-count-other": "00 tuhat",
              "100000-count-one": "000 tuhat",
              "100000-count-other": "000 tuhat",
              "1000000-count-one": "0 miljon",
              "1000000-count-other": "0 miljonit",
              "10000000-count-one": "00 miljonit",
              "10000000-count-other": "00 miljonit",
              "100000000-count-one": "000 miljonit",
              "100000000-count-other": "000 miljonit",
              "1000000000-count-one": "0 miljard",
              "1000000000-count-other": "0 miljardit",
              "10000000000-count-one": "00 miljardit",
              "10000000000-count-other": "00 miljardit",
              "100000000000-count-one": "000 miljardit",
              "100000000000-count-other": "000 miljardit",
              "1000000000000-count-one": "0 triljon",
              "1000000000000-count-other": "0 triljonit",
              "10000000000000-count-one": "00 triljonit",
              "10000000000000-count-other": "00 triljonit",
              "100000000000000-count-one": "000 triljonit",
              "100000000000000-count-other": "000 triljonit"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tuh",
              "1000-count-other": "0 tuh",
              "10000-count-one": "00 tuh",
              "10000-count-other": "00 tuh",
              "100000-count-one": "000 tuh",
              "100000-count-other": "000 tuh",
              "1000000-count-one": "0 mln",
              "1000000-count-other": "0 mln",
              "10000000-count-one": "00 mln",
              "10000000-count-other": "00 mln",
              "100000000-count-one": "000 mln",
              "100000000-count-other": "000 mln",
              "1000000000-count-one": "0 mld",
              "1000000000-count-other": "0 mld",
              "10000000000-count-one": "00 mld",
              "10000000000-count-other": "00 mld",
              "100000000000-count-one": "000 mld",
              "100000000000-count-other": "000 mld",
              "1000000000000-count-one": "0 trl",
              "1000000000000-count-other": "0 trl",
              "10000000000000-count-one": "00 trl",
              "10000000000000-count-other": "00 trl",
              "100000000000000-count-one": "000 trl",
              "100000000000000-count-other": "000 trl"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 tuh ¤",
              "1000-count-other": "0 tuh ¤",
              "10000-count-one": "00 tuh ¤",
              "10000-count-other": "00 tuh ¤",
              "100000-count-one": "000 tuh ¤",
              "100000-count-other": "000 tuh ¤",
              "1000000-count-one": "0 mln ¤",
              "1000000-count-other": "0 mln ¤",
              "10000000-count-one": "00 mln ¤",
              "10000000-count-other": "00 mln ¤",
              "100000000-count-one": "000 mln ¤",
              "100000000-count-other": "000 mln ¤",
              "1000000000-count-one": "0 mld ¤",
              "1000000000-count-other": "0 mld ¤",
              "10000000000-count-one": "00 mld ¤",
              "10000000000-count-other": "00 mld ¤",
              "100000000000-count-one": "000 mld ¤",
              "100000000000-count-other": "000 mld ¤",
              "1000000000000-count-one": "0 trl ¤",
              "1000000000000-count-other": "0 trl ¤",
              "10000000000000-count-one": "00 trl ¤",
              "10000000000000-count-other": "00 trl ¤",
              "100000000000000-count-one": "000 trl ¤",
              "100000000000000-count-other": "000 trl ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~ {0}",
          "atLeast": "≥{0}",
          "atMost": "≤ {0}",
          "range": "{0}‒{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} ööpäev",
          "pluralMinimalPairs-count-other": "{0} ööpäeva",
          "other": "Tehke {0}. parempööre."
        }
      }
    }
  }
}
