module.exports={
  "main": {
    "lv": {
      "identity": {
        "version": {
          "_number": "$Revision: 14843 $",
          "_cldrVersion": "35.1"
        },
        "language": "lv"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NS",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-zero": "0 tūkstošu",
              "1000-count-one": "0 tūkstotis",
              "1000-count-other": "0 tūkstoši",
              "10000-count-zero": "00 tūkstoši",
              "10000-count-one": "00 tūkstotis",
              "10000-count-other": "00 tūkstoši",
              "100000-count-zero": "000 tūkstoši",
              "100000-count-one": "000 tūkstotis",
              "100000-count-other": "000 tūkstoši",
              "1000000-count-zero": "0 miljonu",
              "1000000-count-one": "0 miljons",
              "1000000-count-other": "0 miljoni",
              "10000000-count-zero": "00 miljoni",
              "10000000-count-one": "00 miljons",
              "10000000-count-other": "00 miljoni",
              "100000000-count-zero": "000 miljoni",
              "100000000-count-one": "000 miljons",
              "100000000-count-other": "000 miljoni",
              "1000000000-count-zero": "0 miljardu",
              "1000000000-count-one": "0 miljards",
              "1000000000-count-other": "0 miljardi",
              "10000000000-count-zero": "00 miljardi",
              "10000000000-count-one": "00 miljards",
              "10000000000-count-other": "00 miljardi",
              "100000000000-count-zero": "000 miljardi",
              "100000000000-count-one": "000 miljards",
              "100000000000-count-other": "000 miljardi",
              "1000000000000-count-zero": "0 triljonu",
              "1000000000000-count-one": "0 triljons",
              "1000000000000-count-other": "0 triljoni",
              "10000000000000-count-zero": "00 triljoni",
              "10000000000000-count-one": "00 triljons",
              "10000000000000-count-other": "00 triljoni",
              "100000000000000-count-zero": "000 triljoni",
              "100000000000000-count-one": "000 triljons",
              "100000000000000-count-other": "000 triljoni"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-zero": "0 tūkst'.'",
              "1000-count-one": "0 tūkst'.'",
              "1000-count-other": "0 tūkst'.'",
              "10000-count-zero": "00 tūkst'.'",
              "10000-count-one": "00 tūkst'.'",
              "10000-count-other": "00 tūkst'.'",
              "100000-count-zero": "000 tūkst'.'",
              "100000-count-one": "000 tūkst'.'",
              "100000-count-other": "000 tūkst'.'",
              "1000000-count-zero": "0 milj'.'",
              "1000000-count-one": "0 milj'.'",
              "1000000-count-other": "0 milj'.'",
              "10000000-count-zero": "00 milj'.'",
              "10000000-count-one": "00 milj'.'",
              "10000000-count-other": "00 milj'.'",
              "100000000-count-zero": "000 milj'.'",
              "100000000-count-one": "000 milj'.'",
              "100000000-count-other": "000 milj'.'",
              "1000000000-count-zero": "0 mljrd'.'",
              "1000000000-count-one": "0 mljrd'.'",
              "1000000000-count-other": "0 mljrd'.'",
              "10000000000-count-zero": "00 mljrd'.'",
              "10000000000-count-one": "00 mljrd'.'",
              "10000000000-count-other": "00 mljrd'.'",
              "100000000000-count-zero": "000 mljrd'.'",
              "100000000000-count-one": "000 mljrd'.'",
              "100000000000-count-other": "000 mljrd'.'",
              "1000000000000-count-zero": "0 trilj'.'",
              "1000000000000-count-one": "0 trilj'.'",
              "1000000000000-count-other": "0 trilj'.'",
              "10000000000000-count-zero": "00 trilj'.'",
              "10000000000000-count-one": "00 trilj'.'",
              "10000000000000-count-other": "00 trilj'.'",
              "100000000000000-count-zero": "000 trilj'.'",
              "100000000000000-count-one": "000 trilj'.'",
              "100000000000000-count-other": "000 trilj'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-zero": "0 tūkst'.' ¤",
              "1000-count-one": "0 tūkst'.' ¤",
              "1000-count-other": "0 tūkst'.' ¤",
              "10000-count-zero": "00 tūkst'.' ¤",
              "10000-count-one": "00 tūkst'.' ¤",
              "10000-count-other": "00 tūkst'.' ¤",
              "100000-count-zero": "000 tūkst'.' ¤",
              "100000-count-one": "000 tūkst'.' ¤",
              "100000-count-other": "000 tūkst'.' ¤",
              "1000000-count-zero": "0 milj'.' ¤",
              "1000000-count-one": "0 milj'.' ¤",
              "1000000-count-other": "0 milj'.' ¤",
              "10000000-count-zero": "00 milj'.' ¤",
              "10000000-count-one": "00 milj'.' ¤",
              "10000000-count-other": "00 milj'.' ¤",
              "100000000-count-zero": "000 milj'.' ¤",
              "100000000-count-one": "000 milj'.' ¤",
              "100000000-count-other": "000 milj'.' ¤",
              "1000000000-count-zero": "0 mljrd'.' ¤",
              "1000000000-count-one": "0 mljrd'.' ¤",
              "1000000000-count-other": "0 mljrd'.' ¤",
              "10000000000-count-zero": "00 mljrd'.' ¤",
              "10000000000-count-one": "00 mljrd'.' ¤",
              "10000000000-count-other": "00 mljrd'.' ¤",
              "100000000000-count-zero": "000 mljrd'.' ¤",
              "100000000000-count-one": "000 mljrd'.' ¤",
              "100000000000-count-other": "000 mljrd'.' ¤",
              "1000000000000-count-zero": "0 trilj'.' ¤",
              "1000000000000-count-one": "0 trilj'.' ¤",
              "1000000000000-count-other": "0 trilj'.' ¤",
              "10000000000000-count-zero": "00 trilj'.' ¤",
              "10000000000000-count-one": "00 trilj'.' ¤",
              "10000000000000-count-other": "00 trilj'.' ¤",
              "100000000000000-count-zero": "000 trilj'.' ¤",
              "100000000000000-count-one": "000 trilj'.' ¤",
              "100000000000000-count-other": "000 trilj'.' ¤"
            }
          },
          "unitPattern-count-zero": "{0} {1}",
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-zero": "{0} diennakšu",
          "pluralMinimalPairs-count-one": "{0} diennakts",
          "pluralMinimalPairs-count-other": "{0} diennaktis",
          "other": "Dodieties {0}. pagriezienā pa labi."
        }
      }
    }
  }
}
