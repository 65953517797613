module.exports={
  "main": {
    "mk": {
      "identity": {
        "version": {
          "_number": "$Revision: 14827 $",
          "_cldrVersion": "35.1"
        },
        "language": "mk"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 илјада",
              "1000-count-other": "0 илјади",
              "10000-count-one": "00 илјада",
              "10000-count-other": "00 илјади",
              "100000-count-one": "000 илјада",
              "100000-count-other": "000 илјади",
              "1000000-count-one": "0 милион",
              "1000000-count-other": "0 милиони",
              "10000000-count-one": "00 милион",
              "10000000-count-other": "00 милиони",
              "100000000-count-one": "000 милион",
              "100000000-count-other": "000 милиони",
              "1000000000-count-one": "0 милијарда",
              "1000000000-count-other": "0 милијарди",
              "10000000000-count-one": "00 милијарда",
              "10000000000-count-other": "00 милијарди",
              "100000000000-count-one": "000 милијарда",
              "100000000000-count-other": "000 милијарди",
              "1000000000000-count-one": "0 билион",
              "1000000000000-count-other": "0 билиони",
              "10000000000000-count-one": "00 билион",
              "10000000000000-count-other": "00 билиони",
              "100000000000000-count-one": "000 билион",
              "100000000000000-count-other": "000 билиони"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 илј'.'",
              "1000-count-other": "0 илј'.'",
              "10000-count-one": "00 илј'.'",
              "10000-count-other": "00 илј'.'",
              "100000-count-one": "000 илј'.'",
              "100000-count-other": "000 илј'.'",
              "1000000-count-one": "0 мил'.'",
              "1000000-count-other": "0 мил'.'",
              "10000000-count-one": "00 мил'.'",
              "10000000-count-other": "00 мил'.'",
              "100000000-count-one": "000 М",
              "100000000-count-other": "000 М",
              "1000000000-count-one": "0 милј'.'",
              "1000000000-count-other": "0 милј'.'",
              "10000000000-count-one": "00 милј'.'",
              "10000000000-count-other": "00 милј'.'",
              "100000000000-count-one": "000 мј'.'",
              "100000000000-count-other": "000 ми'.'",
              "1000000000000-count-one": "0 бил'.'",
              "1000000000000-count-other": "0 бил'.'",
              "10000000000000-count-one": "00 бил'.'",
              "10000000000000-count-other": "00 бил'.'",
              "100000000000000-count-one": "000 бил'.'",
              "100000000000000-count-other": "000 бил'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 илј'.' ¤ ",
              "1000-count-other": "0 илј'.' ¤ ",
              "10000-count-one": "00 илј'.' ¤ ",
              "10000-count-other": "00 илј'.' ¤ ",
              "100000-count-one": "000 илј'.' ¤ ",
              "100000-count-other": "000 илј'.' ¤ ",
              "1000000-count-one": "0 мил'.' ¤",
              "1000000-count-other": "0 мил'.' ¤",
              "10000000-count-one": "00 мил'.' ¤",
              "10000000-count-other": "00 мил'.' ¤",
              "100000000-count-one": "000 мил'.' ¤",
              "100000000-count-other": "000 мил'.' ¤",
              "1000000000-count-one": "0 милј'.' ¤",
              "1000000000-count-other": "0 милј'.' ¤",
              "10000000000-count-one": "00 милј'.' ¤",
              "10000000000-count-other": "00 милј'.' ¤",
              "100000000000-count-one": "000 милј'.' ¤",
              "100000000000-count-other": "000 милј'.' ¤",
              "1000000000000-count-one": "0 бил'.' ¤",
              "1000000000000-count-other": "0 бил'.' ¤",
              "10000000000000-count-one": "00 бил'.' ¤",
              "10000000000000-count-other": "00 бил'.' ¤",
              "100000000000000-count-one": "000 бил'.' ¤",
              "100000000000000-count-other": "000 бил'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} ден",
          "pluralMinimalPairs-count-other": "{0} дена",
          "many": "Сврти на {0}-мата улица десно.",
          "one": "Сврти на {0}-вата улица десно.",
          "other": "Сврти на {0}-тата улица десно.",
          "two": "Сврти на {0}-рата улица десно."
        }
      }
    }
  }
}
