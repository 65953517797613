// @ts-ignore:need require to load json data
declare function require(name:string);

export const supplemental = require('cldr-data/supplemental/likelySubtags.json');
export const numberingSystems = require('cldr-data/supplemental/numberingSystems.json');

// https://basarat.gitbooks.io/typescript/docs/types/index-signatures.html
interface LanguageFormat {
    [key:string]: string
}

export const languagesFormatsJson:LanguageFormat = {
    "am-ET": require('cldr-data/main/am/numbers.json'),
    "ar": require('cldr-data/main/ar/numbers.json'),
    "az-AZ": require('cldr-data/main/az/numbers.json'),
    "be-BY": require('cldr-data/main/be/numbers.json'),
    "bg-BG": require('cldr-data/main/bg/numbers.json'),
    "bn-BD": require('cldr-data/main/bn/numbers.json'),
    "bs-BA": require('cldr-data/main/bs/numbers.json'),
    "ca-AD": require('cldr-data/main/ca-AD/numbers.json'),
    "cs-CZ": require('cldr-data/main/cs/numbers.json'),
    "da-DK": require('cldr-data/main/da/numbers.json'),
    "de": require('cldr-data/main/de/numbers.json'),
    "el": require('cldr-data/main/el/numbers.json'),
    "en": require('cldr-data/main/en/numbers.json'),
    "es": require('cldr-data/main/es/numbers.json'),
    "es-MX": require('cldr-data/main/es-MX/numbers.json'),
    "et-EE": require('cldr-data/main/et/numbers.json'),
    "fa": require('cldr-data/main/fa/numbers.json'),
    "fo-FO": require('cldr-data/main/en/numbers.json'),
    "fi-FI": require('cldr-data/main/fi/numbers.json'),
    "fr": require('cldr-data/main/fr/numbers.json'),
    "he-IL": require('cldr-data/main/he/numbers.json'),
    "hi-IN": require('cldr-data/main/hi/numbers.json'),
    "hy-AM": require('cldr-data/main/hy/numbers.json'),
    "hr-HR": require('cldr-data/main/hr/numbers.json'),
    "hu-HU": require('cldr-data/main/hu/numbers.json'),
    "id-ID": require('cldr-data/main/id/numbers.json'),
    "is-IS": require('cldr-data/main/is/numbers.json'),
    "it": require('cldr-data/main/it/numbers.json'),
    "ja-JP": require('cldr-data/main/ja/numbers.json'),
    "ka-GE": require('cldr-data/main/ka/numbers.json'),
    "kk-KZ": require('cldr-data/main/kk/numbers.json'),
    "km-KH": require('cldr-data/main/km/numbers.json'),
    "ky-KG": require('cldr-data/main/ky/numbers.json'),
    "ko": require('cldr-data/main/ko/numbers.json'),
    "lo-LA": require('cldr-data/main/lo/numbers.json'),
    "lv-LV": require('cldr-data/main/lv/numbers.json'),
    "lt-LT": require('cldr-data/main/lt/numbers.json'),
    "mk-MK": require('cldr-data/main/mk/numbers.json'),
    "mn-MN": require('cldr-data/main/mn/numbers.json'),
    "ms-BN": require('cldr-data/main/ms-BN/numbers.json'),
    "ms-MY": require('cldr-data/main/ms/numbers.json'),
    "my-MM": require('cldr-data/main/my/numbers.json'),
    "nb-NO": require('cldr-data/main/nb/numbers.json'),
    "ne-NP": require('cldr-data/main/ne/numbers.json'),
    "nl": require('cldr-data/main/nl/numbers.json'),
    "pt": require('cldr-data/main/pt/numbers.json'),
    "pl-PL": require('cldr-data/main/pl/numbers.json'),
    "ro": require('cldr-data/main/ro/numbers.json'),
    "ru-RU": require('cldr-data/main/ru/numbers.json'),
    "si-LK": require('cldr-data/main/si/numbers.json'),
    "sk-SK": require('cldr-data/main/sk/numbers.json'),
    "sl-SI": require('cldr-data/main/sl/numbers.json'),
    "so-SO": require('cldr-data/main/so/numbers.json'),
    "sq-AL": require('cldr-data/main/sq/numbers.json'),
    "sr-RS": require('cldr-data/main/sr/numbers.json'),
    "sv": require('cldr-data/main/sv/numbers.json'),
    "sw": require('cldr-data/main/sw/numbers.json'),
    "th-TH": require('cldr-data/main/th/numbers.json'),
    "tk-TM": require('cldr-data/main/tk/numbers.json'),
    "tr-TR": require('cldr-data/main/tr/numbers.json'),
    "ur-PK": require('cldr-data/main/ur/numbers.json'),
    "uk-UA": require('cldr-data/main/uk/numbers.json'),
    "zh-hans-CN": require('cldr-data/main/zh-hans/numbers.json'),
    "zh-hant-HK": require('cldr-data/main/zh-hant-HK/numbers.json'),
    "zh-hant-MO": require('cldr-data/main/zh-hant-MO/numbers.json'),
    "zh-hant-TW": require('cldr-data/main/zh-hant-HK/numbers.json'),
    "vi": require('cldr-data/main/vi/numbers.json')
};
