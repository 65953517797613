module.exports={
  "main": {
    "es-MX": {
      "identity": {
        "version": {
          "_number": "$Revision: 14796 $",
          "_cldrVersion": "35.1"
        },
        "language": "es",
        "territory": "MX"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 mil",
              "1000-count-other": "0 mil",
              "10000-count-one": "00 mil",
              "10000-count-other": "00 mil",
              "100000-count-one": "000 mil",
              "100000-count-other": "000 mil",
              "1000000-count-one": "0 millón",
              "1000000-count-other": "0 millones",
              "10000000-count-one": "00 millones",
              "10000000-count-other": "00 millones",
              "100000000-count-one": "000 millones",
              "100000000-count-other": "000 millones",
              "1000000000-count-one": "0 mil millones",
              "1000000000-count-other": "0 mil millones",
              "10000000000-count-one": "00 mil millones",
              "10000000000-count-other": "00 mil millones",
              "100000000000-count-one": "000 mil millones",
              "100000000000-count-other": "000 mil millones",
              "1000000000000-count-one": "0 billón",
              "1000000000000-count-other": "0 billones",
              "10000000000000-count-one": "00 billones",
              "10000000000000-count-other": "00 billones",
              "100000000000000-count-one": "000 billones",
              "100000000000000-count-other": "000 billones"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 k",
              "1000-count-other": "0 k",
              "10000-count-one": "00 k",
              "10000-count-other": "00 k",
              "100000-count-one": "000 k",
              "100000-count-other": "000 k",
              "1000000-count-one": "0 M",
              "1000000-count-other": "0 M",
              "10000000-count-one": "00 M",
              "10000000-count-other": "00 M",
              "100000000-count-one": "000 M",
              "100000000-count-other": "000 M",
              "1000000000-count-one": "0000 M",
              "1000000000-count-other": "0000 M",
              "10000000000-count-one": "00 mil M",
              "10000000000-count-other": "00 mil M",
              "100000000000-count-one": "000 mil M",
              "100000000000-count-other": "000 mil M",
              "1000000000000-count-one": "0 B",
              "1000000000000-count-other": "0 B",
              "10000000000000-count-one": "00 B",
              "10000000000000-count-other": "00 B",
              "100000000000000-count-one": "000 B",
              "100000000000000-count-other": "000 B"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "0 k¤",
              "1000-count-other": "0 k¤",
              "10000-count-one": "00 k¤",
              "10000-count-other": "00 k¤",
              "100000-count-one": "000 k¤",
              "100000-count-other": "000 k¤",
              "1000000-count-one": "0 M¤",
              "1000000-count-other": "0 M¤",
              "10000000-count-one": "00 M¤",
              "10000000-count-other": "00 M¤",
              "100000000-count-one": "000 M¤",
              "100000000-count-other": "000 M¤",
              "1000000000-count-one": "0000 M¤",
              "1000000000-count-other": "0000 M¤",
              "10000000000-count-one": "00 MRD ¤",
              "10000000000-count-other": "00 MRD ¤",
              "100000000000-count-one": "000 MRD ¤",
              "100000000000-count-other": "000 MRD ¤",
              "1000000000000-count-one": "0 B¤",
              "1000000000000-count-other": "0 B¤",
              "10000000000000-count-one": "00 B¤",
              "10000000000000-count-other": "00 B¤",
              "100000000000000-count-one": "000 B¤",
              "100000000000000-count-other": "000 B¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "Más de {0}",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} día",
          "pluralMinimalPairs-count-other": "{0} días",
          "other": "Toma la {0}.ª a la derecha."
        }
      }
    }
  }
}
