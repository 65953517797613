module.exports={
  "main": {
    "km": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "km"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "khmr"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-khmr": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-khmr": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0 ពាន់",
              "10000-count-other": "00 ពាន់",
              "100000-count-other": "000ពាន់",
              "1000000-count-other": "0 លាន",
              "10000000-count-other": "00 លាន",
              "100000000-count-other": "000 លាន",
              "1000000000-count-other": "0 ប៊ីលាន",
              "10000000000-count-other": "00 ប៊ីលាន",
              "100000000000-count-other": "000 ប៊ីលាន",
              "1000000000000-count-other": "0 ទ្រីលាន",
              "10000000000000-count-other": "00 ទ្រីលាន",
              "100000000000000-count-other": "000 ទ្រីលាន"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0ពាន់",
              "10000-count-other": "00 ពាន់",
              "100000-count-other": "000 ពាន់",
              "1000000-count-other": "0 លាន",
              "10000000-count-other": "00 លាន",
              "100000000-count-other": "000 លាន",
              "1000000000-count-other": "0 ប៊ីលាន",
              "10000000000-count-other": "00 ប៊ីលាន",
              "100000000000-count-other": "000 ប៊ីលាន",
              "1000000000000-count-other": "0 ទ្រីលាន",
              "10000000000000-count-other": "00 ទ្រីលាន",
              "100000000000000-count-other": "000 ទ្រីលាន"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0 ពាន់",
              "10000-count-other": "00 ពាន់",
              "100000-count-other": "000ពាន់",
              "1000000-count-other": "0 លាន",
              "10000000-count-other": "00 លាន",
              "100000000-count-other": "000 លាន",
              "1000000000-count-other": "0 ប៊ីលាន",
              "10000000000-count-other": "00 ប៊ីលាន",
              "100000000000-count-other": "000 ប៊ីលាន",
              "1000000000000-count-other": "0 ទ្រីលាន",
              "10000000000000-count-other": "00 ទ្រីលាន",
              "100000000000000-count-other": "000 ទ្រីលាន"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0ពាន់",
              "10000-count-other": "00 ពាន់",
              "100000-count-other": "000 ពាន់",
              "1000000-count-other": "0 លាន",
              "10000000-count-other": "00 លាន",
              "100000000-count-other": "000 លាន",
              "1000000000-count-other": "0 ប៊ីលាន",
              "10000000000-count-other": "00 ប៊ីលាន",
              "100000000000-count-other": "000 ប៊ីលាន",
              "1000000000000-count-other": "0 ទ្រីលាន",
              "10000000000000-count-other": "00 ទ្រីលាន",
              "100000000000000-count-other": "000 ទ្រីលាន"
            }
          }
        },
        "scientificFormats-numberSystem-khmr": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-khmr": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-khmr": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00¤",
          "accounting": "#,##0.00¤;(#,##0.00¤)",
          "short": {
            "standard": {
              "1000-count-other": "¤0 ពាន់",
              "10000-count-other": "¤00 ពាន់",
              "100000-count-other": "¤000 ពាន់",
              "1000000-count-other": "¤0 លាន",
              "10000000-count-other": "¤00 លាន",
              "100000000-count-other": "¤000 លាន",
              "1000000000-count-other": "¤0 ប៊ីលាន",
              "10000000000-count-other": "¤00 ប៊ីលាន",
              "100000000000-count-other": "¤000 ប៊ីលាន",
              "1000000000000-count-other": "¤0 ទ្រីលាន",
              "10000000000000-count-other": "¤00 ទ្រីលាន",
              "100000000000000-count-other": "¤000 ទ្រីលាន"
            }
          },
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00¤",
          "accounting": "#,##0.00¤;(#,##0.00¤)",
          "short": {
            "standard": {
              "1000-count-other": "¤0 ពាន់",
              "10000-count-other": "¤00 ពាន់",
              "100000-count-other": "¤000 ពាន់",
              "1000000-count-other": "¤0 លាន",
              "10000000-count-other": "¤00 លាន",
              "100000000-count-other": "¤000 លាន",
              "1000000000-count-other": "¤0 ប៊ីលាន",
              "10000000000-count-other": "¤00 ប៊ីលាន",
              "100000000000-count-other": "¤000 ប៊ីលាន",
              "1000000000000-count-other": "¤0 ទ្រីលាន",
              "10000000000000-count-other": "¤00 ទ្រីលាន",
              "100000000000000-count-other": "¤000 ទ្រីលាន"
            }
          },
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-khmr": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-other": "{0} ថ្ងៃ",
          "other": "បត់​ស្តាំ​លើក​ទី​ {0}"
        }
      }
    }
  }
}
