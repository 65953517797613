import {Localizations, Pages, Units} from "./constants";
import {DefaultValues} from "./models";
import PersistenceManager from "./persistance";
import Accordions from "./accordions";
import Tooltips from "./tooltips";
import AppInstallBar from "./app-install-bar";
import * as calculation from "./calculations";

import {debounce, isNaN, random} from "lodash";
import Vue from 'vue';
import Big from "big.js";

// Number formatting
import * as Globalize from 'globalize';
import * as formats from './numberformatting';

const appSelector = "app";

const cutCalculation = new calculation.CutCalculation();
const costCalculation = new calculation.CostCalculation();
const unitConversion = new calculation.UnitConversion();
const validations = new calculation.Validations();

// Persistence Keys
const pumpKey = "selected_pump"
const materialKey = "selected_material";
const orificeNozzleKey = "selected_orifice_nozzle";
const qualityKey = "selected_quality";
const calculationKey = "calculation_values";
const customOrificeNozzleKey = "custom_orifice_nozzle";
const customMaterialKey = "custom_material";
const localizationsKey = "localizations";
const settingNotifcationKey = "notifications_settings";
const installNotificationKey = "notifications_install";

class AppView {
    private static parser:any;
	private static formatter:any;
	private static formatterNoDecimal:any;
	private static formatterThreeDecimal:any;
	private static resultFormatter:any;

	init() {
		// Set up Vue if main "app" element exists
		if (document.getElementById(appSelector)) {
		    this.setupNumberFormatting();
			this.setupVueInstance();
		}
	}

	setupNumberFormatting() {
		// Load required, base JSON files
		Globalize.load(formats.supplemental);
		Globalize.load(formats.numberingSystems);

		// Load localization value from local storage
		const localizations =  AppView.loadFromStorage(localizationsKey, DefaultValues.localizations);
		const numberFormat = localizations.numberFormatting.value;
		AppView.setNumberFormat(numberFormat);
	}

	setupVueInstance() {
		new Vue({
			el: "#" + appSelector,
			data: {
				selectedPump: AppView.loadFromStorage(pumpKey, DefaultValues.selectedPump),
				selectedMaterial: AppView.loadFromStorage(materialKey, DefaultValues.selectedMaterial),
				selectedOrificeNozzle: AppView.loadFromStorage(orificeNozzleKey, DefaultValues.selectedOrificeNozzle),
				selectedQuality: AppView.loadFromStorage(qualityKey, DefaultValues.selectedQuality),
				calculation: AppView.loadFromStorage(calculationKey, DefaultValues.calculation),
                calculationAsNumbers: null,
                localizations: AppView.loadFromStorage(localizationsKey, AppView.copyObject(DefaultValues.localizations)),
                savedLocalization: AppView.loadFromStorage(localizationsKey, AppView.copyObject(DefaultValues.localizations)),

				results: {
					cuttingWaterGPM: 0.00,
					stationaryPierceTime: 0.00,
					dynamicPierceTime: 0.00,
					energyCost: 0.00,
					waterCost: 0.00,
					abrasiveCost: 0.00,
					overallCostPerHour: 0.00,
					cutPerMinute: 0.00,
					costPerInch: 0.00,
					cutSpeedQuality1: 0.00,
					cutSpeedQuality2: 0.00,
					cutSpeedQuality3: 0.00,
					cutSpeedQuality4: 0.00,
					cutSpeedQuality5: 0.00
				},

				customOrificeNozzleForm: {
					orificeDiameter: "",
					nozzleDiameter: "",
					defaultAbrasiveFlow: "",
                    displayRemoveLink: false,
					currentEditingIndex: null,
					navigationHeader: ""
				},

				customMaterialForm: {
				    name: "",
					machinabilityIndex: "0.00",
					displayRemoveLink: false,
					currentEditingIndex: null,
					navigationHeader: ""
				},

				validations: {
				    validOrificeDiameter: true,
					validNozzleOrificeRatio: true,
					validCuttingWaterGPM: true,
					validPressure: true,
					validCustomOrificeNozzleFields: true,
					validCustomMaterialFields: true,
					inValidOrificeDiameterMessage: "",
					inValidNozzleOrificeRatioMessage: "",
					inValidCuttingWaterGPMMessage: "",
					inValidPressureMessage: "",

					allFormFieldsHaveValues: true,
					missingFields: null,
				},

				notifications: {
					displayInstall: PersistenceManager.readValue(installNotificationKey) !== "closed",
					displaySettings: PersistenceManager.readValue(settingNotifcationKey) !== "closed"
				},

				lists: {
					pumpList: DefaultValues.pumpList,
					materialList: DefaultValues.materialList,
					qualityList: DefaultValues.qualityList,
					orificeNozzleList: DefaultValues.orificeNozzleList,
					numberOfOrificeList: DefaultValues.numberOfOrificeList,
					customOrificeNozzleList: AppView.loadFromStorage(customOrificeNozzleKey, DefaultValues.customOrificeNozzleList),
					customMaterialList: AppView.loadFromStorage(customMaterialKey, DefaultValues.customMaterialList),
					unitsList: Localizations.units,
					currencyList: Localizations.currency,
					numberFormattingList: Localizations.numberFormatting
				}
			},
			watch: {
				/**
				 * Update calculation values when pump dropdown is changed
				 *
				 * @param newPump
				 * @param oldPump - not used
				 */
				selectedPump: function(newPump, oldPump) {
					this.calculation.pumpName = newPump.name;
					if (this.localizations.units.value === Units.METRIC) {
						this.calculation.pumpCoolingGPM = AppView.formatter(Number(newPump.pumpCoolingLPM));
						this.calculation.maxPumpGPM = AppView.formatter(Number(newPump.maxPumpLPM));
					} else {
						this.calculation.pumpCoolingGPM = AppView.formatter(Number(newPump.pumpCoolingGPM));
						this.calculation.maxPumpGPM = AppView.formatter(Number(newPump.maxPumpGPM));
					}
					this.calculation.pumpReplacementParts = AppView.formatter(Number(newPump.pumpReplacementPartsPerHour));
					this.calculation.kiloWatt = AppView.formatter(Number(newPump.kiloWatt));
				},
				/**
				 * Update calculation values when material dropdown is changed.
				 *
				 * @param newMaterial
				 * @param oldMaterial - not used
				 */
				selectedMaterial: function(newMaterial, oldMaterial) {
					this.calculation.machinabilityIndex = AppView.formatter(Number(newMaterial.machinabilityIndex));

					// Set default pierce and cut pressure if supplied
					if (this.localizations.units.value === Units.METRIC) {
						if (typeof newMaterial.defaultPiercePressureBar !== "undefined") {
							this.calculation.piercingPressure = AppView.formatterNoDecimal(Number(newMaterial.defaultPiercePressureBar));
						}

						if (typeof newMaterial.defaultCutPressureBar !== "undefined") {
							this.calculation.cuttingPressure = AppView.formatterNoDecimal(Number(newMaterial.defaultCutPressureBar));
						}
					} else {
						if (typeof newMaterial.defaultPiercePressurePSI !== "undefined") {
							this.calculation.piercingPressure = AppView.formatterNoDecimal(Number(newMaterial.defaultPiercePressurePSI));
						}

						if (typeof newMaterial.defaultCutPressurePSI !== "undefined") {
							this.calculation.cuttingPressure = AppView.formatterNoDecimal(Number(newMaterial.defaultCutPressurePSI));
						}
					}
				},
				/**
				 * Update calculation when orifice/nozzle dropdown is changed.
				 *
				 * @param newOrificeNozzle
				 * @param oldOrificeNozzle - not used
				 */
				selectedOrificeNozzle: function(newOrificeNozzle, oldOrificeNozzle) {
					const isMetric = this.localizations.units.value === Units.METRIC;
					const newOrificeDiameter = isMetric ? newOrificeNozzle.metric.orificeDiameter : newOrificeNozzle.usCustomary.orificeDiameter;
					const newNozzleDiameter = isMetric ? newOrificeNozzle.metric.nozzleDiameter : newOrificeNozzle.usCustomary.nozzleDiameter;
					const newAbrasiveFlow = isMetric ? newOrificeNozzle.metric.abrasiveFlow: newOrificeNozzle.usCustomary.abrasiveFlow;

					this.calculation.orificeDiameter = AppView.formatterThreeDecimal(Number(newOrificeDiameter));
					this.calculation.nozzleDiameter = AppView.formatterThreeDecimal(Number(newNozzleDiameter));
					this.calculation.abrasiveFlow = AppView.formatter(Number(newAbrasiveFlow));
				},
				/**
				 * Update calculation when cut quality dropdown is changed.
				 *
				 * @param newQuality
				 * @param oldQuality - not used
				 */
				selectedQuality: function(newQuality, oldQuality) {
					this.calculation.cutQuality = newQuality.value;
				},
				/**
				 * Main Vue watcher that handles watching the calculations object for changes in the values.
				 * On value change, calculations are run through a debounce function.
				 */
				calculation: {
					handler: function() {
						this.runCalculationsDebounced();
					},
					deep: true // Watch all values in the calculation object
				},
				/**
				 * Custom Orifice/Nozzle watcher. Updates abrasive flow value on orifice or nozzle change.
				 */
				customOrificeNozzleForm: {
					handler: function() {
						this.determineAbrasiveFlowDebounced();
					},
					deep: true
				}
			},
			/**
			 * Vue life-cycle method that is called when the instance is created.
			 */
			created() {
				// Create debounce (provided by lodash) functions that wraps runCalculations and determinerAbrasiveFlow methods
				this.runCalculationsDebounced = debounce(this.runCalculations, 600);
				this.determineAbrasiveFlowDebounced = debounce(this.determineAbrasiveFlow, 600);

				// Run first calculation on load
				this.runCalculations();
			},
			computed: {
				displayMaterial():string {
					return `${this.selectedMaterial.name} (${this.calculation.machinabilityIndex})`;
				},
				displayCurrency():string {
					return this.localizations.currency.description;
				},
				hasCustomOrifices():boolean {
				    return this.lists.customOrificeNozzleList.length > 0;
				},
				hasCustomMaterial():boolean {
					return this.lists.customMaterialList.length > 0;
				},
				displayInstallNotification():string {
				    return this.notifications.displayInstall ? 'display:flex' : 'display:none;';
				},
				displaySettingsNotification():string {
					return this.notifications.displaySettings ? 'display:flex' : 'display:none;';
				},
				pressureUnit():string {
					return this.localizations.units.value === Units.METRIC ? Units.BAR: Units.PSI;
				},
				weightUnit():string {
					return this.localizations.units.value === Units.METRIC ? Units.KG: Units.LB;
				},
				volumeUnit():string {
					return this.localizations.units.value === Units.METRIC ? Units.LTR: Units.GAL;
				},
				volumePerMinuteUnit():string {
					return this.localizations.units.value === Units.METRIC ? Units.LPM: Units.GPM;
				},
				sizeUnitMMandInches():string {
					return this.localizations.units.value === Units.METRIC ? Units.MM: Units.INCHES;
				},
				sizeUnitCMandInch():string {
					return this.localizations.units.value === Units.METRIC ? Units.CM: Units.INCH;
				},
				sizeUnitCMandInchCap():string {
					return this.localizations.units.value === Units.METRIC ? Units.CM_CAP: Units.INCH_CAP;
				},
				sizeUnitCMSandInches():string {
					return this.localizations.units.value === Units.METRIC ? Units.CMS: Units.INCHES;
				},
				currentUnitSettings():string {
					const currency = this.savedLocalization.currency.description;
					const value = 10.00;
                    return currency + AppView.resultFormatter(value);
				},
				newUnitSettings():string {
					const currency = this.localizations.currency.description;
					const conversion = AppView.parser(this.localizations.conversion);
					const numberFormatting = this.localizations.numberFormatting.value;

					// Only run conversion of properly formatted currency conversion input
					let value = 10.00;

					if (!isNaN(conversion)) {
						value = Number(unitConversion.convertCurrency(value, conversion));
					}

					// Load a temp formatter for displaying output
					// @ts-ignore:
					Globalize.load(formats.languagesFormatsJson[numberFormatting]);
					Globalize.locale(numberFormatting);
					const tempFormatter = Globalize.numberFormatter({
						minimumFractionDigits: 2,
						maximumFractionDigits: 2,
						useGrouping: false
					});

					return currency + tempFormatter(value);
				}
			},
			methods: {
				/**
				 * Main calculation method.
				 */
				isMetric() {
				    return this.localizations.units.value === Units.METRIC;
				},
				runCalculations() {
					AppView.outputObjectToConsole("Preparing to run calculation - Current values as strings: " + (this.isMetric() ? "METRIC" : "US Customary"), this.calculation);

					// Convert the string field values to numbers using globalize parser
					this.calculationAsNumbers = AppView.convertCalculationValuesToNumbers(this.calculation);

					AppView.outputObjectToConsole("Preparing to run calculation - Current values as number: " + (this.isMetric() ? "METRIC" : "US Customary"), this.calculationAsNumbers);

					// Confirm that all fields contain valid input data before running calculation
					if (AppView.containsInValidFields(this.calculationAsNumbers).length > 0) {
						return false;
					}

                    let valuesUsedForCalculation;

					// Convert metric values to us customary which are required by calculation.
					if (this.isMetric()) {
					    valuesUsedForCalculation = AppView.convertCalculationMetricValuesToUSCustomary(this.calculationAsNumbers);
					} else {
						valuesUsedForCalculation = AppView.copyObject(this.calculationAsNumbers);
					}

					// Run validations on converted calculation values
					this.runValidations(valuesUsedForCalculation);

					// CuttingWaterGPM
					const cuttingWaterGPM = AppView.calculateCuttingWaterGPM(valuesUsedForCalculation);
					if (this.isMetric()) {
						this.results.cuttingWaterGPM = AppView.resultFormatter(
						    AppView.calculateCuttingWaterLPM(cuttingWaterGPM)
						);
					} else {
						this.results.cuttingWaterGPM = AppView.resultFormatter(cuttingWaterGPM);
					}

					// Pierce Times
					this.results.stationaryPierceTime = AppView.resultFormatter(AppView.stationaryPierceTime(valuesUsedForCalculation));
					this.results.dynamicPierceTime = AppView.resultFormatter(AppView.dynamicPierceTime(valuesUsedForCalculation));

					// Cost Calculations
					this.results.energyCost = AppView.resultFormatter(AppView.energyCostPerHour(valuesUsedForCalculation));
					this.results.waterCost = AppView.resultFormatter(AppView.waterCostPerHour(valuesUsedForCalculation));
					this.results.abrasiveCost = AppView.resultFormatter(AppView.abrasiveCostPerHour(valuesUsedForCalculation));
					const overAllCostPerHour = AppView.overAllCostPerHour(valuesUsedForCalculation);

					const cutSpeed = AppView.calculateCutSpeed(valuesUsedForCalculation);

					// Determine alternative cut speeds
					valuesUsedForCalculation.cutQuality = 1;
					let cutSpeedQuality1:number = AppView.calculateCutSpeed(valuesUsedForCalculation);

					valuesUsedForCalculation.cutQuality = 2;
					let cutSpeedQuality2:number = AppView.calculateCutSpeed(valuesUsedForCalculation);

					valuesUsedForCalculation.cutQuality = 3;
					let cutSpeedQuality3:number = AppView.calculateCutSpeed(valuesUsedForCalculation);

					valuesUsedForCalculation.cutQuality = 4;
					let cutSpeedQuality4:number = AppView.calculateCutSpeed(valuesUsedForCalculation);

					valuesUsedForCalculation.cutQuality = 5;
					let cutSpeedQuality5:number = AppView.calculateCutSpeed(valuesUsedForCalculation);

					// If Metric
					if (this.isMetric()) {
						cutSpeedQuality1 = Number(unitConversion.convertInchesToMillimeters(cutSpeedQuality1));
						cutSpeedQuality2 = Number(unitConversion.convertInchesToMillimeters(cutSpeedQuality2));
						cutSpeedQuality3 = Number(unitConversion.convertInchesToMillimeters(cutSpeedQuality3));
						cutSpeedQuality4 = Number(unitConversion.convertInchesToMillimeters(cutSpeedQuality4));
						cutSpeedQuality5 = Number(unitConversion.convertInchesToMillimeters(cutSpeedQuality5));
					}

					// Add cuts speeds to results for use in quality dropdown
					this.results.cutSpeedQuality1 = AppView.resultFormatter(cutSpeedQuality1);
					this.results.cutSpeedQuality2 = AppView.resultFormatter(cutSpeedQuality2);
					this.results.cutSpeedQuality3 = AppView.resultFormatter(cutSpeedQuality3);
					this.results.cutSpeedQuality4 = AppView.resultFormatter(cutSpeedQuality4);
					this.results.cutSpeedQuality5 = AppView.resultFormatter(cutSpeedQuality5);

					// Total Calculations
					const costPerInch = AppView.costPerInch(overAllCostPerHour, cutSpeed);

					if (this.isMetric()) {
						this.results.costPerInch = AppView.resultFormatter(Number(unitConversion.convertPerInchToPerCentimeter(costPerInch)));
						this.results.cutPerMinute = AppView.resultFormatter(Number(unitConversion.convertInchesToCentimeters(cutSpeed)));
					} else {
						this.results.costPerInch = AppView.resultFormatter(costPerInch);
						this.results.cutPerMinute = AppView.resultFormatter(cutSpeed);
					}

					this.results.overallCostPerHour = AppView.resultFormatter(overAllCostPerHour);

					// Save the original calculation string values to the DB
					AppView.persistFormValues(this.calculation,
						this.selectedPump,
						this.selectedMaterial,
						this.selectedOrificeNozzle,
						this.selectedQuality);
				},
				linkToHome() {
					this.linkTo("CALC");
				},
				toggleAccordion(event:any) {
					Accordions.toggleAccordion(event.currentTarget);
				},
				showTooltip(event:any) {
					event.preventDefault();
					Tooltips.show(event.currentTarget);
				},
				hideTooltip(event:any) {
					event.preventDefault();
					Tooltips.hide(event.currentTarget);
				},
				displayQuality(selectedQuality:any) {
					return `Q${selectedQuality.value} - ${selectedQuality.description}`;
				},
				linkTo(pageId:string) {
					const pageIdClass = Pages[pageId];

					if (typeof pageIdClass !== "undefined") {
						const bodyClassList = document.body.classList;

						// Create a list of existing "screen--" related classes that should be removed
						const existingPageIdClasses = [];
						for (let i = 0; i < bodyClassList.length; i++) {
							let className = bodyClassList[i];

							if (className.match(/^screen--/g) || className.match(/^screenPrevious--/g)) {
								existingPageIdClasses.push(className);
							}
						}

						// Remove existing "screen--" classes
						for (let existingClassName of existingPageIdClasses) {
							bodyClassList.remove(existingClassName);
							if (existingClassName.indexOf("screenPrevious--") === -1) {
								let previousScreenClassNamePrefix = "screenPrevious--";
								let previousScreenClassName = existingClassName.replace(/^screen--/g, previousScreenClassNamePrefix);
								bodyClassList.add(previousScreenClassName);
								let mainClassName = (".main--" + previousScreenClassName.substring(previousScreenClassNamePrefix.length));
								let mainElem = document.querySelector(mainClassName) as HTMLElement;
								if (mainElem !== null) {
									let removeClassName = function(event:TransitionEvent) {
										if (event.target === mainElem) {
											mainElem.removeEventListener('transitionend', removeClassName);
											mainElem.classList.add("u-notransition");
											window.requestAnimationFrame(function(){
												bodyClassList.remove(previousScreenClassName);
												window.requestAnimationFrame(function(){
													mainElem.classList.remove("u-notransition");
												});
											});
										}
									}
									mainElem.addEventListener('transitionend', removeClassName);
								}
							}
						}

						// Scroll to top of page
						// document.getElementById("app").scrollTo(0, 0); // Doesn't work in Edge - https://stackoverflow.com/questions/51517324/scrollto-method-doesnt-work-in-edge
						document.getElementById("app").scrollTop = 0;

						// @ts-ignore
						ga('send', {
							hitType: 'pageview',
							page: pageIdClass
						});

						// Add new pageId class
						bodyClassList.add(pageIdClass);
					}
				},
				formattedOrificeNozzle(orificeNozzle:any) {
				    if (this.isMetric()) {
				    	return `${AppView.formatterThreeDecimal(Number(orificeNozzle.metric.orificeDiameter))}/${AppView.formatterThreeDecimal(Number(orificeNozzle.metric.nozzleDiameter))}`;
					} else {
						return `${AppView.formatterThreeDecimal(Number(orificeNozzle.usCustomary.orificeDiameter))}/${AppView.formatterThreeDecimal(Number(orificeNozzle.usCustomary.nozzleDiameter))}`;
					}
				},

				formattedMaterial(material:any) {
				    return `${material.name} (${AppView.formatter(Number(material.machinabilityIndex))})`;
				},

				formattedOrificeNumber(orificeNumber:string) {
					return AppView.formatterNoDecimal(Number(orificeNumber));
				},

				/******** Custom Orifice/Nozzle Section **********/

				/**
				 * View add a new custom orifice/nozzle form.
				 */
				addOrificeNozzle() {
					this.resetCustomOrificeNozzleForm();
					this.customOrificeNozzleForm.navigationHeader = "Add Orifice/Nozzle";
					this.customOrificeNozzleForm.nozzleDiameter = AppView.formatterThreeDecimal(0.0);
					this.customOrificeNozzleForm.orificeDiameter = AppView.formatterThreeDecimal(0.0);
					this.validations.validCustomOrificeNozzleFields = true;
                    this.linkTo("SAVE_ORIFICE");
				},
				/**
                 * Update existing custom orifice/nozzle combination.
				 */
				updateOrificeNozzle(index:number) {
					this.customOrificeNozzleForm.displayRemoveLink = true;
					this.validations.validCustomOrificeNozzleFields = true;

				    const customOrificeNozzleList = PersistenceManager.readObject(customOrificeNozzleKey);
				    const customOrificeNozzle = customOrificeNozzleList[index];

				    let orificeNozzle;
				    if (this.isMetric()) {
				    	orificeNozzle = customOrificeNozzle.metric;
					} else {
						orificeNozzle = customOrificeNozzle.usCustomary;
					}

				    this.customOrificeNozzleForm.nozzleDiameter = AppView.formatterThreeDecimal(Number(orificeNozzle.nozzleDiameter));
					this.customOrificeNozzleForm.orificeDiameter = AppView.formatterThreeDecimal(Number(orificeNozzle.orificeDiameter));
					this.customOrificeNozzleForm.navigationHeader = `${AppView.formatterThreeDecimal(Number(orificeNozzle.orificeDiameter))}/${AppView.formatterThreeDecimal(Number(orificeNozzle.nozzleDiameter))}`;
					this.customOrificeNozzleForm.currentEditingIndex = index;

					this.linkTo("SAVE_ORIFICE");
				},
				/**
				 * Determine a default abrasive flow based on values supplied in
				 * custom orifice/nozzle form.
				 */
				determineAbrasiveFlow() {
					const orificeDiameterAsNumber = AppView.parser(this.customOrificeNozzleForm.orificeDiameter);
					const nozzleDiameterAsNumber = AppView.parser(this.customOrificeNozzleForm.nozzleDiameter);

					if (isNaN(orificeDiameterAsNumber) || isNaN(nozzleDiameterAsNumber)) {
						return; // Don't perform calculation
					}

					const abrasiveFlow = cutCalculation.generateAbrasiveFlowValue(
					    orificeDiameterAsNumber,
						nozzleDiameterAsNumber,
                        this.isMetric()
					);

					this.customOrificeNozzleForm.defaultAbrasiveFlow = AppView.resultFormatter(abrasiveFlow);
				},
				/**
				 * Save custom orifice/nozzle combination
				 */
				saveOrificeNozzle() {
                    const currentIndex = this.customOrificeNozzleForm.currentEditingIndex;

					let customOrificeNozzleList:Array<any>;

					// Check local storage for existing list, create on if it doesn't exist
					if (PersistenceManager.checkForExistingValue(customOrificeNozzleKey)) {
						customOrificeNozzleList = PersistenceManager.readObject(customOrificeNozzleKey);
					} else {
						customOrificeNozzleList = new Array<any>();
					}

					// Load the current custom orifice/nozzle if exists, if not create a new one with a random ID
					let customOrificeNozzle = customOrificeNozzleList[currentIndex];
					if (typeof customOrificeNozzle === "undefined") {
						customOrificeNozzle = {
						    id: random(1, 10000000, false), // Generate random ID - from lodash
							usCustomary: {},
							metric: {}
						};
					}

					// Determine custom orifice values for both units
					const enteredOrificeDiameter = AppView.parser(this.customOrificeNozzleForm.orificeDiameter);
					const enteredNozzleDiameter = AppView.parser(this.customOrificeNozzleForm.nozzleDiameter);
					const calculatedAbrasiveFlow = AppView.parser(this.customOrificeNozzleForm.defaultAbrasiveFlow);

					// Display validation if orifice diameter or nozzle diameter is not set
					if (isNaN(enteredOrificeDiameter) || isNaN(enteredNozzleDiameter)) {
						this.validations.validCustomOrificeNozzleFields = false;
						return;
					}

                    if (this.isMetric()) {
                    	// Generate US Customary values
						const convertedOrificeDiameter = Number(unitConversion.convertMillimetersToInches(enteredOrificeDiameter));
						const convertedNozzleDiameter = Number(unitConversion.convertMillimetersToInches(enteredNozzleDiameter));

						const abrasiveFlowFromConvertedValues = cutCalculation.generateAbrasiveFlowValue(
							convertedOrificeDiameter,
							convertedNozzleDiameter,
                            false
						);

						customOrificeNozzle.usCustomary = {
							orificeDiameter: convertedOrificeDiameter,
							nozzleDiameter: convertedNozzleDiameter,
							abrasiveFlow: abrasiveFlowFromConvertedValues
						};

						// Use form values for metric
                        customOrificeNozzle.metric = {
							orificeDiameter: enteredOrificeDiameter,
							nozzleDiameter: enteredNozzleDiameter,
							abrasiveFlow: calculatedAbrasiveFlow,
						};
					} else {
                    	// Use form values for US Customary
						customOrificeNozzle.usCustomary = {
							orificeDiameter: enteredOrificeDiameter,
							nozzleDiameter: enteredNozzleDiameter,
							abrasiveFlow: calculatedAbrasiveFlow
						};

						// Generate Metric values
						const convertedOrificeDiameter = Number(unitConversion.convertInchesToMillimeters(enteredOrificeDiameter));
						const convertedNozzleDiameter = Number(unitConversion.convertInchesToMillimeters(enteredNozzleDiameter));

						const abrasiveFlowFromConvertedValues = cutCalculation.generateAbrasiveFlowValue(
							convertedOrificeDiameter,
							convertedNozzleDiameter,
                            true
						);

						customOrificeNozzle.metric = {
							orificeDiameter: convertedOrificeDiameter,
							nozzleDiameter: convertedNozzleDiameter,
							abrasiveFlow: abrasiveFlowFromConvertedValues
						};
					}

                    // If editing replace existing orifice/nozzle with new one created above
					if (currentIndex !== null) {
						customOrificeNozzleList.splice(currentIndex, 1, customOrificeNozzle);
					} else {
						customOrificeNozzleList.push(customOrificeNozzle);
					}

					// Reset selectedOrificeNozzle to default if edited one was selected.
					if (customOrificeNozzle.id === this.selectedOrificeNozzle.id) {
						this.selectedOrificeNozzle = DefaultValues.selectedOrificeNozzle;
					}

					// Save the updated list to local storage and set it as the current list
					PersistenceManager.saveObject(customOrificeNozzleKey, customOrificeNozzleList);
					this.lists.customOrificeNozzleList = customOrificeNozzleList;

					// Link back to the "ADD_ORIFICE" page and reset form values
					this.linkTo("ADD_ORIFICE");
					this.resetCustomOrificeNozzleForm();
				},
				/**
				 * Remove custom orifice/nozzle combination
				 */
				removeOrificeNozzle() {
					const currentIndex = this.customOrificeNozzleForm.currentEditingIndex;

					if (currentIndex !== null) {
						// Read custom orifice/nozzle list from local storage
						let customOrificeNozzleList = PersistenceManager.readObject(customOrificeNozzleKey);

						// Remove currently selected orifice/nozzle
						const removeOrificeNozzle = customOrificeNozzleList.splice(currentIndex, 1);

						// Save list to localStorage
						PersistenceManager.saveObject(customOrificeNozzleKey, customOrificeNozzleList);

						// Update Vue property
						this.lists.customOrificeNozzleList = customOrificeNozzleList;

						// Reset model to default because the custom orifice/nozzle removed was the selected value
						if (removeOrificeNozzle[0].id === this.selectedOrificeNozzle.id) {
							this.selectedOrificeNozzle = DefaultValues.selectedOrificeNozzle;
						}

						// Reset the form values
						this.resetCustomOrificeNozzleForm();
					}

					this.linkTo("ADD_ORIFICE");
				},

				/**
				 * Reset custom orifice/nozzle form values.
				 */
				resetCustomOrificeNozzleForm() {
					this.customOrificeNozzleForm.orificeDiameter = "";
					this.customOrificeNozzleForm.nozzleDiameter = "";
					this.customOrificeNozzleForm.defaultAbrasiveFlow = "";
					this.customOrificeNozzleForm.currentEditingIndex = null;
					this.customOrificeNozzleForm.displayRemoveLink = false;
				},

				/******** Custom Materials  **********/

				/**
				 * View "Add Material" form
				 */
				addMaterial() {
					this.resetCustomMaterial();
					this.customMaterialForm.navigationHeader = "Add Material";
					this.customMaterialForm.machinabilityIndex = AppView.formatter(0.0);
					this.validations.validCustomMaterialFields = true;
					this.linkTo("SAVE_MATERIAL");
				},

				/**
				 * Update existing custom material.
				 */
                updateMaterial(index:number) {
					this.validations.validCustomMaterialFields = true;
					this.customMaterialForm.displayRemoveLink = true;

					const customMaterialList = PersistenceManager.readObject(customMaterialKey);
					const customMaterial = customMaterialList[index];

					// Format index value
					const formattedMachinabilityIndex = AppView.formatter(Number(customMaterial.machinabilityIndex));

					this.customMaterialForm.name = customMaterial.name;
					this.customMaterialForm.machinabilityIndex = formattedMachinabilityIndex;
					this.customMaterialForm.navigationHeader = `${customMaterial.name} (${formattedMachinabilityIndex})`;
					this.customMaterialForm.currentEditingIndex = index;

					this.linkTo("SAVE_MATERIAL");
				},

				/**
				 * Save custom material.
				 */
				saveMaterial() {
					const currentIndex = this.customMaterialForm.currentEditingIndex;

					// Check local storage for existing list, create on if it doesn't exist
					let customMaterialList:Array<any>;

					if (PersistenceManager.checkForExistingValue(customMaterialKey)) {
						customMaterialList = PersistenceManager.readObject(customMaterialKey);
					} else {
						customMaterialList = new Array<any>();
					}

					// Load the current material if it exists, if not create a new one with a random ID
					let customMaterial = customMaterialList[currentIndex];

					if (typeof customMaterial === "undefined") {
						customMaterial = {
							id: random(1, 10000000, false), // Generate random ID - from lodash
							name: "",
							machinabilityIndex: ""
						};
					}

					customMaterial.name = this.customMaterialForm.name;

					const enteredMachinabilityIndex = AppView.parser(this.customMaterialForm.machinabilityIndex);

					// Display validation if machinability index is not set
					if (isNaN(enteredMachinabilityIndex)) {
						this.validations.validCustomMaterialFields = false;
						return;
					}

					customMaterial.machinabilityIndex = enteredMachinabilityIndex.toString();


					if (currentIndex !== null) {
						customMaterialList.splice(currentIndex, 1, customMaterial);
					} else {
						customMaterialList.push(customMaterial);
					}

					// Reset selectedMaterial
					if (customMaterial.id === this.selectedMaterial.id) {
						this.selectedMaterial = DefaultValues.selectedMaterial;
					}

					PersistenceManager.saveObject(customMaterialKey, customMaterialList);
					this.lists.customMaterialList = customMaterialList;
					this.linkTo("ADD_MATERIAL");
					this.resetCustomMaterial();
				},

				/**
				 * Remove custom material.
				 */
				removeMaterial() {
					const currentIndex = this.customMaterialForm.currentEditingIndex;

					if (currentIndex !== null) {
						// Read custom material list from local storage
						let customMaterialList = PersistenceManager.readObject(customMaterialKey);

						// Remove currently selected material from list
						const removedMaterial = customMaterialList.splice(currentIndex, 1);

						// Save list
						PersistenceManager.saveObject(customMaterialKey, customMaterialList);

						// Update view property
						this.lists.customMaterialList = customMaterialList;

						// Reset model because the custom material removed was the selected value
						if (removedMaterial[0].id === this.selectedMaterial.id) {
							this.selectedMaterial = DefaultValues.selectedMaterial;
						}

						this.resetCustomMaterial();
					}
					this.linkTo("ADD_MATERIAL");
				},

				/**
				 * Reset custom material form values.
				 */
				resetCustomMaterial() {
					this.customMaterialForm.name = "";
					this.customMaterialForm.machinabilityIndex = "";
                    this.customMaterialForm.displayRemoveLink = false;
					this.customMaterialForm.currentEditingIndex = null;
					this.customMaterialForm.navigationHeader = null;
				},

				/**
				 * View the Unit/Currency editing page.
				 */
				editUnitsCurrency() {
				    // Load the saved localizations object, used by computed property to display current settings
					this.savedLocalization = AppView.loadFromStorage(localizationsKey, AppView.copyObject(DefaultValues.localizations));
					this.localizations.conversion = AppView.formatter(1.0);

					// Reset validation methods
					this.validations.allFormFieldsHaveValues = true;
					this.validations.missingFields = null;
				    this.linkTo("SETTINGS_CURRENCY");
				},

				/**
				 * Undo unit/currency settings when backing out of the form without saving.
				 */
                undoUnitsCurrency() {
				    this.linkTo("SETTINGS");
				    this.localizations = AppView.loadFromStorage(localizationsKey, AppView.copyObject(DefaultValues.localizations));
				},

				/**
				 * Save unit/currency settings.
				 *
				 * 1) Update form fields based on unit selected
				 * 2) Convert the currency values
				 * 3) Persist settings to the database
				 */
				saveUnitsCurrency() {
					// Load saved localization values
					const savedLocalizations = AppView.loadFromStorage(localizationsKey, AppView.copyObject(DefaultValues.localizations));

					const differentUnits = savedLocalizations.units.value !== this.localizations.units.value;
					const differentNumberFormatting = savedLocalizations.numberFormatting.value !== this.localizations.numberFormatting.value;

					// Display message to user if there are invalid fields before running the unit/currency update
					const invalidFields = AppView.containsInValidFields(this.calculationAsNumbers);
					if (invalidFields.length > 0) {
					    this.validations.allFormFieldsHaveValues = false;
					    this.validations.missingFields = invalidFields;
						return;
					}

					// Check to see if current units value is different from saved value
					if (differentUnits) {
					    if (this.isMetric()) {
					    	this.calculationAsNumbers = AppView.runUsCustomaryToMetricConversion(this.calculationAsNumbers);

					    	// Use the metric orifice/nozzle values
							this.calculationAsNumbers.orificeDiameter = Number(this.selectedOrificeNozzle.metric.orificeDiameter);
							this.calculationAsNumbers.nozzleDiameter = Number(this.selectedOrificeNozzle.metric.nozzleDiameter);
							this.calculationAsNumbers.abrasiveFlow = Number(this.selectedOrificeNozzle.metric.abrasiveFlow);
						} else {
					        this.calculationAsNumbers = AppView.runMetricToUsCustomaryConversion(this.calculationAsNumbers);

							// Use the us customary orifice/nozzle values
							this.calculationAsNumbers.orificeDiameter = Number(this.selectedOrificeNozzle.usCustomary.orificeDiameter);
							this.calculationAsNumbers.nozzleDiameter = Number(this.selectedOrificeNozzle.usCustomary.nozzleDiameter);
							this.calculationAsNumbers.abrasiveFlow = Number(this.selectedOrificeNozzle.usCustomary.abrasiveFlow);
						}
					}

					// Check to see if current number formatting is different from current value
					if (differentNumberFormatting) {
						const numberFormat = this.localizations.numberFormatting.value

						// Load the new locale
                        AppView.setNumberFormat(numberFormat);
					}

					// Run currency conversion, and the set exchange rate back to 1
					const enteredCurrencyConversion = AppView.parser(this.localizations.conversion);
					if (!isNaN(enteredCurrencyConversion)) {
						this.calculationAsNumbers = AppView.runCurrencyConversion(this.calculationAsNumbers, enteredCurrencyConversion);
						AppView.outputObjectToConsole("Currency converted", this.calculationAsNumbers);
					}

					this.localizations.conversion = AppView.formatter(1.0);

					// Save the converted number values back to the view
					this.calculation = AppView.convertCalculationNumbersToStrings(this.calculationAsNumbers);
					AppView.outputObjectToConsole("Units/Currency Settings Update - Converted values as strings", this.calculation);

					// Save new localization values
				    PersistenceManager.saveObject(localizationsKey, this.localizations);

				    // Turn off settings notification
					this.notifications.displaySettings = false;
					PersistenceManager.saveValue(settingNotifcationKey, "closed");

					// Link back to SETTINGS page
				    this.linkTo("SETTINGS");
				},
				// Notifications
				closeNotification(id:string) {
					if (id === "install") {
						this.notifications.displayInstall = false;
						PersistenceManager.saveValue(installNotificationKey, "closed");
					} else if (id === "settings") {
						this.notifications.displaySettings = false;
						PersistenceManager.saveValue(settingNotifcationKey, "closed");
					}
				},
				displayCutQualityForIndex(index:number):string {
				    switch (index) {
						case 0:
						    return `${this.results.cutSpeedQuality1}`;
						case 1:
							return `${this.results.cutSpeedQuality2}`;
						case 2:
							return `${this.results.cutSpeedQuality3}`;
						case 3:
							return `${this.results.cutSpeedQuality4}`;
						case 4:
							return `${this.results.cutSpeedQuality5}`;
						default:
							return "";
					}
				},
				runValidations(calculationAsNumbers:any):boolean {
				    // Reset validations
					this.validations.validOrificeDiameter = true;
					this.validations.validNozzleOrificeRatio = true;
					this.validations.validPressure = true;
					this.validations.validCuttingWaterGPM = true;

				    let hasError = false;

					// Check valid minimum orifice diameter
					if (validations.invalidOrificeMinimumDiameter(calculationAsNumbers.orificeDiameter)) {
						this.validations.validOrificeDiameter = false;
						let minimumDiameter;
						if (this.isMetric()) {
							minimumDiameter = AppView.formatter(0.1016) +" mm.";
						} else {
							minimumDiameter = AppView.formatter(0.004) + " inches.";
						}

						this.validations.inValidOrificeDiameterMessage = "Orifice diameter is too small. It must be greater than " + minimumDiameter;
						hasError = true;
					}

					// Check valid maximum orifice diameter
					if (validations.invalidOrificeMaximumDiameter(calculationAsNumbers.orificeDiameter)) {
						this.validations.validOrificeDiameter = false;
						let maximumDiameter;
						if (this.isMetric()) {
							maximumDiameter = AppView.formatter(0.762) + " mm.";
						} else {
							maximumDiameter = AppView.formatter(0.03) + " inches.";
						}
						this.validations.inValidOrificeDiameterMessage = "Orifice diameter is too large. It must be smaller than " + maximumDiameter;
						hasError = true;
					}

					// Check valid orifice/nozzle ratio
					if (validations.invalidNozzleOrificeRatio(calculationAsNumbers.nozzleDiameter, calculationAsNumbers.orificeDiameter)) {
						this.validations.validNozzleOrificeRatio = false;
						this.validations.inValidNozzleOrificeRatioMessage = "The Nozzle/Orifice diameter ratio is greater than 1.5.";
						hasError = true;
					}

					// Check valid min pressure
					if (validations.invalidMinPressure(calculationAsNumbers.cuttingPressure, this.selectedPump.minPressure)) {
					    this.validations.validPressure = false;
					    let minPressure;
					    if (this.isMetric()) {
					    	minPressure = this.selectedPump.minPressureBar;
						} else {
					    	minPressure = this.selectedPump.minPressure;
						}
						this.validations.inValidPressureMessage = "Cutting pressure is less than the selected pumps minimum cutting pressure of " + minPressure;
						hasError = true;
					}

					if (validations.invalidMaxPressure(calculationAsNumbers.cuttingPressure, this.selectedPump.maxPressure)) {
						this.validations.validPressure = false;
						let maxPressure;
						if (this.isMetric()) {
							maxPressure = this.selectedPump.maxPressureBar;
						} else {
							maxPressure = this.selectedPump.maxPressure;
						}
						this.validations.inValidPressureMessage = "Cutting pressure is more than the selected pumps maximum cutting pressure of " + maxPressure;
						hasError = true;
					}

					// Check valid cutting water GPM
					const cuttingWaterGPM = AppView.calculateCuttingWaterGPM(calculationAsNumbers);
					let roundedCuttingWater;

					let maxPumpWater:number;
					if (this.isMetric()) {
						maxPumpWater = Number(this.selectedPump.maxPumpLPM);
						roundedCuttingWater = AppView.calculateCuttingWaterLPM(cuttingWaterGPM);
					} else {
						maxPumpWater = Number(this.selectedPump.maxPumpGPM);
						roundedCuttingWater = cuttingWaterGPM;
					}

					if (validations.invalidCuttingWaterGPM(roundedCuttingWater, maxPumpWater)) {
					    this.validations.validCuttingWaterGPM = false;
						let maxPumpGPMMessage;
						if (this.isMetric()) {
							maxPumpGPMMessage = "LPM of " + AppView.resultFormatter(maxPumpWater);
						} else {
							maxPumpGPMMessage = "GPM of " + AppView.resultFormatter(maxPumpWater);
						}
						this.validations.inValidCuttingWaterGPMMessage = "Cutting Water is greater than the pumps maximum water " + maxPumpGPMMessage;
						hasError = true;
					}

					return hasError;
				},
				containsValidationsErrors():boolean {
					if (
						this.invalidOrificeDiameter() ||
						this.invalidNozzleOrificeRatio() ||
						this.invalidCuttingWaterGPM() ||
						this.invalidPressure()
					) { return true; }

					return false;
				},
				invalidOrificeDiameter():boolean {
					return !this.validations.validOrificeDiameter;
				},
				invalidNozzleOrificeRatio():boolean {
					return !this.validations.validNozzleOrificeRatio;
				},
				invalidCuttingWaterGPM():boolean {
					return !this.validations.validCuttingWaterGPM;
				},
				invalidPressure():boolean {
					return !this.validations.validPressure;
				}
			}
		});
	}

	/* Helpers */

	/**
	 * Check that all values in object are valid numbers ( i.e., not NaN, null, or string ).
	 * Also confirm that some fields are not zero.
	 * Used when validating input from the form.
	 *
	 * @param obj
	 */
	private static containsInValidFields(obj:any) {
	    let invalidFields = [];
		for (const prop in obj) {
			if (obj.hasOwnProperty(prop)) {
				const value = obj[prop];

				// Don't check pumpName field
				if (prop === "pumpName") {
					continue;
				}

				// Check for a valid number
				if (isNaN(value) || value === null || typeof value !== "number") {
					invalidFields.push(prop);
				}

				// Confirm that certain fields are not zero
				if (prop === "cuttingPressure" || prop === "piercingPressure" || prop === "abrasiveFlow" || prop === "orificeDiameter") {
					if (value === 0) {
						invalidFields.push(prop);
					}
				}
			}
		}

		return invalidFields;
	}

	/**
	 * Make a completely new copy of an object.
	 *
	 * @param sourceObj
	 */
	private static copyObject(sourceObj:any):any {
		return JSON.parse(JSON.stringify(sourceObj));
	}

	/**
	 * Output object to console for debugging.
	 *
	 * @param obj
	 */
	private static outputObjectToConsole(label:string, obj:any):any {
		console.log(label);
		console.log(this.copyObject(obj));
	}

	/* Persistence */

	/**
	 * Load persisted object based on persistence key. If null return the default.
	 *
	 * @param persistenceKey
	 * @param defaultObj
	 */
	private static loadFromStorage(persistenceKey:string, defaultObj:any):any {
		const obj = PersistenceManager.readObject(persistenceKey);
		return obj !== null ? obj : defaultObj;
	}

	/**
	 * Persist all field values. Run after every field change.
	 *
	 * @param calculationValues
	 * @param selectedPump
	 * @param selectedMaterial
	 * @param selectedOrificeNozzle
	 * @param selectedQuality
	 */
	private static persistFormValues(calculationValues:any, selectedPump:any, selectedMaterial:any, selectedOrificeNozzle:any, selectedQuality:any) {
		PersistenceManager.saveObject(pumpKey, selectedPump);
		PersistenceManager.saveObject(materialKey, selectedMaterial);
		PersistenceManager.saveObject(orificeNozzleKey, selectedOrificeNozzle);
		PersistenceManager.saveObject(qualityKey, selectedQuality);
		PersistenceManager.saveObject(calculationKey, calculationValues);
	}

	/* Currency/Unit Conversion Methods */

	/**
	 * Convert the form string values to numbers based on the set number format parser.
	 *
	 * @param calculations
	 */
	private static convertCalculationValuesToNumbers(calculations:any):any {
	    // Make a copy of the calculations object when parsing
	    const calculationAsNumber = this.copyObject(calculations);

	    // Costs
	    calculationAsNumber.laborAndOverHeadCost = AppView.parser(calculations.laborAndOverHeadCost);
		calculationAsNumber.energyCost = AppView.parser(calculations.energyCost);
		calculationAsNumber.abrasiveCost = AppView.parser(calculations.abrasiveCost);
		calculationAsNumber.waterCost = AppView.parser(calculations.waterCost);
		calculationAsNumber.headReplacementPartsCost = AppView.parser(calculations.headReplacementPartsCost);

		// Pump
		calculationAsNumber.pumpCoolingGPM = AppView.parser(calculations.pumpCoolingGPM);
		calculationAsNumber.maxPumpGPM = AppView.parser(calculations.maxPumpGPM);
		calculationAsNumber.pumpReplacementParts = AppView.parser(calculations.pumpReplacementParts);
		calculationAsNumber.kiloWatt = AppView.parser(calculations.kiloWatt);

		// Material
		calculationAsNumber.machinabilityIndex = AppView.parser(calculations.machinabilityIndex);
		calculationAsNumber.thickness = AppView.parser(calculations.thickness);

		// Cut
		calculationAsNumber.orificeNumber = AppView.parser(calculations.orificeNumber);
		calculationAsNumber.cutQuality = parseInt(calculations.cutQuality);
		calculationAsNumber.abrasiveFlow = AppView.parser(calculations.abrasiveFlow);
		calculationAsNumber.orificeDiameter = AppView.parser(calculations.orificeDiameter);
		calculationAsNumber.nozzleDiameter = AppView.parser(calculations.nozzleDiameter);
		calculationAsNumber.cuttingPressure = AppView.parser(calculations.cuttingPressure);
		calculationAsNumber.piercingPressure = AppView.parser(calculations.piercingPressure);

		return calculationAsNumber;
	}

	private static convertCalculationNumbersToStrings(calculationAsNumber:any):any {
		// Make a copy of the calculationsNumber object when parsing
		const calculationAsString = this.copyObject(calculationAsNumber);

		// Costs
		calculationAsString.laborAndOverHeadCost = AppView.formatter(calculationAsNumber.laborAndOverHeadCost);
		calculationAsString.energyCost = AppView.formatter(calculationAsNumber.energyCost);
		calculationAsString.abrasiveCost = AppView.formatter(calculationAsNumber.abrasiveCost);
		calculationAsString.waterCost = AppView.formatter(calculationAsNumber.waterCost);
		calculationAsString.headReplacementPartsCost = AppView.formatter(calculationAsNumber.headReplacementPartsCost);

		// Pump
		calculationAsString.pumpCoolingGPM = AppView.formatter(calculationAsNumber.pumpCoolingGPM);
		calculationAsString.maxPumpGPM = AppView.formatter(calculationAsNumber.maxPumpGPM);
		calculationAsString.pumpReplacementParts = AppView.formatter(calculationAsNumber.pumpReplacementParts);
		calculationAsString.kiloWatt = AppView.formatter(calculationAsNumber.kiloWatt);

		// Material
		calculationAsString.machinabilityIndex = AppView.formatter(calculationAsNumber.machinabilityIndex);
		calculationAsString.thickness = AppView.formatter(calculationAsNumber.thickness);

		// Cut
		calculationAsString.orificeNumber = AppView.formatterNoDecimal(calculationAsNumber.orificeNumber);
		calculationAsString.cutQuality = calculationAsNumber.cutQuality.toString();
		calculationAsString.abrasiveFlow = AppView.formatter(calculationAsNumber.abrasiveFlow);
		calculationAsString.orificeDiameter = AppView.formatterThreeDecimal(calculationAsNumber.orificeDiameter);
		calculationAsString.nozzleDiameter = AppView.formatterThreeDecimal(calculationAsNumber.nozzleDiameter);
		calculationAsString.cuttingPressure = AppView.formatterNoDecimal(calculationAsNumber.cuttingPressure);
		calculationAsString.piercingPressure = AppView.formatterNoDecimal(calculationAsNumber.piercingPressure);

		return calculationAsString;
	}

	/**
	 * Convert metric calculation values that need to be US customary for formula.
	 *
	 * @param metricValues
	 */
	private static convertCalculationMetricValuesToUSCustomary(metricValues:any):any {
	    const usCustomaryValues = AppView.runMetricToUsCustomaryConversion(metricValues);

		usCustomaryValues.orificeDiameter = Number(unitConversion.convertMillimetersToInches(metricValues.orificeDiameter));
		usCustomaryValues.nozzleDiameter = Number(unitConversion.convertMillimetersToInches(metricValues.nozzleDiameter));

		AppView.outputObjectToConsole("Metric values found while running Calculations - Converted to US customary:", usCustomaryValues);

		return usCustomaryValues;
	}

	private static runUsCustomaryToMetricConversion(usCustomaryValues:any):any {
	    const metricValues = AppView.copyObject(usCustomaryValues);

		metricValues.abrasiveCost = Number(unitConversion.convertPerPoundToPerKg(usCustomaryValues.abrasiveCost))
		metricValues.waterCost = Number(unitConversion.convertPerGallonsToPerLitre(usCustomaryValues.waterCost));

		// Convert GPM to LPM
		metricValues.pumpCoolingGPM = Number(unitConversion.convertGallonsToLitres(usCustomaryValues.pumpCoolingGPM));
		metricValues.maxPumpGPM = Number(unitConversion.convertGallonsToLitres(usCustomaryValues.maxPumpGPM));

		// Convert inches to mm
		metricValues.thickness = Number(unitConversion.convertInchesToMillimeters(usCustomaryValues.thickness));

		// Convert abrasive flow
		metricValues.abrasiveFlow = Number(unitConversion.convertPoundsToKg(metricValues.abrasiveFlow));

		// Convert pressure
		metricValues.cuttingPressure = Number(unitConversion.convertPSIToBar(metricValues.cuttingPressure));
		metricValues.piercingPressure = Number(unitConversion.convertPSIToBar(metricValues.piercingPressure));

		return metricValues;
	}

	private static runMetricToUsCustomaryConversion(metricValues:any):any {
		const usCustomaryValues = AppView.copyObject(metricValues);

		// Convert abrasive cost
		usCustomaryValues.abrasiveCost = Number(unitConversion.convertPerKgToPerPound(metricValues.abrasiveCost));

		// Convert water cost
		usCustomaryValues.waterCost = Number(unitConversion.convertPerLiterToPerGallon(metricValues.waterCost));

		// Convert LPM to GPM
		usCustomaryValues.pumpCoolingGPM = Number(unitConversion.convertLitresToGallons(metricValues.pumpCoolingGPM));
		usCustomaryValues.maxPumpGPM = Number(unitConversion.convertLitresToGallons(metricValues.maxPumpGPM));

		// Convert mm to inches
		usCustomaryValues.thickness = Number(unitConversion.convertMillimetersToInches(metricValues.thickness));

		// Convert abrasive flow
		usCustomaryValues.abrasiveFlow = Number(unitConversion.convertKgToPound(metricValues.abrasiveFlow));

		// Convert pressure
		usCustomaryValues.cuttingPressure = Number(unitConversion.convertBarToPSI(metricValues.cuttingPressure));
		usCustomaryValues.piercingPressure = Number(unitConversion.convertBarToPSI(metricValues.piercingPressure));

		return usCustomaryValues;
	}

	private static runCurrencyConversion(calculationAsNumber:any, exchangeRate:number) {
	    const calculationNumberUpdatedCurrency = AppView.copyObject(calculationAsNumber);

		calculationNumberUpdatedCurrency.laborAndOverHeadCost = Number(unitConversion.convertCurrency(calculationAsNumber.laborAndOverHeadCost, exchangeRate));
		calculationNumberUpdatedCurrency.energyCost = Number(unitConversion.convertCurrency(calculationAsNumber.energyCost, exchangeRate));
		calculationNumberUpdatedCurrency.abrasiveCost = Number(unitConversion.convertCurrency(calculationAsNumber.abrasiveCost, exchangeRate));
		calculationNumberUpdatedCurrency.waterCost = Number(unitConversion.convertCurrency(calculationAsNumber.waterCost, exchangeRate));
		calculationNumberUpdatedCurrency.pumpReplacementParts = Number(unitConversion.convertCurrency(calculationAsNumber.pumpReplacementParts, exchangeRate));
		calculationNumberUpdatedCurrency.headReplacementPartsCost = Number(unitConversion.convertCurrency(calculationAsNumber.headReplacementPartsCost, exchangeRate));

		return calculationNumberUpdatedCurrency;
	}

	private static setNumberFormat(numberFormatValue:string) {
		Globalize.load(formats.languagesFormatsJson[numberFormatValue]);
		Globalize.locale(numberFormatValue);

		// @ts-ignore:
		AppView.parser = Globalize.numberParser({maximumFractionDigits: 3});
		// @ts-ignore:
		AppView.formatter = Globalize.numberFormatter({minimumFractionDigits: 2, maximumFractionDigits: 3, useGrouping: false});
		// @ts-ignore:
		AppView.formatterNoDecimal = Globalize.numberFormatter({maximumFractionDigits: 0, useGrouping: false});
		// @ts-ignore:
		AppView.resultFormatter = Globalize.numberFormatter({minimumFractionDigits: 2, maximumFractionDigits: 2, useGrouping: false});
		// @ts-ignore:
		AppView.formatterThreeDecimal = Globalize.numberFormatter({minimumFractionDigits: 3, maximumFractionDigits: 3, useGrouping: false});
	}

	/* Calculations - Cost */

	private static calculateCuttingWaterGPM(formulaValues:any):number {
		const cuttingWaterGPM:Big = costCalculation.cuttingWaterGPM(formulaValues.orificeNumber, formulaValues.orificeDiameter, formulaValues.cuttingPressure)

		console.log("Cutting Water GPM = " + cuttingWaterGPM)

		return Number(cuttingWaterGPM);
	}

	private static calculateCuttingWaterLPM(cuttingWaterGPM:number):number {
		return Number(unitConversion.convertGallonsToLitres(cuttingWaterGPM));
	}

	private static energyCostPerHour(formulaValues:any):number {
		const cuttingWaterGPM:Big = costCalculation.cuttingWaterGPM(formulaValues.orificeNumber, formulaValues.orificeDiameter, formulaValues.cuttingPressure)
		const nozzlePower:Big = costCalculation.nozzlePower(cuttingWaterGPM, formulaValues.cuttingPressure);
		const energyCost:Big = costCalculation.energyCost(nozzlePower, formulaValues.kiloWatt, formulaValues.energyCost)

		console.log("Energy Cost = " + energyCost);

		return Number(energyCost);
	}

	private static waterCostPerHour(formulaValues:any):number {
		const cuttingWaterGPM:Big = costCalculation.cuttingWaterGPM(formulaValues.orificeNumber, formulaValues.orificeDiameter, formulaValues.cuttingPressure)
		const waterCosts:Big = costCalculation.waterCost(formulaValues.waterCost, formulaValues.pumpCoolingGPM, cuttingWaterGPM);

		console.log("Water Cost = " + waterCosts);

		return Number(waterCosts);
	}

	private static abrasiveCostPerHour(formulaValues:any):number {
		const abrasiveCost:Big = costCalculation.abrasiveCost(formulaValues.abrasiveCost, formulaValues.abrasiveFlow, formulaValues.orificeNumber);

		console.log("Abrasive Cost = " + abrasiveCost);

		return Number(abrasiveCost);
	}

	private static overAllCostPerHour(formulaValues:any):number {
		const cuttingWaterGPM:Big = costCalculation.cuttingWaterGPM(formulaValues.orificeNumber, formulaValues.orificeDiameter, formulaValues.cuttingPressure)
		const nozzlePower:Big = costCalculation.nozzlePower(cuttingWaterGPM, formulaValues.cuttingPressure);

		const energyCost:Big = costCalculation.energyCost(nozzlePower, formulaValues.kiloWatt, formulaValues.energyCost)
		const replacePartsCost:Big = costCalculation.replacementPartsCost(formulaValues.pumpReplacementParts, formulaValues.headReplacementPartsCost, formulaValues.orificeNumber);
		const abrasiveCost:Big = costCalculation.abrasiveCost(formulaValues.abrasiveCost, formulaValues.abrasiveFlow, formulaValues.orificeNumber);
		const waterCost:Big = costCalculation.waterCost(formulaValues.waterCost, formulaValues.pumpCoolingGPM, cuttingWaterGPM);
		const laborCost:Big = new Big(formulaValues.laborAndOverHeadCost);

		const costPerHour:Big = costCalculation.costPerHour(replacePartsCost, waterCost, laborCost, energyCost, abrasiveCost);

		console.log("Replacement Part Cost = " + replacePartsCost);
		console.log("Cost per hour = " + costPerHour);

		return Number(costPerHour);
	}

	private static costPerInch(costPerHour:number, cutSpeed:number):number {
		const costPerInch:Big = costCalculation.costPerInch(new Big(costPerHour), new Big(cutSpeed));

		console.log("Cost Per inch = " + costPerInch);

		return Number(costPerInch);
	}

	/* Cut Speed & Pierce Time Calculations */

	private static dynamicPierceTime(formulaValues:any):number {
		const qualityIndex = cutCalculation.qualityIndex(formulaValues.thickness, formulaValues.cutQuality);
		const cuttingWaterPressure = cutCalculation.cuttingWaterPressure(formulaValues.piercingPressure);

		const dynamicPierceTime = cutCalculation.dynamicPierceTime(
													formulaValues.machinabilityIndex,
													formulaValues.thickness,
													formulaValues.orificeDiameter,
													formulaValues.nozzleDiameter,
													formulaValues.abrasiveFlow,
													cuttingWaterPressure,
													qualityIndex);
		console.log("Dynamic Pierce Time = " + dynamicPierceTime);

		return dynamicPierceTime;
	}

	private static stationaryPierceTime(formulaValues:any):number {
		const stationaryPierceTime = cutCalculation.stationaryPierceTime(formulaValues.machinabilityIndex, formulaValues.thickness, formulaValues.piercingPressure);
		console.log("Stationary Pierce Time = " + stationaryPierceTime);

		return stationaryPierceTime;
	}

	private static calculateCutSpeed(formulaValues:any):number {
		const qualityIndex = cutCalculation.qualityIndex(formulaValues.thickness, formulaValues.cutQuality);
		const cuttingWaterPressure = cutCalculation.cuttingWaterPressure(formulaValues.cuttingPressure);
		const efficiencyVariable = cutCalculation.efficiencyVariable(formulaValues.abrasiveFlow, cuttingWaterPressure, formulaValues.orificeDiameter, formulaValues.nozzleDiameter);
		const totalEfficiency = cutCalculation.totalEfficiency(efficiencyVariable);
		const abrasiveVelocity = cutCalculation.abrasiveVelocity(formulaValues.orificeDiameter, cuttingWaterPressure, formulaValues.abrasiveFlow);
		const feedRateVariable = cutCalculation.feedRateVariable(formulaValues.thickness, totalEfficiency, abrasiveVelocity, formulaValues.orificeDiameter, formulaValues.nozzleDiameter);
		const cutSpeed = cutCalculation.cutSpeed(formulaValues.machinabilityIndex, formulaValues.abrasiveFlow, feedRateVariable, qualityIndex, formulaValues.nozzleDiameter);

		console.log("Quality Index = " + qualityIndex);
		console.log("Cutting Water Pressure = " + cuttingWaterPressure);
		console.log("Efficiency Variable = " + efficiencyVariable);
		console.log("Total Efficiency = " + totalEfficiency);
		console.log("Abrasive Velocity = " + abrasiveVelocity);
		console.log("Feedrate Variable = " + feedRateVariable);
		console.log("Cut Speed = " + cutSpeed);

		return cutSpeed;
	}
}

export default new AppView();