module.exports={
  "main": {
    "fi": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "fi"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "−",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "epäluku",
          "timeSeparator": "."
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tuhat",
              "1000-count-other": "0 tuhatta",
              "10000-count-one": "00 tuhatta",
              "10000-count-other": "00 tuhatta",
              "100000-count-one": "000 tuhatta",
              "100000-count-other": "000 tuhatta",
              "1000000-count-one": "0 miljoona",
              "1000000-count-other": "0 miljoonaa",
              "10000000-count-one": "00 miljoonaa",
              "10000000-count-other": "00 miljoonaa",
              "100000000-count-one": "000 miljoonaa",
              "100000000-count-other": "000 miljoonaa",
              "1000000000-count-one": "0 miljardi",
              "1000000000-count-other": "0 miljardia",
              "10000000000-count-one": "00 miljardia",
              "10000000000-count-other": "00 miljardia",
              "100000000000-count-one": "000 miljardia",
              "100000000000-count-other": "000 miljardia",
              "1000000000000-count-one": "0 biljoona",
              "1000000000000-count-other": "0 biljoonaa",
              "10000000000000-count-one": "00 biljoonaa",
              "10000000000000-count-other": "00 biljoonaa",
              "100000000000000-count-one": "000 biljoonaa",
              "100000000000000-count-other": "000 biljoonaa"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 t'.'",
              "1000-count-other": "0 t'.'",
              "10000-count-one": "00 t'.'",
              "10000-count-other": "00 t'.'",
              "100000-count-one": "000 t'.'",
              "100000-count-other": "000 t'.'",
              "1000000-count-one": "0 milj'.'",
              "1000000-count-other": "0 milj'.'",
              "10000000-count-one": "00 milj'.'",
              "10000000-count-other": "00 milj'.'",
              "100000000-count-one": "000 milj'.'",
              "100000000-count-other": "000 milj'.'",
              "1000000000-count-one": "0 mrd'.'",
              "1000000000-count-other": "0 mrd'.'",
              "10000000000-count-one": "00 mrd'.'",
              "10000000000-count-other": "00 mrd'.'",
              "100000000000-count-one": "000 mrd'.'",
              "100000000000-count-other": "000 mrd'.'",
              "1000000000000-count-one": "0 bilj'.'",
              "1000000000000-count-other": "0 bilj'.'",
              "10000000000000-count-one": "00 bilj'.'",
              "10000000000000-count-other": "00 bilj'.'",
              "100000000000000-count-one": "000 bilj'.'",
              "100000000000000-count-other": "000 bilj'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 t'.' ¤",
              "1000-count-other": "0 t'.' ¤",
              "10000-count-one": "00 t'.' ¤",
              "10000-count-other": "00 t'.' ¤",
              "100000-count-one": "000 t'.' ¤",
              "100000-count-other": "000 t'.' ¤",
              "1000000-count-one": "0 milj'.' ¤",
              "1000000-count-other": "0 milj'.' ¤",
              "10000000-count-one": "00 milj'.' ¤",
              "10000000-count-other": "00 milj'.' ¤",
              "100000000-count-one": "000 milj'.' ¤",
              "100000000-count-other": "000 milj'.' ¤",
              "1000000000-count-one": "0 mrd'.' ¤",
              "1000000000-count-other": "0 mrd'.' ¤",
              "10000000000-count-one": "00 mrd'.' ¤",
              "10000000000-count-other": "00 mrd'.' ¤",
              "100000000000-count-one": "000 mrd'.' ¤",
              "100000000000-count-other": "000 mrd'.' ¤",
              "1000000000000-count-one": "0 bilj'.' ¤",
              "1000000000000-count-other": "0 bilj'.' ¤",
              "10000000000000-count-one": "00 bilj'.' ¤",
              "10000000000000-count-other": "00 bilj'.' ¤",
              "100000000000000-count-one": "000 bilj'.' ¤",
              "100000000000000-count-other": "000 bilj'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "vähintään {0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} päivä",
          "pluralMinimalPairs-count-other": "{0} päivää",
          "other": "Käänny {0}. risteyksestä oikealle."
        }
      }
    }
  }
}
