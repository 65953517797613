module.exports={
  "main": {
    "ca-AD": {
      "identity": {
        "version": {
          "_number": "$Revision: 14769 $",
          "_cldrVersion": "35.1"
        },
        "language": "ca",
        "territory": "AD"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 miler",
              "1000-count-other": "0 milers",
              "10000-count-one": "00 milers",
              "10000-count-other": "00 milers",
              "100000-count-one": "000 milers",
              "100000-count-other": "000 milers",
              "1000000-count-one": "0 milió",
              "1000000-count-other": "0 milions",
              "10000000-count-one": "00 milions",
              "10000000-count-other": "00 milions",
              "100000000-count-one": "000 milions",
              "100000000-count-other": "000 milions",
              "1000000000-count-one": "0 miler de milions",
              "1000000000-count-other": "0 milers de milions",
              "10000000000-count-one": "00 milers de milions",
              "10000000000-count-other": "00 milers de milions",
              "100000000000-count-one": "000 milers de milions",
              "100000000000-count-other": "000 milers de milions",
              "1000000000000-count-one": "0 bilió",
              "1000000000000-count-other": "0 bilions",
              "10000000000000-count-one": "00 bilions",
              "10000000000000-count-other": "00 bilions",
              "100000000000000-count-one": "000 bilions",
              "100000000000000-count-other": "000 bilions"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0m",
              "1000-count-other": "0m",
              "10000-count-one": "00m",
              "10000-count-other": "00m",
              "100000-count-one": "000m",
              "100000-count-other": "000m",
              "1000000-count-one": "0 M",
              "1000000-count-other": "0 M",
              "10000000-count-one": "00 M",
              "10000000-count-other": "00 M",
              "100000000-count-one": "000 M",
              "100000000-count-other": "000 M",
              "1000000000-count-one": "0000 M",
              "1000000000-count-other": "0000 M",
              "10000000000-count-one": "00mM",
              "10000000000-count-other": "00mM",
              "100000000000-count-one": "000mM",
              "100000000000-count-other": "000mM",
              "1000000000000-count-one": "0 B",
              "1000000000000-count-other": "0 B",
              "10000000000000-count-one": "00 B",
              "10000000000000-count-other": "00 B",
              "100000000000000-count-one": "000 B",
              "100000000000000-count-other": "000 B"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0m ¤",
              "1000-count-other": "0m ¤",
              "10000-count-one": "00m ¤",
              "10000-count-other": "00m ¤",
              "100000-count-one": "000m ¤",
              "100000-count-other": "000m ¤",
              "1000000-count-one": "0 M ¤",
              "1000000-count-other": "0 M ¤",
              "10000000-count-one": "00 M ¤",
              "10000000-count-other": "00 M ¤",
              "100000000-count-one": "000 M ¤",
              "100000000-count-other": "000 M ¤",
              "1000000000-count-one": "0000 M ¤",
              "1000000000-count-other": "0000 M ¤",
              "10000000000-count-one": "00mM ¤",
              "10000000000-count-other": "00mM ¤",
              "100000000000-count-one": "000mM ¤",
              "100000000000-count-other": "000mM ¤",
              "1000000000000-count-one": "0 B ¤",
              "1000000000000-count-other": "0 B ¤",
              "10000000000000-count-one": "00 B ¤",
              "10000000000000-count-other": "00 B ¤",
              "100000000000000-count-one": "000 B ¤",
              "100000000000000-count-other": "000 B ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥ {0}",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} dia",
          "pluralMinimalPairs-count-other": "{0} dies",
          "few": "Agafa el {0}t a la dreta.",
          "one": "Agafa el {0}r a la dreta.",
          "other": "Agafa el {0}è a la dreta.",
          "two": "Agafa el {0}n a la dreta."
        }
      }
    }
  }
}
