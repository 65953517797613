import NumberUtil from "./numberUtil";

const activeClass = "isActive";
const padding = 4;

class Tooltips {
	constructor() {}

	init() {}

	public show(tooltipIcon:HTMLElement) {
		const scrollContainer = document.getElementById("app");
		const content = this.getContentElement(tooltipIcon);
		content.classList.add(activeClass);

		// Position
		const iconRect = tooltipIcon.getBoundingClientRect();
		const contentRect = content.getBoundingClientRect();

		let left = iconRect.left + scrollContainer.scrollLeft;
		let top = iconRect.bottom + padding + scrollContainer.scrollTop;

		const spaceToRightEdge = (scrollContainer.clientWidth - iconRect.left) - contentRect.width;
		if (spaceToRightEdge < 0) { // A negative means it hangs over the right edge.
			left = left + spaceToRightEdge - padding;
		}

		content.style.left = NumberUtil.round(left) + "px";
		content.style.top = NumberUtil.round(top) + "px";
	}
	public hide(tooltipIcon:HTMLElement) {
		const content = this.getContentElement(tooltipIcon);
		content.classList.remove(activeClass);
		content.style.left = "";
		content.style.top = "";
	}

	private getContentElement(tooltipIcon:HTMLElement):HTMLElement {
		const id = tooltipIcon.getAttribute("href").split("#")[1];
		return document.getElementById(id);
	}
}

export default new Tooltips();
