module.exports={
  "main": {
    "be": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "be"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 тысяча",
              "1000-count-few": "0 тысячы",
              "1000-count-many": "0 тысяч",
              "1000-count-other": "0 тысячы",
              "10000-count-one": "00 тысяча",
              "10000-count-few": "00 тысячы",
              "10000-count-many": "00 тысяч",
              "10000-count-other": "00 тысячы",
              "100000-count-one": "000 тысяча",
              "100000-count-few": "000 тысячы",
              "100000-count-many": "000 тысяч",
              "100000-count-other": "000 тысячы",
              "1000000-count-one": "0 мільён",
              "1000000-count-few": "0 мільёны",
              "1000000-count-many": "0 мільёнаў",
              "1000000-count-other": "0 мільёна",
              "10000000-count-one": "00 мільён",
              "10000000-count-few": "00 мільёны",
              "10000000-count-many": "00 мільёнаў",
              "10000000-count-other": "00 мільёна",
              "100000000-count-one": "000 мільён",
              "100000000-count-few": "000 мільёны",
              "100000000-count-many": "000 мільёнаў",
              "100000000-count-other": "000 мільёна",
              "1000000000-count-one": "0 мільярд",
              "1000000000-count-few": "0 мільярды",
              "1000000000-count-many": "0 мільярдаў",
              "1000000000-count-other": "0 мільярда",
              "10000000000-count-one": "00 мільярд",
              "10000000000-count-few": "00 мільярды",
              "10000000000-count-many": "00 мільярдаў",
              "10000000000-count-other": "00 мільярда",
              "100000000000-count-one": "000 мільярд",
              "100000000000-count-few": "000 мільярды",
              "100000000000-count-many": "000 мільярдаў",
              "100000000000-count-other": "000 мільярда",
              "1000000000000-count-one": "0 трыльён",
              "1000000000000-count-few": "0 трыльёны",
              "1000000000000-count-many": "0 трыльёнаў",
              "1000000000000-count-other": "0 трыльёна",
              "10000000000000-count-one": "00 трыльён",
              "10000000000000-count-few": "00 трыльёны",
              "10000000000000-count-many": "00 трыльёнаў",
              "10000000000000-count-other": "00 трыльёна",
              "100000000000000-count-one": "000 трыльён",
              "100000000000000-count-few": "000 трыльёны",
              "100000000000000-count-many": "000 трыльёнаў",
              "100000000000000-count-other": "000 трыльёна"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 тыс'.'",
              "1000-count-few": "0 тыс'.'",
              "1000-count-many": "0 тыс'.'",
              "1000-count-other": "0 тыс'.'",
              "10000-count-one": "00 тыс'.'",
              "10000-count-few": "00 тыс'.'",
              "10000-count-many": "00 тыс'.'",
              "10000-count-other": "00 тыс'.'",
              "100000-count-one": "000 тыс'.'",
              "100000-count-few": "000 тыс'.'",
              "100000-count-many": "000 тыс'.'",
              "100000-count-other": "000 тыс'.'",
              "1000000-count-one": "0 млн",
              "1000000-count-few": "0 млн",
              "1000000-count-many": "0 млн",
              "1000000-count-other": "0 млн",
              "10000000-count-one": "00 млн",
              "10000000-count-few": "00 млн",
              "10000000-count-many": "00 млн",
              "10000000-count-other": "00 млн",
              "100000000-count-one": "000 млн",
              "100000000-count-few": "000 млн",
              "100000000-count-many": "000 млн",
              "100000000-count-other": "000 млн",
              "1000000000-count-one": "0 млрд",
              "1000000000-count-few": "0 млрд",
              "1000000000-count-many": "0 млрд",
              "1000000000-count-other": "0 млрд",
              "10000000000-count-one": "00 млрд",
              "10000000000-count-few": "00 млрд",
              "10000000000-count-many": "00 млрд",
              "10000000000-count-other": "00 млрд",
              "100000000000-count-one": "000 млрд",
              "100000000000-count-few": "000 млрд",
              "100000000000-count-many": "000 млрд",
              "100000000000-count-other": "000 млрд",
              "1000000000000-count-one": "0 трлн",
              "1000000000000-count-few": "0 трлн",
              "1000000000000-count-many": "0 трлн",
              "1000000000000-count-other": "0 трлн",
              "10000000000000-count-one": "00 трлн",
              "10000000000000-count-few": "00 трлн",
              "10000000000000-count-many": "00 трлн",
              "10000000000000-count-other": "00 трлн",
              "100000000000000-count-one": "000 трлн",
              "100000000000000-count-few": "000 трлн",
              "100000000000000-count-many": "000 трлн",
              "100000000000000-count-other": "000 трлн"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 тыс'.' ¤",
              "1000-count-few": "0 тыс'.' ¤",
              "1000-count-many": "0 тыс'.' ¤",
              "1000-count-other": "0 тыс'.' ¤",
              "10000-count-one": "00 тыс'.' ¤",
              "10000-count-few": "00 тыс'.' ¤",
              "10000-count-many": "00 тыс'.' ¤",
              "10000-count-other": "00 тыс'.' ¤",
              "100000-count-one": "000 тыс'.' ¤",
              "100000-count-few": "000 тыс'.' ¤",
              "100000-count-many": "000 тыс'.' ¤",
              "100000-count-other": "000 тыс'.' ¤",
              "1000000-count-one": "0 млн ¤",
              "1000000-count-few": "0 млн ¤",
              "1000000-count-many": "0 млн ¤",
              "1000000-count-other": "0 млн ¤",
              "10000000-count-one": "00 млн ¤",
              "10000000-count-few": "00 млн ¤",
              "10000000-count-many": "00 млн ¤",
              "10000000-count-other": "00 млн ¤",
              "100000000-count-one": "000 млн ¤",
              "100000000-count-few": "000 млн ¤",
              "100000000-count-many": "000 млн ¤",
              "100000000-count-other": "000 млн ¤",
              "1000000000-count-one": "0 млрд ¤ ",
              "1000000000-count-few": "0 млрд ¤ ",
              "1000000000-count-many": "0 млрд ¤ ",
              "1000000000-count-other": "0 млрд ¤ ",
              "10000000000-count-one": "00 млрд ¤",
              "10000000000-count-few": "00 млрд ¤",
              "10000000000-count-many": "00 млрд ¤",
              "10000000000-count-other": "00 млрд ¤",
              "100000000000-count-one": "000 млрд ¤",
              "100000000000-count-few": "000 млрд ¤",
              "100000000000-count-many": "000 млрд ¤",
              "100000000000-count-other": "000 млрд ¤",
              "1000000000000-count-one": "0 трлн ¤",
              "1000000000000-count-few": "0 трлн ¤",
              "1000000000000-count-many": "0 трлн ¤",
              "1000000000000-count-other": "0 трлн ¤",
              "10000000000000-count-one": "00 трлн ¤",
              "10000000000000-count-few": "00 трлн ¤",
              "10000000000000-count-many": "00 трлн ¤",
              "10000000000000-count-other": "00 трлн ¤",
              "100000000000000-count-one": "000 трлн ¤",
              "100000000000000-count-few": "000 трлн ¤",
              "100000000000000-count-many": "000 трлн ¤",
              "100000000000000-count-other": "000 трлн ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "≈{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "з {0} кнігі за {0} дзень",
          "pluralMinimalPairs-count-few": "з {0} кніг за {0} дні",
          "pluralMinimalPairs-count-many": "з {0} кніг за {0} дзён",
          "pluralMinimalPairs-count-other": "з {0} кнігі за {0} дня",
          "few": "{0}-і дом злева",
          "other": "{0}-ы дом злева"
        }
      }
    }
  }
}
