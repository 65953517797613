module.exports={
  "main": {
    "hu": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "hu"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "4",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 ezer",
              "1000-count-other": "0 ezer",
              "10000-count-one": "00 ezer",
              "10000-count-other": "00 ezer",
              "100000-count-one": "000 ezer",
              "100000-count-other": "000 ezer",
              "1000000-count-one": "0 millió",
              "1000000-count-other": "0 millió",
              "10000000-count-one": "00 millió",
              "10000000-count-other": "00 millió",
              "100000000-count-one": "000 millió",
              "100000000-count-other": "000 millió",
              "1000000000-count-one": "0 milliárd",
              "1000000000-count-other": "0 milliárd",
              "10000000000-count-one": "00 milliárd",
              "10000000000-count-other": "00 milliárd",
              "100000000000-count-one": "000 milliárd",
              "100000000000-count-other": "000 milliárd",
              "1000000000000-count-one": "0 billió",
              "1000000000000-count-other": "0 billió",
              "10000000000000-count-one": "00 billió",
              "10000000000000-count-other": "00 billió",
              "100000000000000-count-one": "000 billió",
              "100000000000000-count-other": "000 billió"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 E",
              "1000-count-other": "0 E",
              "10000-count-one": "00 E",
              "10000-count-other": "00 E",
              "100000-count-one": "000 E",
              "100000-count-other": "000 E",
              "1000000-count-one": "0 M",
              "1000000-count-other": "0 M",
              "10000000-count-one": "00 M",
              "10000000-count-other": "00 M",
              "100000000-count-one": "000 M",
              "100000000-count-other": "000 M",
              "1000000000-count-one": "0 Mrd",
              "1000000000-count-other": "0 Mrd",
              "10000000000-count-one": "00 Mrd",
              "10000000000-count-other": "00 Mrd",
              "100000000000-count-one": "000 Mrd",
              "100000000000-count-other": "000 Mrd",
              "1000000000000-count-one": "0 B",
              "1000000000000-count-other": "0 B",
              "10000000000000-count-one": "00 B",
              "10000000000000-count-other": "00 B",
              "100000000000000-count-one": "000 B",
              "100000000000000-count-other": "000 B"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 E ¤",
              "1000-count-other": "0 E ¤",
              "10000-count-one": "00 E ¤",
              "10000-count-other": "00 E ¤",
              "100000-count-one": "000 E ¤",
              "100000-count-other": "000 E ¤",
              "1000000-count-one": "0 M ¤",
              "1000000-count-other": "0 M ¤",
              "10000000-count-one": "00 M ¤",
              "10000000-count-other": "00 M ¤",
              "100000000-count-one": "000 M ¤",
              "100000000-count-other": "000 M ¤",
              "1000000000-count-one": "0 Mrd ¤",
              "1000000000-count-other": "0 Mrd ¤",
              "10000000000-count-one": "00 Mrd ¤",
              "10000000000-count-other": "00 Mrd ¤",
              "100000000000-count-one": "000 Mrd ¤",
              "100000000000-count-other": "000 Mrd ¤",
              "1000000000000-count-one": "0 B ¤",
              "1000000000000-count-other": "0 B ¤",
              "10000000000000-count-one": "00 B ¤",
              "10000000000000-count-other": "00 B ¤",
              "100000000000000-count-one": "000 B ¤",
              "100000000000000-count-other": "000 B ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "A kosár tartalma: {0} X. Megveszi?",
          "pluralMinimalPairs-count-other": "A kosár tartalma: {0} X. Megveszi őket?",
          "one": "Az {0}. lehetőségnél forduljon jobbra.",
          "other": "A {0}. lehetőségnél forduljon jobbra."
        }
      }
    }
  }
}
