module.exports={
  "main": {
    "my": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "my"
      },
      "numbers": {
        "defaultNumberingSystem": "mymr",
        "otherNumberingSystems": {
          "native": "mymr"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "ဂဏန်းမဟုတ်သော",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-mymr": {
          "decimal": ".",
          "group": ",",
          "list": "၊",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "ဂဏန်းမဟုတ်သော",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0ထောင်",
              "10000-count-other": "0သောင်း",
              "100000-count-other": "0သိန်း",
              "1000000-count-other": "0သန်း",
              "10000000-count-other": "0ကုဋေ",
              "100000000-count-other": "00ကုဋေ",
              "1000000000-count-other": "ကုဋေ000",
              "10000000000-count-other": "ကုဋေ0000",
              "100000000000-count-other": "ကုဋေ0သောင်း",
              "1000000000000-count-other": "ကုဋေ0သိန်း",
              "10000000000000-count-other": "ကုဋေ0သန်း",
              "100000000000000-count-other": "0ကောဋိ"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0ထောင်",
              "10000-count-other": "0သောင်း",
              "100000-count-other": "0သိန်း",
              "1000000-count-other": "0သန်း",
              "10000000-count-other": "0ကုဋေ",
              "100000000-count-other": "00ကုဋေ",
              "1000000000-count-other": "ကုဋေ000",
              "10000000000-count-other": "ကုဋေ0ထ",
              "100000000000-count-other": "ကုဋေ0သ",
              "1000000000000-count-other": "ဋေ0သိန်း",
              "10000000000000-count-other": "ဋေ0သန်း",
              "100000000000000-count-other": "0ကောဋိ"
            }
          }
        },
        "decimalFormats-numberSystem-mymr": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0ထောင်",
              "10000-count-other": "0သောင်း",
              "100000-count-other": "0သိန်း",
              "1000000-count-other": "0သန်း",
              "10000000-count-other": "0ကုဋေ",
              "100000000-count-other": "00ကုဋေ",
              "1000000000-count-other": "ကုဋေ000",
              "10000000000-count-other": "ကုဋေ0000",
              "100000000000-count-other": "ကုဋေ0သောင်း",
              "1000000000000-count-other": "ကုဋေ0သိန်း",
              "10000000000000-count-other": "ကုဋေ0သန်း",
              "100000000000000-count-other": "0ကောဋိ"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0ထောင်",
              "10000-count-other": "0သောင်း",
              "100000-count-other": "0သိန်း",
              "1000000-count-other": "0သန်း",
              "10000000-count-other": "0ကုဋေ",
              "100000000-count-other": "00ကုဋေ",
              "1000000000-count-other": "ကုဋေ000",
              "10000000000-count-other": "ကုဋေ0ထ",
              "100000000000-count-other": "ကုဋေ0သ",
              "1000000000000-count-other": "ဋေ0သိန်း",
              "10000000000000-count-other": "ဋေ0သန်း",
              "100000000000000-count-other": "0ကောဋိ"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-mymr": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-mymr": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "¤ #,##0.00",
          "short": {
            "standard": {
              "1000-count-other": "¤ 0ထောင်",
              "10000-count-other": "¤ 0သောင်း",
              "100000-count-other": "¤ 0သိန်း",
              "1000000-count-other": "¤ 0သန်း",
              "10000000-count-other": "¤ 0ကုဋေ",
              "100000000-count-other": "¤ 00ကုဋေ",
              "1000000000-count-other": "¤ ကုဋေ000",
              "10000000000-count-other": "¤ ကုဋေ0000",
              "100000000000-count-other": "¤ ကုဋေ0သောင်း",
              "1000000000000-count-other": "¤ ကုဋေ0သိန်း",
              "10000000000000-count-other": "¤ ကုဋေ0သန်း",
              "100000000000000-count-other": "¤ 0ကောဋိ"
            }
          },
          "unitPattern-count-other": "{1} {0}"
        },
        "currencyFormats-numberSystem-mymr": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "¤ #,##0.00",
          "short": {
            "standard": {
              "1000-count-other": "¤ 0ထောင်",
              "10000-count-other": "¤ 0သောင်း",
              "100000-count-other": "¤ 0သိန်း",
              "1000000-count-other": "¤ 0သန်း",
              "10000000-count-other": "¤ 0ကုဋေ",
              "100000000-count-other": "¤ 00ကုဋေ",
              "1000000000-count-other": "¤ ကုဋေ000",
              "10000000000-count-other": "¤ ကုဋေ0000",
              "100000000000-count-other": "¤ ကုဋေ0သောင်း",
              "1000000000000-count-other": "¤ ကုဋေ0သိန်း",
              "10000000000000-count-other": "¤ ကုဋေ0သန်း",
              "100000000000000-count-other": "¤ 0ကောဋိ"
            }
          },
          "unitPattern-count-other": "{1} {0}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0} နှင့်အထက်",
          "atMost": "≤{0}",
          "range": "{0} - {1}"
        },
        "miscPatterns-numberSystem-mymr": {
          "approximately": "~{0}",
          "atLeast": "{0} နှင့်အထက်",
          "atMost": "≤{0}",
          "range": "{0} - {1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-other": "{0}ရက်",
          "other": "{0} အုပ်မြောက်"
        }
      }
    }
  }
}
