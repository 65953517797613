module.exports={
  "main": {
    "ar": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "ar"
      },
      "numbers": {
        "defaultNumberingSystem": "arab",
        "defaultNumberingSystem-alt-latn": "latn",
        "otherNumberingSystems": {
          "native": "arab"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-arab": {
          "decimal": "٫",
          "group": "٬",
          "list": "؛",
          "percentSign": "٪؜",
          "plusSign": "؜+",
          "minusSign": "؜-",
          "exponential": "اس",
          "superscriptingExponent": "×",
          "perMille": "؉",
          "infinity": "∞",
          "nan": "ليس رقم",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "‎%‎",
          "plusSign": "‎+",
          "minusSign": "‎-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "ليس رقمًا",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-arab": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-zero": "0 ألف",
              "1000-count-one": "0 ألف",
              "1000-count-two": "0 ألف",
              "1000-count-few": "0 آلاف",
              "1000-count-many": "0 ألف",
              "1000-count-other": "0 ألف",
              "10000-count-zero": "00 ألف",
              "10000-count-one": "00 ألف",
              "10000-count-two": "00 ألف",
              "10000-count-few": "00 ألف",
              "10000-count-many": "00 ألف",
              "10000-count-other": "00 ألف",
              "100000-count-zero": "000 ألف",
              "100000-count-one": "000 ألف",
              "100000-count-two": "000 ألف",
              "100000-count-few": "000 ألف",
              "100000-count-many": "000 ألف",
              "100000-count-other": "000 ألف",
              "1000000-count-zero": "0 مليون",
              "1000000-count-one": "0 مليون",
              "1000000-count-two": "0 مليون",
              "1000000-count-few": "0 ملايين",
              "1000000-count-many": "0 مليون",
              "1000000-count-other": "0 مليون",
              "10000000-count-zero": "00 مليون",
              "10000000-count-one": "00 مليون",
              "10000000-count-two": "00 مليون",
              "10000000-count-few": "00 ملايين",
              "10000000-count-many": "00 مليون",
              "10000000-count-other": "00 مليون",
              "100000000-count-zero": "000 مليون",
              "100000000-count-one": "000 مليون",
              "100000000-count-two": "000 مليون",
              "100000000-count-few": "000 مليون",
              "100000000-count-many": "000 مليون",
              "100000000-count-other": "000 مليون",
              "1000000000-count-zero": "0 مليار",
              "1000000000-count-one": "0 مليار",
              "1000000000-count-two": "0 مليار",
              "1000000000-count-few": "0 مليار",
              "1000000000-count-many": "0 مليار",
              "1000000000-count-other": "0 مليار",
              "10000000000-count-zero": "00 مليار",
              "10000000000-count-one": "00 مليار",
              "10000000000-count-two": "00 مليار",
              "10000000000-count-few": "00 مليار",
              "10000000000-count-many": "00 مليار",
              "10000000000-count-other": "00 مليار",
              "100000000000-count-zero": "000 مليار",
              "100000000000-count-one": "000 مليار",
              "100000000000-count-two": "000 مليار",
              "100000000000-count-few": "000 مليار",
              "100000000000-count-many": "000 مليار",
              "100000000000-count-other": "000 مليار",
              "1000000000000-count-zero": "0 ترليون",
              "1000000000000-count-one": "0 ترليون",
              "1000000000000-count-two": "0 ترليون",
              "1000000000000-count-few": "0 ترليون",
              "1000000000000-count-many": "0 ترليون",
              "1000000000000-count-other": "0 ترليون",
              "10000000000000-count-zero": "00 ترليون",
              "10000000000000-count-one": "00 ترليون",
              "10000000000000-count-two": "00 ترليون",
              "10000000000000-count-few": "00 ترليون",
              "10000000000000-count-many": "00 ترليون",
              "10000000000000-count-other": "00 ترليون",
              "100000000000000-count-zero": "000 ترليون",
              "100000000000000-count-one": "000 ترليون",
              "100000000000000-count-two": "000 ترليون",
              "100000000000000-count-few": "000 ترليون",
              "100000000000000-count-many": "000 ترليون",
              "100000000000000-count-other": "000 ترليون"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-zero": "0 ألف",
              "1000-count-one": "0 ألف",
              "1000-count-two": "0 ألف",
              "1000-count-few": "0 آلاف",
              "1000-count-many": "0 ألف",
              "1000-count-other": "0 ألف",
              "10000-count-zero": "00 ألف",
              "10000-count-one": "00 ألف",
              "10000-count-two": "00 ألف",
              "10000-count-few": "00 ألف",
              "10000-count-many": "00 ألف",
              "10000-count-other": "00 ألف",
              "100000-count-zero": "000 ألف",
              "100000-count-one": "000 ألف",
              "100000-count-two": "000 ألف",
              "100000-count-few": "000 ألف",
              "100000-count-many": "000 ألف",
              "100000-count-other": "000 ألف",
              "1000000-count-zero": "0 مليون",
              "1000000-count-one": "0 مليون",
              "1000000-count-two": "0 مليون",
              "1000000-count-few": "0 مليون",
              "1000000-count-many": "0 مليون",
              "1000000-count-other": "0 مليون",
              "10000000-count-zero": "00 مليون",
              "10000000-count-one": "00 مليون",
              "10000000-count-two": "00 مليون",
              "10000000-count-few": "00 مليون",
              "10000000-count-many": "00 مليون",
              "10000000-count-other": "00 مليون",
              "100000000-count-zero": "000 مليون",
              "100000000-count-one": "000 مليون",
              "100000000-count-two": "000 مليون",
              "100000000-count-few": "000 مليون",
              "100000000-count-many": "000 مليون",
              "100000000-count-other": "000 مليون",
              "1000000000-count-zero": "0 مليار",
              "1000000000-count-one": "0 مليار",
              "1000000000-count-two": "0 مليار",
              "1000000000-count-few": "0 مليار",
              "1000000000-count-many": "0 مليار",
              "1000000000-count-other": "0 مليار",
              "10000000000-count-zero": "00 مليار",
              "10000000000-count-one": "00 مليار",
              "10000000000-count-two": "00 مليار",
              "10000000000-count-few": "00 مليار",
              "10000000000-count-many": "00 مليار",
              "10000000000-count-other": "00 مليار",
              "100000000000-count-zero": "000 مليار",
              "100000000000-count-one": "000 مليار",
              "100000000000-count-two": "000 مليار",
              "100000000000-count-few": "000 مليار",
              "100000000000-count-many": "000 مليار",
              "100000000000-count-other": "000 مليار",
              "1000000000000-count-zero": "0 ترليون",
              "1000000000000-count-one": "0 ترليون",
              "1000000000000-count-two": "0 ترليون",
              "1000000000000-count-few": "0 ترليون",
              "1000000000000-count-many": "0 ترليون",
              "1000000000000-count-other": "0 ترليون",
              "10000000000000-count-zero": "00 ترليون",
              "10000000000000-count-one": "00 ترليون",
              "10000000000000-count-two": "00 ترليون",
              "10000000000000-count-few": "00 ترليون",
              "10000000000000-count-many": "00 ترليون",
              "10000000000000-count-other": "00 ترليون",
              "100000000000000-count-zero": "000 ترليون",
              "100000000000000-count-one": "000 ترليون",
              "100000000000000-count-two": "000 ترليون",
              "100000000000000-count-few": "000 ترليون",
              "100000000000000-count-many": "000 ترليون",
              "100000000000000-count-other": "000 ترليون"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-zero": "0 ألف",
              "1000-count-one": "0 ألف",
              "1000-count-two": "0 ألف",
              "1000-count-few": "0 آلاف",
              "1000-count-many": "0 ألف",
              "1000-count-other": "0 ألف",
              "10000-count-zero": "00 ألف",
              "10000-count-one": "00 ألف",
              "10000-count-two": "00 ألف",
              "10000-count-few": "00 ألف",
              "10000-count-many": "00 ألف",
              "10000-count-other": "00 ألف",
              "100000-count-zero": "000 ألف",
              "100000-count-one": "000 ألف",
              "100000-count-two": "000 ألف",
              "100000-count-few": "000 ألف",
              "100000-count-many": "000 ألف",
              "100000-count-other": "000 ألف",
              "1000000-count-zero": "0 مليون",
              "1000000-count-one": "0 مليون",
              "1000000-count-two": "0 مليون",
              "1000000-count-few": "0 ملايين",
              "1000000-count-many": "0 مليون",
              "1000000-count-other": "0 مليون",
              "10000000-count-zero": "00 مليون",
              "10000000-count-one": "00 مليون",
              "10000000-count-two": "00 مليون",
              "10000000-count-few": "00 ملايين",
              "10000000-count-many": "00 مليون",
              "10000000-count-other": "00 مليون",
              "100000000-count-zero": "000 مليون",
              "100000000-count-one": "000 مليون",
              "100000000-count-two": "000 مليون",
              "100000000-count-few": "000 مليون",
              "100000000-count-many": "000 مليون",
              "100000000-count-other": "000 مليون",
              "1000000000-count-zero": "0 مليار",
              "1000000000-count-one": "0 مليار",
              "1000000000-count-two": "0 مليار",
              "1000000000-count-few": "0 مليار",
              "1000000000-count-many": "0 مليار",
              "1000000000-count-other": "0 مليار",
              "10000000000-count-zero": "00 مليار",
              "10000000000-count-one": "00 مليار",
              "10000000000-count-two": "00 مليار",
              "10000000000-count-few": "00 مليار",
              "10000000000-count-many": "00 مليار",
              "10000000000-count-other": "00 مليار",
              "100000000000-count-zero": "000 مليار",
              "100000000000-count-one": "000 مليار",
              "100000000000-count-two": "000 مليار",
              "100000000000-count-few": "000 مليار",
              "100000000000-count-many": "000 مليار",
              "100000000000-count-other": "000 مليار",
              "1000000000000-count-zero": "0 ترليون",
              "1000000000000-count-one": "0 ترليون",
              "1000000000000-count-two": "0 ترليون",
              "1000000000000-count-few": "0 ترليون",
              "1000000000000-count-many": "0 ترليون",
              "1000000000000-count-other": "0 ترليون",
              "10000000000000-count-zero": "00 ترليون",
              "10000000000000-count-one": "00 ترليون",
              "10000000000000-count-two": "00 ترليون",
              "10000000000000-count-few": "00 ترليون",
              "10000000000000-count-many": "00 ترليون",
              "10000000000000-count-other": "00 ترليون",
              "100000000000000-count-zero": "000 ترليون",
              "100000000000000-count-one": "000 ترليون",
              "100000000000000-count-two": "000 ترليون",
              "100000000000000-count-few": "000 ترليون",
              "100000000000000-count-many": "000 ترليون",
              "100000000000000-count-other": "000 ترليون"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-zero": "0 ألف",
              "1000-count-one": "0 ألف",
              "1000-count-two": "0 ألف",
              "1000-count-few": "0 آلاف",
              "1000-count-many": "0 ألف",
              "1000-count-other": "0 ألف",
              "10000-count-zero": "00 ألف",
              "10000-count-one": "00 ألف",
              "10000-count-two": "00 ألف",
              "10000-count-few": "00 ألف",
              "10000-count-many": "00 ألف",
              "10000-count-other": "00 ألف",
              "100000-count-zero": "000 ألف",
              "100000-count-one": "000 ألف",
              "100000-count-two": "000 ألف",
              "100000-count-few": "000 ألف",
              "100000-count-many": "000 ألف",
              "100000-count-other": "000 ألف",
              "1000000-count-zero": "0 مليون",
              "1000000-count-one": "0 مليون",
              "1000000-count-two": "0 مليون",
              "1000000-count-few": "0 مليون",
              "1000000-count-many": "0 مليون",
              "1000000-count-other": "0 مليون",
              "10000000-count-zero": "00 مليون",
              "10000000-count-one": "00 مليون",
              "10000000-count-two": "00 مليون",
              "10000000-count-few": "00 مليون",
              "10000000-count-many": "00 مليون",
              "10000000-count-other": "00 مليون",
              "100000000-count-zero": "000 مليون",
              "100000000-count-one": "000 مليون",
              "100000000-count-two": "000 مليون",
              "100000000-count-few": "000 مليون",
              "100000000-count-many": "000 مليون",
              "100000000-count-other": "000 مليون",
              "1000000000-count-zero": "0 مليار",
              "1000000000-count-one": "0 مليار",
              "1000000000-count-two": "0 مليار",
              "1000000000-count-few": "0 مليار",
              "1000000000-count-many": "0 مليار",
              "1000000000-count-other": "0 مليار",
              "10000000000-count-zero": "00 مليار",
              "10000000000-count-one": "00 مليار",
              "10000000000-count-two": "00 مليار",
              "10000000000-count-few": "00 مليار",
              "10000000000-count-many": "00 مليار",
              "10000000000-count-other": "00 مليار",
              "100000000000-count-zero": "000 مليار",
              "100000000000-count-one": "000 مليار",
              "100000000000-count-two": "000 مليار",
              "100000000000-count-few": "000 مليار",
              "100000000000-count-many": "000 مليار",
              "100000000000-count-other": "000 مليار",
              "1000000000000-count-zero": "0 ترليون",
              "1000000000000-count-one": "0 ترليون",
              "1000000000000-count-two": "0 ترليون",
              "1000000000000-count-few": "0 ترليون",
              "1000000000000-count-many": "0 ترليون",
              "1000000000000-count-other": "0 ترليون",
              "10000000000000-count-zero": "00 ترليون",
              "10000000000000-count-one": "00 ترليون",
              "10000000000000-count-two": "00 ترليون",
              "10000000000000-count-few": "00 ترليون",
              "10000000000000-count-many": "00 ترليون",
              "10000000000000-count-other": "00 ترليون",
              "100000000000000-count-zero": "000 ترليون",
              "100000000000000-count-one": "000 ترليون",
              "100000000000000-count-two": "000 ترليون",
              "100000000000000-count-few": "000 ترليون",
              "100000000000000-count-many": "000 ترليون",
              "100000000000000-count-other": "000 ترليون"
            }
          }
        },
        "scientificFormats-numberSystem-arab": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-arab": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-arab": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "unitPattern-count-zero": "{0} {1}",
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-two": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤ #,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-zero": "¤ 0 ألف",
              "1000-count-one": "¤ 0 ألف",
              "1000-count-two": "¤ 0 ألف",
              "1000-count-few": "¤ 0 ألف",
              "1000-count-many": "¤ 0 ألف",
              "1000-count-other": "¤ 0 ألف",
              "10000-count-zero": "¤ 00 ألف",
              "10000-count-one": "¤ 00 ألف",
              "10000-count-two": "¤ 00 ألف",
              "10000-count-few": "¤ 00 ألف",
              "10000-count-many": "¤ 00 ألف",
              "10000-count-other": "¤ 00 ألف",
              "100000-count-zero": "¤ 000 ألف",
              "100000-count-one": "¤ 000 ألف",
              "100000-count-two": "¤ 000 ألف",
              "100000-count-few": "¤ 000 ألف",
              "100000-count-many": "¤ 000 ألف",
              "100000-count-other": "¤ 000 ألف",
              "1000000-count-zero": "¤ 0 مليون",
              "1000000-count-one": "¤ 0 مليون",
              "1000000-count-two": "¤ 0 مليون",
              "1000000-count-few": "¤ 0 مليون",
              "1000000-count-many": "¤ 0 مليون",
              "1000000-count-other": "¤ 0 مليون",
              "10000000-count-zero": "¤ 00 مليون",
              "10000000-count-one": "¤ 00 مليون",
              "10000000-count-two": "¤ 00 مليون",
              "10000000-count-few": "¤ 00 مليون",
              "10000000-count-many": "¤ 00 مليون",
              "10000000-count-other": "¤ 00 مليون",
              "100000000-count-zero": "¤ 000 مليون",
              "100000000-count-one": "¤ 000 مليون",
              "100000000-count-two": "¤ 000 مليون",
              "100000000-count-few": "¤ 000 مليون",
              "100000000-count-many": "¤ 000 مليون",
              "100000000-count-other": "¤ 000 مليون",
              "1000000000-count-zero": "¤ 0 مليار",
              "1000000000-count-one": "¤ 0 مليار",
              "1000000000-count-two": "¤ 0 مليار",
              "1000000000-count-few": "¤ 0 مليار",
              "1000000000-count-many": "¤ 0 مليار",
              "1000000000-count-other": "¤ 0 مليار",
              "10000000000-count-zero": "¤ 00 مليار",
              "10000000000-count-one": "¤ 00 مليار",
              "10000000000-count-two": "¤ 00 مليار",
              "10000000000-count-few": "¤ 00 مليار",
              "10000000000-count-many": "¤ 00 مليار",
              "10000000000-count-other": "¤ 00 مليار",
              "100000000000-count-zero": "¤ 000 مليار",
              "100000000000-count-one": "¤ 000 مليار",
              "100000000000-count-two": "¤ 000 مليار",
              "100000000000-count-few": "¤ 000 مليار",
              "100000000000-count-many": "¤ 000 مليار",
              "100000000000-count-other": "¤ 000 مليار",
              "1000000000000-count-zero": "¤ 0 ترليون",
              "1000000000000-count-one": "¤ 0 ترليون",
              "1000000000000-count-two": "¤ 0 ترليون",
              "1000000000000-count-few": "¤ 0 ترليون",
              "1000000000000-count-many": "¤ 0 ترليون",
              "1000000000000-count-other": "¤ 0 ترليون",
              "10000000000000-count-zero": "¤ 00 ترليون",
              "10000000000000-count-one": "¤ 00 ترليون",
              "10000000000000-count-two": "¤ 00 ترليون",
              "10000000000000-count-few": "¤ 00 ترليون",
              "10000000000000-count-many": "¤ 00 ترليون",
              "10000000000000-count-other": "¤ 00 ترليون",
              "100000000000000-count-zero": "¤ 000 ترليون",
              "100000000000000-count-one": "¤ 000 ترليون",
              "100000000000000-count-two": "¤ 000 ترليون",
              "100000000000000-count-few": "¤ 000 ترليون",
              "100000000000000-count-many": "¤ 000 ترليون",
              "100000000000000-count-other": "¤ 000 ترليون"
            }
          },
          "unitPattern-count-zero": "{0} {1}",
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-two": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-arab": {
          "approximately": "~{0}",
          "atLeast": "+{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "+{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-zero": "{0} كتاب",
          "pluralMinimalPairs-count-one": "ولد واحد حضر",
          "pluralMinimalPairs-count-two": "ولدان حضرا",
          "pluralMinimalPairs-count-few": "{0} أولاد حضروا",
          "pluralMinimalPairs-count-many": "{0} ولدًا حضروا",
          "pluralMinimalPairs-count-other": "{0} ولد حضروا",
          "other": "اتجه إلى المنعطف الـ {0} يمينًا."
        }
      }
    }
  }
}
