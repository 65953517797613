module.exports={
  "main": {
    "sw": {
      "identity": {
        "version": {
          "_number": "$Revision: 14817 $",
          "_cldrVersion": "35.1"
        },
        "language": "sw"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "elfu 0;elfu -0",
              "1000-count-other": "elfu 0;elfu -0",
              "10000-count-one": "elfu 00;elfu -00",
              "10000-count-other": "elfu 00;elfu -00",
              "100000-count-one": "elfu 000;elfu -000",
              "100000-count-other": "elfu 000;elfu -000",
              "1000000-count-one": "milioni 0;milioni -0",
              "1000000-count-other": "milioni 0;milioni -0",
              "10000000-count-one": "milioni 00;milioni -00",
              "10000000-count-other": "milioni 00;milioni -00",
              "100000000-count-one": "milioni 000;milioni -000",
              "100000000-count-other": "milioni 000;milioni -000",
              "1000000000-count-one": "bilioni 0;bilioni -0",
              "1000000000-count-other": "bilioni 0;bilioni -0",
              "10000000000-count-one": "bilioni 00;bilioni -00",
              "10000000000-count-other": "bilioni 00;bilioni -00",
              "100000000000-count-one": "bilioni 000;bilioni -000",
              "100000000000-count-other": "bilioni 000;bilioni -000",
              "1000000000000-count-one": "trilioni 0;trilioni -0",
              "1000000000000-count-other": "trilioni 0;trilioni -0",
              "10000000000000-count-one": "trilioni 00;trilioni -00",
              "10000000000000-count-other": "trilioni 00;trilioni -00",
              "100000000000000-count-one": "trilioni 000;trilioni -000",
              "100000000000000-count-other": "trilioni 000;trilioni -000"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "elfu 0;elfu -0",
              "1000-count-other": "elfu 0;elfu -0",
              "10000-count-one": "elfu 00;elfu -00",
              "10000-count-other": "elfu 00;elfu -00",
              "100000-count-one": "elfu 000;elfu -000",
              "100000-count-other": "elfu 000;elfu -000",
              "1000000-count-one": "0M;-0M",
              "1000000-count-other": "0M",
              "10000000-count-one": "00M;-00M",
              "10000000-count-other": "00M",
              "100000000-count-one": "000M;-000M",
              "100000000-count-other": "000M",
              "1000000000-count-one": "0B;-0B",
              "1000000000-count-other": "0B;-0B",
              "10000000000-count-one": "00B;-00B",
              "10000000000-count-other": "00B;-00B",
              "100000000000-count-one": "000B;-000B",
              "100000000000-count-other": "000B;-000B",
              "1000000000000-count-one": "0T;-0T",
              "1000000000000-count-other": "0T",
              "10000000000000-count-one": "00T;-00T",
              "10000000000000-count-other": "00T",
              "100000000000000-count-one": "000T;-000T",
              "100000000000000-count-other": "000T"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤ #,##0.00",
          "accounting": "¤ #,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "¤ elfu0",
              "1000-count-other": "¤ elfu0",
              "10000-count-one": "¤ elfu00;¤elfu -00",
              "10000-count-other": "¤ elfu00;¤elfu -00",
              "100000-count-one": "¤ laki000;¤laki -000",
              "100000-count-other": "¤ laki000;¤laki -000",
              "1000000-count-one": "¤ 0M;¤-0M",
              "1000000-count-other": "¤ 0M",
              "10000000-count-one": "¤ 00M;¤M-00M",
              "10000000-count-other": "¤ 00M;¤-00M",
              "100000000-count-one": "¤ 000M;¤Milioni-000",
              "100000000-count-other": "¤ 000M",
              "1000000000-count-one": "¤ 0B;¤-0B",
              "1000000000-count-other": "¤ 0B;¤-0B",
              "10000000000-count-one": "¤ 00B;¤-00B",
              "10000000000-count-other": "¤ 00B;¤-00B",
              "100000000000-count-one": "¤ 000B;¤-000B",
              "100000000000-count-other": "¤ 000B;¤-000B",
              "1000000000000-count-one": "¤ 0T;¤-0T",
              "1000000000000-count-other": "¤ 0T",
              "10000000000000-count-one": "¤ 00T;¤-00T",
              "10000000000000-count-other": "¤ 00T",
              "100000000000000-count-one": "¤ 000T;¤-000T",
              "100000000000000-count-other": "¤ 000T;¤-000T"
            }
          },
          "unitPattern-count-one": "{1} {0}",
          "unitPattern-count-other": "{1} {0}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "siku {0} iliyopita",
          "pluralMinimalPairs-count-other": "siku {0} zilizopita",
          "other": "Chukua mpinduko wa {0} kulia."
        }
      }
    }
  }
}
