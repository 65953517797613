module.exports={
  "main": {
    "ka": {
      "identity": {
        "version": {
          "_number": "$Revision: 14817 $",
          "_cldrVersion": "35.1"
        },
        "language": "ka"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn",
          "traditional": "geor"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "არ არის რიცხვი",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 ათასი",
              "1000-count-other": "0 ათასი",
              "10000-count-one": "00 ათასი",
              "10000-count-other": "00 ათასი",
              "100000-count-one": "000 ათასი",
              "100000-count-other": "000 ათასი",
              "1000000-count-one": "0 მილიონი",
              "1000000-count-other": "0 მილიონი",
              "10000000-count-one": "00 მილიონი",
              "10000000-count-other": "00 მილიონი",
              "100000000-count-one": "000 მილიონი",
              "100000000-count-other": "000 მილიონი",
              "1000000000-count-one": "0 მილიარდი",
              "1000000000-count-other": "0 მილიარდი",
              "10000000000-count-one": "00 მილიარდი",
              "10000000000-count-other": "00 მილიარდი",
              "100000000000-count-one": "000 მილიარდი",
              "100000000000-count-other": "000 მილიარდი",
              "1000000000000-count-one": "0 ტრილიონი",
              "1000000000000-count-other": "0 ტრილიონი",
              "10000000000000-count-one": "00 ტრილიონი",
              "10000000000000-count-other": "00 ტრილიონი",
              "100000000000000-count-one": "000 ტრილიონი",
              "100000000000000-count-other": "000 ტრილიონი"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 ათ'.'",
              "1000-count-other": "0 ათ'.'",
              "10000-count-one": "00 ათ'.'",
              "10000-count-other": "00 ათ'.'",
              "100000-count-one": "000 ათ'.'",
              "100000-count-other": "000 ათ'.'",
              "1000000-count-one": "0 მლნ'.'",
              "1000000-count-other": "0 მლნ'.'",
              "10000000-count-one": "00 მლნ'.'",
              "10000000-count-other": "00 მლნ'.'",
              "100000000-count-one": "000 მლნ'.'",
              "100000000-count-other": "000 მლნ'.'",
              "1000000000-count-one": "0 მლრდ'.'",
              "1000000000-count-other": "0 მლრდ'.'",
              "10000000000-count-one": "00 მლრდ'.'",
              "10000000000-count-other": "00 მლრდ'.'",
              "100000000000-count-one": "000 მლრ'.'",
              "100000000000-count-other": "000 მლრ'.'",
              "1000000000000-count-one": "0 ტრლ'.'",
              "1000000000000-count-other": "0 ტრლ'.'",
              "10000000000000-count-one": "00 ტრლ'.'",
              "10000000000000-count-other": "00 ტრლ'.'",
              "100000000000000-count-one": "000 ტრლ'.'",
              "100000000000000-count-other": "000 ტრლ'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 ათ'.' ¤",
              "1000-count-other": "0 ათ'.' ¤",
              "10000-count-one": "00 ათ'.' ¤",
              "10000-count-other": "00 ათ'.' ¤",
              "100000-count-one": "000 ათ'.' ¤",
              "100000-count-other": "000 ათ'.' ¤",
              "1000000-count-one": "0 მლნ'.' ¤",
              "1000000-count-other": "0 მლნ'.' ¤",
              "10000000-count-one": "00 მლნ'.' ¤",
              "10000000-count-other": "00 მლნ'.' ¤",
              "100000000-count-one": "000 მლნ'.' ¤",
              "100000000-count-other": "000 მლნ'.' ¤",
              "1000000000-count-one": "0 მლრდ'.' ¤",
              "1000000000-count-other": "0 მლრდ'.' ¤",
              "10000000000-count-one": "00 მლრდ'.' ¤",
              "10000000000-count-other": "00 მლრდ'.' ¤",
              "100000000000-count-one": "000 მლრ'.' ¤",
              "100000000000-count-other": "000 მლრ'.' ¤",
              "1000000000000-count-one": "0 ტრლ'.' ¤",
              "1000000000000-count-other": "0 ტრლ'.' ¤",
              "10000000000000-count-one": "00 ტრლ'.' ¤",
              "10000000000000-count-other": "00 ტრლ'.' ¤",
              "100000000000000-count-one": "000 ტრლ'.' ¤",
              "100000000000000-count-other": "000 ტრლ'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "≈{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "კალათში {0} X-ია. შეიძენთ მას?",
          "pluralMinimalPairs-count-other": "კალათში {0} X-ია. შეიძენთ მათ?",
          "many": "მე-{0}",
          "one": "{0}-ლი",
          "other": "{0}-ე"
        }
      }
    }
  }
}
