import { Localizations } from "./constants";

export const MaterialsList = [
	{name: "Alumina", machinabilityIndex: "0.66", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Aluminum (2024)", machinabilityIndex: "2.50", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Aluminum (3003)", machinabilityIndex: "2.54", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Aluminum (5052)", machinabilityIndex: "2.43", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Aluminum (6061)", machinabilityIndex: "2.90", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Aluminum (7075)", machinabilityIndex: "2.47", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Brass (Annealed)", machinabilityIndex: "2.31", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Brass (Full Hard)", machinabilityIndex: "1.28", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Brass (Half Hard)", machinabilityIndex: "1.40", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Caesarstone (Green)", machinabilityIndex: "4.01", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Caesarstone (White)", machinabilityIndex: "5.00", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Carbon / Graphite", machinabilityIndex: "9.06", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Carbon Fiber", machinabilityIndex: "4.39", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Ceramic Tile (Generic)", machinabilityIndex: "9.94", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Chromoly Steel", machinabilityIndex: "0.95", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Concrete", machinabilityIndex: "2.78", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Copper", machinabilityIndex: "1.40", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Copper-Nickel (7030)", machinabilityIndex: "1.23", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Corian", machinabilityIndex: "5.23", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Delrin", machinabilityIndex: "8.89", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Fiberglass (Generic)", machinabilityIndex: "7.31", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Glass", machinabilityIndex: "5.00", defaultPiercePressurePSI: "10000", defaultPiercePressureBar: "689", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4146" },
	{name: "Granite (Absolute Black)", machinabilityIndex: "1.75", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Granite (Baltic Brown)", machinabilityIndex: "3.51", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Granite (Black Galaxy)", machinabilityIndex: "1.96", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Granite (Blue Pearl)", machinabilityIndex: "1.41", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136" },
	{name: "Granite (Dakota Mahogany)", machinabilityIndex: "3.46", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Emerald Pearl)", machinabilityIndex: "3.32", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (English Brown)", machinabilityIndex: "3.28", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Giallo Veneziano)", machinabilityIndex: "3.42", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Imperial Brown)", machinabilityIndex: "4.46", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Labrador Gold)", machinabilityIndex: "3.92", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Peacock Green)", machinabilityIndex: "2.75", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Sapphire Blue)", machinabilityIndex: "3.36", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (St. Cecilia)", machinabilityIndex: "1.55", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Tropic Brown)", machinabilityIndex: "2.51", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Verde Butterfly)", machinabilityIndex: "2.07", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Verde Fountain)", machinabilityIndex: "2.22", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Verde Marinace)", machinabilityIndex: "2.82", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Violetta)", machinabilityIndex: "2.28", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Granite (Volga Blue)", machinabilityIndex: "3.12", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Hastelloy", machinabilityIndex: "0.84", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Haynes", machinabilityIndex: "0.82", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Inconel", machinabilityIndex: "0.80", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Kevlar", machinabilityIndex: "5.00", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Lead", machinabilityIndex: "4.80", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Limestone", machinabilityIndex: "4.89", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Magnesium", machinabilityIndex: "2.91", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Marble (Generic)", machinabilityIndex: "6.40", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Marble (Green)", machinabilityIndex: "4.28", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Micarta", machinabilityIndex: "5.58", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Mild Steel (A36)", machinabilityIndex: "1.00", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Molybdenum", machinabilityIndex: "0.68", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Nylon", machinabilityIndex: "5.24", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Onyx", machinabilityIndex: "6.25", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Phenolic", machinabilityIndex: "5.26", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Plexiglass (Lexan or Acrylic)", machinabilityIndex: "10.00", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Polyethylene (High Density)", machinabilityIndex: "8.30", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Polyethylene (Low Density)", machinabilityIndex: "12.87", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Polypropylene", machinabilityIndex: "7.87", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Polyurethante Plastic", machinabilityIndex: "23.68", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Porcelain (Travertino)", machinabilityIndex: "5.56", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "PVC", machinabilityIndex: "10.58", defaultPiercePressurePSI: "15000", defaultPiercePressureBar: "1034", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Quartz (Generic)", machinabilityIndex: "5.11", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Rubber (25-30 Durometer)", machinabilityIndex: "84.80", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Rubber (50-60 Durometer)", machinabilityIndex: "50.88", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Rubber (80 Durometer)", machinabilityIndex: "21.35", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Slate", machinabilityIndex: "4.89", defaultPiercePressurePSI: "20000", defaultPiercePressureBar: "1378", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (13-8)", machinabilityIndex: "0.96", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (15-5)", machinabilityIndex: "0.98", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (17-4)", machinabilityIndex: "0.98", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (17-7)", machinabilityIndex: "0.94", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (304)", machinabilityIndex: "0.90", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Stainless Steel (316L)", machinabilityIndex: "0.95", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Steel (AR 400)", machinabilityIndex: "0.86", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Teflon", machinabilityIndex: "15.50", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Titanium", machinabilityIndex: "1.40", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Tool Steel (A2)", machinabilityIndex: "0.86", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Tool Steel (D2)", machinabilityIndex: "0.87", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Tungsten", machinabilityIndex: "0.26", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Tungsten Carbide", machinabilityIndex: "0.76", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "UHMW", machinabilityIndex: "6.54", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Wood (Birch)", machinabilityIndex: "18.13", defaultPiercePressurePSI: "30000", defaultPiercePressureBar: "2068", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Wood (Pine)", machinabilityIndex: "21.05", defaultPiercePressurePSI: "30000", defaultPiercePressureBar: "2068", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Zinc Alloy", machinabilityIndex: "1.55", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
	{name: "Zirconium", machinabilityIndex: "1.24", defaultPiercePressurePSI: "60000", defaultPiercePressureBar: "4136", defaultCutPressurePSI: "60000", defaultCutPressureBar: "4136"},
];

export const PumpList = [
	{name: "DynaMAX 315- 15hp (Previously Echion 15)", horsePower: 15, hertz: "50/60", kiloWatt: "11", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "0.3", maxPumpLPM: "1.14", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 330- 30hp (Previously Echion 30)", horsePower: 30, hertz: "50/60", kiloWatt: "22", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "0.6", maxPumpLPM: "2.27", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 350- 50hp (Previously Echion 50)", horsePower: 50, hertz: "50/60", kiloWatt: "37", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "1", maxPumpLPM: "3.76", pumpCoolingGPM: "1.75", pumpCoolingLPM: "6.62", pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 550P- 50hp (Previously HyPrecision P-50/P-50S)", horsePower: 50, hertz: "50/60", kiloWatt: "37", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "1", maxPumpLPM: "3.76", pumpCoolingGPM: "1.75", pumpCoolingLPM: "6.62", pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 560P- 60 hp (Previously HyPrecision P-60S)", horsePower: 60, hertz: "50/60", kiloWatt: "45", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "1.2", maxPumpLPM: "4.54", pumpCoolingGPM: "2.5", pumpCoolingLPM: "9.46",  pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 575P- 75 hp (Previously HyPrecision P-75S)", horsePower: 75, hertz: "50/60", kiloWatt: "56", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "1.5", maxPumpLPM: "5.68", pumpCoolingGPM: "2.75", pumpCoolingLPM: "10.41", pumpReplacementPartsPerHour: "1.88"},
	{name: "DynaMAX 5100- 100hp/50Hz (Previously HyPrecision 100D- 100hp/50Hz)", horsePower: 100, hertz: "50", kiloWatt: "75", minPressure: 10000, maxPressure: 60000, minPressureBar:689, maxPressureBar:4136, maxPumpGPM: "2.1", maxPumpLPM: "7.95", pumpCoolingGPM: "3.5", pumpCoolingLPM: "13.25", pumpReplacementPartsPerHour: "3.76"},
	{name: "DynaMAX 5100- 100hp/60Hz (Previously HyPrecision 100D- 100hp/60Hz)", horsePower: 100, hertz: "60", kiloWatt: "75", minPressure: 10000, maxPressure: 60000, minPressureBar:689, maxPressureBar:4136, maxPumpGPM: "2.2", maxPumpLPM: "8.33", pumpCoolingGPM: "3.5", pumpCoolingLPM: "13.25", pumpReplacementPartsPerHour: "3.76"},
	{name: "DynaMAX 5150- 150hp/50Hz (Previously HyPrecision 150D- 150hp/50Hz)", horsePower: 150, hertz: "50", kiloWatt: "112", minPressure: 10000, maxPressure: 60000, minPressureBar:689, maxPressureBar:4136, maxPumpGPM: "3", maxPumpLPM: "11.36", pumpCoolingGPM: "5.5", pumpCoolingLPM: "20.82", pumpReplacementPartsPerHour: "3.76"},
	{name: "DynaMAX 5150- 150hp/60Hz (Previously HyPrecision 150D- 150hp/60Hz)", horsePower: 150, hertz: "60", kiloWatt: "112", minPressure: 10000, maxPressure: 60000, minPressureBar:689, maxPressureBar:4136, maxPumpGPM: "3.2", maxPumpLPM: "12.11", pumpCoolingGPM: "5.5", pumpCoolingLPM: "20.82", pumpReplacementPartsPerHour: "3.76"},
	{name: "HyPrecision 15- 15hp", horsePower: 15, hertz: "50/60", kiloWatt: "11", minPressure: 6000, maxPressure: 60000, minPressureBar:414, maxPressureBar:4136, maxPumpGPM: "0.3", maxPumpLPM: "1.14", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision 30- 30hp", horsePower: 30, hertz: "50/60", kiloWatt: "22", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "0.6", maxPumpLPM: "2.27", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision 50/50S- 50hp", horsePower: 50, hertz: "50/60", kiloWatt: "37", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1", maxPumpLPM: "3.76", pumpCoolingGPM: "1.75", pumpCoolingLPM: "6.62", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision 60S- 60hp", horsePower: 60, hertz: "50/60", kiloWatt: "45", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1.2", maxPumpLPM: "4.54", pumpCoolingGPM: "2.5", pumpCoolingLPM: "9.46", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision 75S- 75hp", horsePower: 75, hertz: "50/60", kiloWatt: "56", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1.5", maxPumpLPM: "5.68", pumpCoolingGPM: "2.75", pumpCoolingLPM: "10.41", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision P-15- 15hp", horsePower: 15, hertz: "50/60", kiloWatt: "11", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "0.3", maxPumpLPM: "1.14", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "HyPrecision P-30- 30hp", horsePower: 30, hertz: "50/60", kiloWatt: "22", minPressure: 5000, maxPressure: 60000, minPressureBar:344, maxPressureBar:4136, maxPumpGPM: "0.6", maxPumpLPM: "2.27", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},	{name: "AccuStream A-6015- 15hp", horsePower: 15, hertz: "50/60", kiloWatt: "11", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "0.3", maxPumpLPM: "1.14", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "AccuStream A-6030- 30hp", horsePower: 30, hertz: "50/60", kiloWatt: "22", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "0.6", maxPumpLPM: "2.27", pumpCoolingGPM: "1.5", pumpCoolingLPM: "5.67", pumpReplacementPartsPerHour: "1.88"},
	{name: "AccuStream Matrix 50- 50hp", horsePower: 50, hertz: "50/60", kiloWatt: "37", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1", maxPumpLPM: "3.76", pumpCoolingGPM: "1.75", pumpCoolingLPM: "6.62", pumpReplacementPartsPerHour: "1.88"},
	{name: "AccuStream AS-6050- 50hp", horsePower: 50, hertz: "50/60", kiloWatt: "37", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1.1", maxPumpLPM: "4.16", pumpCoolingGPM: "1.75", pumpCoolingLPM: "6.62", pumpReplacementPartsPerHour: "1.88"},
	{name: "AccuStream AS-6060- 60hp", horsePower: 60, hertz: "50/60", kiloWatt: "45", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1.3", maxPumpLPM: "4.92", pumpCoolingGPM: "2.5", pumpCoolingLPM: "9.46", pumpReplacementPartsPerHour: "1.88"},
	{name: "AccuStream AS-6075- 75hp", horsePower: 75, hertz: "50/60", kiloWatt: "56", minPressure: 6000, maxPressure: 60000, minPressureBar: 414, maxPressureBar:4136, maxPumpGPM: "1.6", maxPumpLPM: "6.06", pumpCoolingGPM: "2.75", pumpCoolingLPM: "10.41", pumpReplacementPartsPerHour: "1.88"},

];

export const OrificeNozzleList = [
	{
		usCustomary: {
			orificeDiameter: "0.007",
			nozzleDiameter: "0.020",
			abrasiveFlow: "0.33"
		},
		metric: {
			orificeDiameter: "0.18",
			nozzleDiameter: "0.50",
			abrasiveFlow: "0.15",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.008",
			nozzleDiameter: "0.020",
			abrasiveFlow: "0.37"
		},
		metric: {
			orificeDiameter: "0.20",
			nozzleDiameter: "0.50",
			abrasiveFlow: "0.17",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.009",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.62"
		},
		metric: {
			orificeDiameter: "0.23",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.28",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.010",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.67"
		},
		metric: {
			orificeDiameter: "0.25",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.30",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.011",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.73"
		},
		metric: {
			orificeDiameter: "0.28",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.33",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.012",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.79"
		},
		metric: {
			orificeDiameter: "0.30",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.36",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.012",
			nozzleDiameter: "0.035",
			abrasiveFlow: "0.92"
		},
		metric: {
			orificeDiameter: "0.30",
			nozzleDiameter: "0.89",
			abrasiveFlow: "0.42",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.013",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.85"
		},
		metric: {
			orificeDiameter: "0.33",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.39",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.013",
			nozzleDiameter: "0.035",
			abrasiveFlow: "0.99"
		},
		metric: {
			orificeDiameter: "0.33",
			nozzleDiameter: "0.89",
			abrasiveFlow: "0.45",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.014",
			nozzleDiameter: "0.030",
			abrasiveFlow: "0.90"
		},
		metric: {
			orificeDiameter: "0.35",
			nozzleDiameter: "0.76",
			abrasiveFlow: "0.41",
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.014",
			nozzleDiameter: "0.035",
			abrasiveFlow: "1.10"
		},
		metric: {
			orificeDiameter: "0.35",
			nozzleDiameter: "0.89",
			abrasiveFlow: "0.50"
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.014",
			nozzleDiameter: "0.040",
			abrasiveFlow: "1.20"
		},
		metric: {
			orificeDiameter: "0.35",
			nozzleDiameter: "1.02",
			abrasiveFlow: "0.55"
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.015",
			nozzleDiameter: "0.040",
			abrasiveFlow: "1.30"
		},
		metric: {
			orificeDiameter: "0.38",
			nozzleDiameter: "1.02",
			abrasiveFlow: "0.59"
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.016",
			nozzleDiameter: "0.040",
			abrasiveFlow: "1.30"
		},
		metric: {
			orificeDiameter: "0.40",
			nozzleDiameter: "1.02",
			abrasiveFlow: "0.59"
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.016",
			nozzleDiameter: "0.045",
			abrasiveFlow: "1.50"
		},
		metric: {
			orificeDiameter: "0.40",
			nozzleDiameter: "1.14",
			abrasiveFlow: "0.68"
		}
	},
	{
		usCustomary: {
			orificeDiameter: "0.018",
			nozzleDiameter: "0.045",
			abrasiveFlow: "1.70"
		},
		metric: {
			orificeDiameter: "0.45",
			nozzleDiameter: "1.14",
			abrasiveFlow: "0.77"
		}
	}
];

export const DefaultCosts = {
	laborAndOverhead: "0.00", // $ per hour
	energy:  "0.12", // $ per kwh
	waterCost: "11.00", // $ per 1000 gallons of water (sum of water + sewer costs)
	abrasive: "0.25", // $ per pound
	headReplacementPartsCost: "2.89" // dollars per hour
};

export const QualityList = [
	{value: "1", description: "Separation Cut", image: "assets/images/design/cut-quality/Q1.jpg"},
	{value: "2", description: "Fair", image: "assets/images/design/cut-quality/Q2.jpg"},
	{value: "3", description: "Average", image: "assets/images/design/cut-quality/Q3.jpg"},
	{value: "4", description: "Good", image: "assets/images/design/cut-quality/Q4.jpg"},
	{value: "5", description: "Excellent", image: "assets/images/design/cut-quality/Q5.jpg"}
];

export const DefaultValues = {
	calculation: {
		// Cost
		laborAndOverHeadCost: DefaultCosts.laborAndOverhead,
		energyCost: DefaultCosts.energy,
		abrasiveCost: DefaultCosts.abrasive,
		waterCost: DefaultCosts.waterCost,
		headReplacementPartsCost: DefaultCosts.headReplacementPartsCost,

		// Pump
		pumpName: PumpList[0].name,
		pumpCoolingGPM: PumpList[0].pumpCoolingGPM,
		maxPumpGPM: PumpList[0].maxPumpGPM,
		pumpReplacementParts: PumpList[0].pumpReplacementPartsPerHour,
		kiloWatt: PumpList[0].kiloWatt,

		// Material
		machinabilityIndex: MaterialsList[0].machinabilityIndex,
		thickness: "1.2",

		// Cut
		orificeNumber: "1",
		cutQuality: QualityList[0].value,
		abrasiveFlow: OrificeNozzleList[0].usCustomary.abrasiveFlow,
		orificeDiameter: OrificeNozzleList[0].usCustomary.orificeDiameter,
		nozzleDiameter: OrificeNozzleList[0].usCustomary.nozzleDiameter,
		cuttingPressure: "60000",
		piercingPressure: "20000"
	},
	selectedPump: PumpList[0],
	selectedMaterial: MaterialsList[0],
	selectedOrificeNozzle: OrificeNozzleList[0],
	selectedQuality: QualityList[0],
	pumpList: PumpList,
	materialList: MaterialsList,
	qualityList: QualityList,
	orificeNozzleList: OrificeNozzleList,
	numberOfOrificeList: ["1", "2", "3", "4", "5", "6"],
	customOrificeNozzleList: new Array<any>(),
	customMaterialList: new Array<any>(),
	localizations: {
		units: Localizations.units[0],
		currency: Localizations.currency[0],
		numberFormatting: Localizations.numberFormatting[0],
		conversion: "1.00"
	}
};
