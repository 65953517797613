module.exports={
  "main": {
    "vi": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "vi"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0 nghìn",
              "10000-count-other": "00 nghìn",
              "100000-count-other": "000 nghìn",
              "1000000-count-other": "0 triệu",
              "10000000-count-other": "00 triệu",
              "100000000-count-other": "000 triệu",
              "1000000000-count-other": "0 tỷ",
              "10000000000-count-other": "00 tỷ",
              "100000000000-count-other": "000 tỷ",
              "1000000000000-count-other": "0 nghìn tỷ",
              "10000000000000-count-other": "00 nghìn tỷ",
              "100000000000000-count-other": "000 nghìn tỷ"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0 N",
              "10000-count-other": "00 N",
              "100000-count-other": "000 N",
              "1000000-count-other": "0 Tr",
              "10000000-count-other": "00 Tr",
              "100000000-count-other": "000 Tr",
              "1000000000-count-other": "0 T",
              "10000000000-count-other": "00 T",
              "100000000000-count-other": "000 T",
              "1000000000000-count-other": "0 NT",
              "10000000000000-count-other": "00 NT",
              "100000000000000-count-other": "000 NT"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-other": "0 N ¤",
              "10000-count-other": "00 N ¤",
              "100000-count-other": "000 N ¤",
              "1000000-count-other": "0 Tr ¤",
              "10000000-count-other": "00 Tr ¤",
              "100000000-count-other": "000 Tr ¤",
              "1000000000-count-other": "0 T ¤",
              "10000000000-count-other": "00 T ¤",
              "100000000000-count-other": "000 T ¤",
              "1000000000000-count-other": "0 NT ¤",
              "10000000000000-count-other": "00 NT ¤",
              "100000000000000-count-other": "000 NT ¤"
            }
          },
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "Rẽ vào lối rẽ thứ nhất bên phải.",
          "pluralMinimalPairs-count-other": "Rẽ vào lối rẽ thứ {0} bên phải.",
          "one": "Rẽ vào lối rẽ thứ nhất bên phải.",
          "other": "Rẽ vào lối rẽ thứ {0} bên phải."
        }
      }
    }
  }
}
