module.exports={
  "main": {
    "hr": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "hr"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tisuća",
              "1000-count-few": "0 tisuće",
              "1000-count-other": "0 tisuća",
              "10000-count-one": "00 tisuća",
              "10000-count-few": "00 tisuće",
              "10000-count-other": "00 tisuća",
              "100000-count-one": "000 tisuća",
              "100000-count-few": "000 tisuće",
              "100000-count-other": "000 tisuća",
              "1000000-count-one": "0 milijun",
              "1000000-count-few": "0 milijuna",
              "1000000-count-other": "0 milijuna",
              "10000000-count-one": "00 milijun",
              "10000000-count-few": "00 milijuna",
              "10000000-count-other": "00 milijuna",
              "100000000-count-one": "000 milijun",
              "100000000-count-few": "000 milijuna",
              "100000000-count-other": "000 milijuna",
              "1000000000-count-one": "0 milijarda",
              "1000000000-count-few": "0 milijarde",
              "1000000000-count-other": "0 milijardi",
              "10000000000-count-one": "00 milijarda",
              "10000000000-count-few": "00 milijarde",
              "10000000000-count-other": "00 milijardi",
              "100000000000-count-one": "000 milijarda",
              "100000000000-count-few": "000 milijarde",
              "100000000000-count-other": "000 milijardi",
              "1000000000000-count-one": "0 bilijun",
              "1000000000000-count-few": "0 bilijuna",
              "1000000000000-count-other": "0 bilijuna",
              "10000000000000-count-one": "00 bilijun",
              "10000000000000-count-few": "00 bilijuna",
              "10000000000000-count-other": "00 bilijuna",
              "100000000000000-count-one": "000 bilijun",
              "100000000000000-count-few": "000 bilijuna",
              "100000000000000-count-other": "000 bilijuna"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tis'.'",
              "1000-count-few": "0 tis'.'",
              "1000-count-other": "0 tis'.'",
              "10000-count-one": "00 tis'.'",
              "10000-count-few": "00 tis'.'",
              "10000-count-other": "00 tis'.'",
              "100000-count-one": "000 tis'.'",
              "100000-count-few": "000 tis'.'",
              "100000-count-other": "000 tis'.'",
              "1000000-count-one": "0 mil'.'",
              "1000000-count-few": "0 mil'.'",
              "1000000-count-other": "0 mil'.'",
              "10000000-count-one": "00 mil'.'",
              "10000000-count-few": "00 mil'.'",
              "10000000-count-other": "00 mil'.'",
              "100000000-count-one": "000 mil'.'",
              "100000000-count-few": "000 mil'.'",
              "100000000-count-other": "000 mil'.'",
              "1000000000-count-one": "0 mlr'.'",
              "1000000000-count-few": "0 mlr'.'",
              "1000000000-count-other": "0 mlr'.'",
              "10000000000-count-one": "00 mlr'.'",
              "10000000000-count-few": "00 mlr'.'",
              "10000000000-count-other": "00 mlr'.'",
              "100000000000-count-one": "000 mlr'.'",
              "100000000000-count-few": "000 mlr'.'",
              "100000000000-count-other": "000 mlr'.'",
              "1000000000000-count-one": "0 bil'.'",
              "1000000000000-count-few": "0 bil'.'",
              "1000000000000-count-other": "0 bil'.'",
              "10000000000000-count-one": "00 bil'.'",
              "10000000000000-count-few": "00 bil'.'",
              "10000000000000-count-other": "00 bil'.'",
              "100000000000000-count-one": "000 bil'.'",
              "100000000000000-count-few": "000 bil'.'",
              "100000000000000-count-other": "000 bil'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0000¤",
              "1000-count-few": "0000¤",
              "1000-count-other": "0000¤",
              "10000-count-one": "00 tis'.' ¤",
              "10000-count-few": "00 tis'.' ¤",
              "10000-count-other": "00 tis'.' ¤",
              "100000-count-one": "000 tis'.' ¤",
              "100000-count-few": "000 tis'.' ¤",
              "100000-count-other": "000 tis'.' ¤",
              "1000000-count-one": "0 mil'.' ¤",
              "1000000-count-few": "0 mil'.' ¤",
              "1000000-count-other": "0 mil'.' ¤",
              "10000000-count-one": "00 mil'.' ¤",
              "10000000-count-few": "00 mil'.' ¤",
              "10000000-count-other": "00 mil'.' ¤",
              "100000000-count-one": "000 mil'.' ¤",
              "100000000-count-few": "000 mil'.' ¤",
              "100000000-count-other": "000 mil'.' ¤",
              "1000000000-count-one": "0 mlr'.' ¤",
              "1000000000-count-few": "0 mlr'.' ¤",
              "1000000000-count-other": "0 mlr'.' ¤",
              "10000000000-count-one": "00 mlr'.' ¤",
              "10000000000-count-few": "00 mlr'.' ¤",
              "10000000000-count-other": "00 mlr'.' ¤",
              "100000000000-count-one": "000 mlr'.' ¤",
              "100000000000-count-few": "000 mlr'.' ¤",
              "100000000000-count-other": "000 mlr'.' ¤",
              "1000000000000-count-one": "0 bil'.' ¤",
              "1000000000000-count-few": "0 bil'.' ¤",
              "1000000000000-count-other": "0 bil'.' ¤",
              "10000000000000-count-one": "00 bil'.' ¤",
              "10000000000000-count-few": "00 bil'.' ¤",
              "10000000000000-count-other": "00 bil'.' ¤",
              "100000000000000-count-one": "000 bil'.' ¤",
              "100000000000000-count-few": "000 bil'.' ¤",
              "100000000000000-count-other": "000 bil'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0} – {1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} dan",
          "pluralMinimalPairs-count-few": "{0} dana",
          "pluralMinimalPairs-count-other": "{0} dan/a",
          "other": "Skrenite {0}. desno."
        }
      }
    }
  }
}
