module.exports={
  "main": {
    "sk": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "sk"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "e",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tisíc",
              "1000-count-few": "0 tisíce",
              "1000-count-many": "0 tisíca",
              "1000-count-other": "0 tisíc",
              "10000-count-one": "00 tisíc",
              "10000-count-few": "00 tisíc",
              "10000-count-many": "00 tisíca",
              "10000-count-other": "00 tisíc",
              "100000-count-one": "000 tisíc",
              "100000-count-few": "000 tisíc",
              "100000-count-many": "000 tisíca",
              "100000-count-other": "000 tisíc",
              "1000000-count-one": "0 milión",
              "1000000-count-few": "0 milióny",
              "1000000-count-many": "0 milióna",
              "1000000-count-other": "0 miliónov",
              "10000000-count-one": "00 miliónov",
              "10000000-count-few": "00 miliónov",
              "10000000-count-many": "00 milióna",
              "10000000-count-other": "00 miliónov",
              "100000000-count-one": "000 miliónov",
              "100000000-count-few": "000 miliónov",
              "100000000-count-many": "000 milióna",
              "100000000-count-other": "000 miliónov",
              "1000000000-count-one": "0 miliarda",
              "1000000000-count-few": "0 miliardy",
              "1000000000-count-many": "0 miliardy",
              "1000000000-count-other": "0 miliárd",
              "10000000000-count-one": "00 miliárd",
              "10000000000-count-few": "00 miliárd",
              "10000000000-count-many": "00 miliardy",
              "10000000000-count-other": "00 miliárd",
              "100000000000-count-one": "000 miliárd",
              "100000000000-count-few": "000 miliárd",
              "100000000000-count-many": "000 miliardy",
              "100000000000-count-other": "000 miliárd",
              "1000000000000-count-one": "0 bilión",
              "1000000000000-count-few": "0 bilióny",
              "1000000000000-count-many": "0 bilióna",
              "1000000000000-count-other": "0 biliónov",
              "10000000000000-count-one": "00 biliónov",
              "10000000000000-count-few": "00 biliónov",
              "10000000000000-count-many": "00 bilióna",
              "10000000000000-count-other": "00 biliónov",
              "100000000000000-count-one": "000 biliónov",
              "100000000000000-count-few": "000 biliónov",
              "100000000000000-count-many": "000 bilióna",
              "100000000000000-count-other": "000 biliónov"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tis'.'",
              "1000-count-few": "0 tis'.'",
              "1000-count-many": "0 tis'.'",
              "1000-count-other": "0 tis'.'",
              "10000-count-one": "00 tis'.'",
              "10000-count-few": "00 tis'.'",
              "10000-count-many": "00 tis'.'",
              "10000-count-other": "00 tis'.'",
              "100000-count-one": "000 tis'.'",
              "100000-count-few": "000 tis'.'",
              "100000-count-many": "000 tis'.'",
              "100000-count-other": "000 tis'.'",
              "1000000-count-one": "0 mil'.'",
              "1000000-count-few": "0 mil'.'",
              "1000000-count-many": "0 mil'.'",
              "1000000-count-other": "0 mil'.'",
              "10000000-count-one": "00 mil'.'",
              "10000000-count-few": "00 mil'.'",
              "10000000-count-many": "00 mil'.'",
              "10000000-count-other": "00 mil'.'",
              "100000000-count-one": "000 mil'.'",
              "100000000-count-few": "000 mil'.'",
              "100000000-count-many": "000 mil'.'",
              "100000000-count-other": "000 mil'.'",
              "1000000000-count-one": "0 mld'.'",
              "1000000000-count-few": "0 mld'.'",
              "1000000000-count-many": "0 mld'.'",
              "1000000000-count-other": "0 mld'.'",
              "10000000000-count-one": "00 mld'.'",
              "10000000000-count-few": "00 mld'.'",
              "10000000000-count-many": "00 mld'.'",
              "10000000000-count-other": "00 mld'.'",
              "100000000000-count-one": "000 mld'.'",
              "100000000000-count-few": "000 mld'.'",
              "100000000000-count-many": "000 mld'.'",
              "100000000000-count-other": "000 mld'.'",
              "1000000000000-count-one": "0 bil'.'",
              "1000000000000-count-few": "0 bil'.'",
              "1000000000000-count-many": "0 bil'.'",
              "1000000000000-count-other": "0 bil'.'",
              "10000000000000-count-one": "00 bil'.'",
              "10000000000000-count-few": "00 bil'.'",
              "10000000000000-count-many": "00 bil'.'",
              "10000000000000-count-other": "00 bil'.'",
              "100000000000000-count-one": "000 bil'.'",
              "100000000000000-count-few": "000 bil'.'",
              "100000000000000-count-many": "000 bil'.'",
              "100000000000000-count-other": "000 bil'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 tis'.' ¤",
              "1000-count-few": "0 tis'.' ¤",
              "1000-count-many": "0 tis'.' ¤",
              "1000-count-other": "0 tis'.' ¤",
              "10000-count-one": "00 tis'.' ¤",
              "10000-count-few": "00 tis'.' ¤",
              "10000-count-many": "00 tis'.' ¤",
              "10000-count-other": "00 tis'.' ¤",
              "100000-count-one": "000 tis'.' ¤",
              "100000-count-few": "000 tis'.' ¤",
              "100000-count-many": "000 tis'.' ¤",
              "100000-count-other": "000 tis'.' ¤",
              "1000000-count-one": "0 mil'.' ¤",
              "1000000-count-few": "0 mil'.' ¤",
              "1000000-count-many": "0 mil'.' ¤",
              "1000000-count-other": "0 mil'.' ¤",
              "10000000-count-one": "00 mil'.' ¤",
              "10000000-count-few": "00 mil'.' ¤",
              "10000000-count-many": "00 mil'.' ¤",
              "10000000-count-other": "00 mil'.' ¤",
              "100000000-count-one": "000 mil'.' ¤",
              "100000000-count-few": "000 mil'.' ¤",
              "100000000-count-many": "000 mil'.' ¤",
              "100000000-count-other": "000 mil'.' ¤",
              "1000000000-count-one": "0 mld'.' ¤",
              "1000000000-count-few": "0 mld'.' ¤",
              "1000000000-count-many": "0 mld'.' ¤",
              "1000000000-count-other": "0 mld'.' ¤",
              "10000000000-count-one": "00 mld'.' ¤",
              "10000000000-count-few": "00 mld'.' ¤",
              "10000000000-count-many": "00 mld'.' ¤",
              "10000000000-count-other": "00 mld'.' ¤",
              "100000000000-count-one": "000 mld'.' ¤",
              "100000000000-count-few": "000 mld'.' ¤",
              "100000000000-count-many": "000 mld'.' ¤",
              "100000000000-count-other": "000 mld'.' ¤",
              "1000000000000-count-one": "0 bil'.' ¤",
              "1000000000000-count-few": "0 bil'.' ¤",
              "1000000000000-count-many": "0 bil'.' ¤",
              "1000000000000-count-other": "0 bil'.' ¤",
              "10000000000000-count-one": "00 bil'.' ¤",
              "10000000000000-count-few": "00 bil'.' ¤",
              "10000000000000-count-many": "00 bil'.' ¤",
              "10000000000000-count-other": "00 bil'.' ¤",
              "100000000000000-count-one": "000 bil'.' ¤",
              "100000000000000-count-few": "000 bil'.' ¤",
              "100000000000000-count-many": "000 bil'.' ¤",
              "100000000000000-count-other": "000 bil'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0} – {1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} deň",
          "pluralMinimalPairs-count-few": "{0} dni",
          "pluralMinimalPairs-count-many": "{0} dňa",
          "pluralMinimalPairs-count-other": "{0} dní",
          "other": "Na {0}. križovatke odbočte doprava."
        }
      }
    }
  }
}
