import stringify = Mocha.utils.stringify;

const keyPrefix = "accustream_waterjet_calculator_";

export default class PersistenceManager {
    static saveValue(key:string, value:string) {
        window.localStorage.setItem(this.addPrefix(key), value);
    }

    static readValue(key:string):string {
        return window.localStorage.getItem(this.addPrefix(key));
    }

    static readArray(key:string):Array<number> {
        const jsonAsString = window.localStorage.getItem(this.addPrefix(key));
        return JSON.parse(jsonAsString);
    }

    static saveArray(key:string, arr:Array<number>) {
        const jsonAsString = JSON.stringify(arr);
        window.localStorage.setItem(this.addPrefix(key), jsonAsString);
    }

    static saveObject(key:string, object:any) {
        const jsonAsString = JSON.stringify(object);
        window.localStorage.setItem(this.addPrefix(key), jsonAsString);
    }

    static readObject(key:string):any {
        const jsonAsString = window.localStorage.getItem(this.addPrefix(key));
        return JSON.parse(jsonAsString);
    }

    static clearItem(key:string) {
        window.localStorage.removeItem(this.addPrefix(key));
    }

    static clearAll() {
        window.localStorage.clear();
    }

    static checkForExistingValue(key:string):boolean {
        return this.readValue(key) !== null;
    }

    private static addPrefix(key:string):string {
        return keyPrefix + key;
    }
}
