module.exports={
  "main": {
    "hi": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "hi"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "deva"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-deva": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-deva": {
          "standard": "#,##,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 हज़ार",
              "1000-count-other": "0 हज़ार",
              "10000-count-one": "00 हज़ार",
              "10000-count-other": "00 हज़ार",
              "100000-count-one": "0 लाख",
              "100000-count-other": "0 लाख",
              "1000000-count-one": "00 लाख",
              "1000000-count-other": "00 लाख",
              "10000000-count-one": "0 करोड़",
              "10000000-count-other": "0 करोड़",
              "100000000-count-one": "00 करोड़",
              "100000000-count-other": "00 करोड़",
              "1000000000-count-one": "0 अरब",
              "1000000000-count-other": "0 अरब",
              "10000000000-count-one": "00 अरब",
              "10000000000-count-other": "00 अरब",
              "100000000000-count-one": "0 खरब",
              "100000000000-count-other": "0 खरब",
              "1000000000000-count-one": "00 खरब",
              "1000000000000-count-other": "00 खरब",
              "10000000000000-count-one": "000 खरब",
              "10000000000000-count-other": "000 खरब",
              "100000000000000-count-one": "0000 खरब",
              "100000000000000-count-other": "0000 खरब"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 हज़ार",
              "1000-count-other": "0 हज़ार",
              "10000-count-one": "00 हज़ार",
              "10000-count-other": "00 हज़ार",
              "100000-count-one": "0 लाख",
              "100000-count-other": "0 लाख",
              "1000000-count-one": "00 लाख",
              "1000000-count-other": "00 लाख",
              "10000000-count-one": "0 क॰",
              "10000000-count-other": "0 क॰",
              "100000000-count-one": "00 क॰",
              "100000000-count-other": "00 क॰",
              "1000000000-count-one": "0 अ॰",
              "1000000000-count-other": "0 अ॰",
              "10000000000-count-one": "00 अ॰",
              "10000000000-count-other": "00 अ॰",
              "100000000000-count-one": "0 ख॰",
              "100000000000-count-other": "0 ख॰",
              "1000000000000-count-one": "00 ख॰",
              "1000000000000-count-other": "00 ख॰",
              "10000000000000-count-one": "0 नील",
              "10000000000000-count-other": "0 नील",
              "100000000000000-count-one": "00 नील",
              "100000000000000-count-other": "00 नील"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 हज़ार",
              "1000-count-other": "0 हज़ार",
              "10000-count-one": "00 हज़ार",
              "10000-count-other": "00 हज़ार",
              "100000-count-one": "0 लाख",
              "100000-count-other": "0 लाख",
              "1000000-count-one": "00 लाख",
              "1000000-count-other": "00 लाख",
              "10000000-count-one": "0 करोड़",
              "10000000-count-other": "0 करोड़",
              "100000000-count-one": "00 करोड़",
              "100000000-count-other": "00 करोड़",
              "1000000000-count-one": "0 अरब",
              "1000000000-count-other": "0 अरब",
              "10000000000-count-one": "00 अरब",
              "10000000000-count-other": "00 अरब",
              "100000000000-count-one": "0 खरब",
              "100000000000-count-other": "0 खरब",
              "1000000000000-count-one": "00 खरब",
              "1000000000000-count-other": "00 खरब",
              "10000000000000-count-one": "000 खरब",
              "10000000000000-count-other": "000 खरब",
              "100000000000000-count-one": "0000 खरब",
              "100000000000000-count-other": "0000 खरब"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 हज़ार",
              "1000-count-other": "0 हज़ार",
              "10000-count-one": "00 हज़ार",
              "10000-count-other": "00 हज़ार",
              "100000-count-one": "0 लाख",
              "100000-count-other": "0 लाख",
              "1000000-count-one": "00 लाख",
              "1000000-count-other": "00 लाख",
              "10000000-count-one": "0 क॰",
              "10000000-count-other": "0 क॰",
              "100000000-count-one": "00 क॰",
              "100000000-count-other": "00 क॰",
              "1000000000-count-one": "0 अ॰",
              "1000000000-count-other": "0 अ॰",
              "10000000000-count-one": "00 अ॰",
              "10000000000-count-other": "00 अ॰",
              "100000000000-count-one": "0 ख॰",
              "100000000000-count-other": "0 ख॰",
              "1000000000000-count-one": "00 ख॰",
              "1000000000000-count-other": "00 ख॰",
              "10000000000000-count-one": "0 नील",
              "10000000000000-count-other": "0 नील",
              "100000000000000-count-one": "00 नील",
              "100000000000000-count-other": "00 नील"
            }
          }
        },
        "scientificFormats-numberSystem-deva": {
          "standard": "[#E0]"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "[#E0]"
        },
        "percentFormats-numberSystem-deva": {
          "standard": "#,##,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##,##0%"
        },
        "currencyFormats-numberSystem-deva": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##,##0.00",
          "accounting": "¤#,##,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "¤0 हज़ार",
              "1000-count-other": "¤0 हज़ार",
              "10000-count-one": "¤00 हज़ार",
              "10000-count-other": "¤00 हज़ार",
              "100000-count-one": "¤0 लाख",
              "100000-count-other": "¤0 लाख",
              "1000000-count-one": "¤00 लाख",
              "1000000-count-other": "¤00 लाख",
              "10000000-count-one": "¤0 क॰",
              "10000000-count-other": "¤0 क॰",
              "100000000-count-one": "¤00 क॰",
              "100000000-count-other": "¤00 क॰",
              "1000000000-count-one": "¤0 अ॰",
              "1000000000-count-other": "¤0 अ॰",
              "10000000000-count-one": "¤00 अ॰",
              "10000000000-count-other": "¤00 अ॰",
              "100000000000-count-one": "¤0 ख॰",
              "100000000000-count-other": "¤0 ख॰",
              "1000000000000-count-one": "¤00 ख॰",
              "1000000000000-count-other": "¤00 ख॰",
              "10000000000000-count-one": "¤0 नील",
              "10000000000000-count-other": "¤0 नील",
              "100000000000000-count-one": "¤00 नील",
              "100000000000000-count-other": "¤00 नील"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##,##0.00",
          "accounting": "¤#,##,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "¤0 हज़ार",
              "1000-count-other": "¤0 हज़ार",
              "10000-count-one": "¤00 हज़ार",
              "10000-count-other": "¤00 हज़ार",
              "100000-count-one": "¤0 लाख",
              "100000-count-other": "¤0 लाख",
              "1000000-count-one": "¤00 लाख",
              "1000000-count-other": "¤00 लाख",
              "10000000-count-one": "¤0 क॰",
              "10000000-count-other": "¤0 क॰",
              "100000000-count-one": "¤00 क॰",
              "100000000-count-other": "¤00 क॰",
              "1000000000-count-one": "¤0 अ॰",
              "1000000000-count-other": "¤0 अ॰",
              "10000000000-count-one": "¤00 अ॰",
              "10000000000-count-other": "¤00 अ॰",
              "100000000000-count-one": "¤0 ख॰",
              "100000000000-count-other": "¤0 ख॰",
              "1000000000000-count-one": "¤00 ख॰",
              "1000000000000-count-other": "¤00 ख॰",
              "10000000000000-count-one": "¤0 नील",
              "10000000000000-count-other": "¤0 नील",
              "100000000000000-count-one": "¤00 नील",
              "100000000000000-count-other": "¤00 नील"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-deva": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} घंटा",
          "pluralMinimalPairs-count-other": "{0} घंटे",
          "few": "{0}था दाहिना मोड़ लें.",
          "many": "{0}ठा दाहिना मोड़ लें.",
          "one": "{0}ला दाहिना मोड़ लें.",
          "other": "{0}वां दाहिना मोड़ लें.",
          "two": "{0}रा दाहिना मोड़ लें."
        }
      }
    }
  }
}
