module.exports={
  "main": {
    "kk": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "kk"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "сан емес",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 мың",
              "1000-count-other": "0 мың",
              "10000-count-one": "00 мың",
              "10000-count-other": "00 мың",
              "100000-count-one": "000 мың",
              "100000-count-other": "000 мың",
              "1000000-count-one": "0 миллион",
              "1000000-count-other": "0 миллион",
              "10000000-count-one": "00 миллион",
              "10000000-count-other": "00 миллион",
              "100000000-count-one": "000 миллион",
              "100000000-count-other": "000 миллион",
              "1000000000-count-one": "0 миллиард",
              "1000000000-count-other": "0 миллиард",
              "10000000000-count-one": "00 миллиард",
              "10000000000-count-other": "00 миллиард",
              "100000000000-count-one": "000 миллиард",
              "100000000000-count-other": "000 миллиард",
              "1000000000000-count-one": "0 триллион",
              "1000000000000-count-other": "0 триллион",
              "10000000000000-count-one": "00 триллион",
              "10000000000000-count-other": "00 триллион",
              "100000000000000-count-one": "000 триллион",
              "100000000000000-count-other": "000 триллион"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 мың",
              "1000-count-other": "0 мың",
              "10000-count-one": "00 мың",
              "10000-count-other": "00 мың",
              "100000-count-one": "000 м'.'",
              "100000-count-other": "000 м'.'",
              "1000000-count-one": "0 млн",
              "1000000-count-other": "0 млн",
              "10000000-count-one": "00 млн",
              "10000000-count-other": "00 млн",
              "100000000-count-one": "000 млн",
              "100000000-count-other": "000 млн",
              "1000000000-count-one": "0 млрд",
              "1000000000-count-other": "0 млрд",
              "10000000000-count-one": "00 млрд",
              "10000000000-count-other": "00 млрд",
              "100000000000-count-one": "000 млрд",
              "100000000000-count-other": "000 млрд",
              "1000000000000-count-one": "0 трлн",
              "1000000000000-count-other": "0 трлн",
              "10000000000000-count-one": "00 трлн",
              "10000000000000-count-other": "00 трлн",
              "100000000000000-count-one": "000 трлн",
              "100000000000000-count-other": "000 трлн"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 мың ¤",
              "1000-count-other": "0 мың ¤",
              "10000-count-one": "00 мың ¤",
              "10000-count-other": "00 мың ¤",
              "100000-count-one": "000 мың ¤",
              "100000-count-other": "000 мың ¤",
              "1000000-count-one": "0 млн ¤",
              "1000000-count-other": "0 млн ¤",
              "10000000-count-one": "00 млн ¤",
              "10000000-count-other": "00 млн ¤",
              "100000000-count-one": "000 млн ¤",
              "100000000-count-other": "000 млн ¤",
              "1000000000-count-one": "0 млрд ¤",
              "1000000000-count-other": "0 млрд ¤",
              "10000000000-count-one": "00 млрд ¤",
              "10000000000-count-other": "00 млрд ¤",
              "100000000000-count-one": "000 млрд ¤",
              "100000000000-count-other": "000 млрд ¤",
              "1000000000000-count-one": "0 трлн ¤",
              "1000000000000-count-other": "0 трлн ¤",
              "10000000000000-count-one": "00 трлн ¤",
              "10000000000000-count-other": "00 трлн ¤",
              "100000000000000-count-one": "000 трлн ¤",
              "100000000000000-count-other": "000 трлн ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "Cебетте {0} Х бар. Ол сіздікі ме?",
          "pluralMinimalPairs-count-other": "Себетте {0} Х бар. Олар сіздікі ме?",
          "many": "{0}-ші бұрылыстан оңға бұрылыңыз.",
          "other": "{0}-шы бұрылыстан оңға бұрылыңыз."
        }
      }
    }
  }
}
