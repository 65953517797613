module.exports={
  "main": {
    "nb": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "nb"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "−",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":",
          "timeSeparator-alt-variant": "."
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tusen",
              "1000-count-other": "0 tusen",
              "10000-count-one": "00 tusen",
              "10000-count-other": "00 tusen",
              "100000-count-one": "000 tusen",
              "100000-count-other": "000 tusen",
              "1000000-count-one": "0 million",
              "1000000-count-other": "0 millioner",
              "10000000-count-one": "00 million",
              "10000000-count-other": "00 millioner",
              "100000000-count-one": "000 million",
              "100000000-count-other": "000 millioner",
              "1000000000-count-one": "0 milliard",
              "1000000000-count-other": "0 milliarder",
              "10000000000-count-one": "00 milliard",
              "10000000000-count-other": "00 milliarder",
              "100000000000-count-one": "000 milliard",
              "100000000000-count-other": "000 milliarder",
              "1000000000000-count-one": "0 billion",
              "1000000000000-count-other": "0 billioner",
              "10000000000000-count-one": "00 billioner",
              "10000000000000-count-other": "00 billioner",
              "100000000000000-count-one": "000 billioner",
              "100000000000000-count-other": "000 billioner"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0k",
              "1000-count-other": "0k",
              "10000-count-one": "00k",
              "10000-count-other": "00k",
              "100000-count-one": "000k",
              "100000-count-other": "000k",
              "1000000-count-one": "0 mill'.'",
              "1000000-count-other": "0 mill'.'",
              "10000000-count-one": "00 mill'.'",
              "10000000-count-other": "00 mill'.'",
              "100000000-count-one": "000 mill'.'",
              "100000000-count-other": "000 mill'.'",
              "1000000000-count-one": "0 mrd'.'",
              "1000000000-count-other": "0 mrd'.'",
              "10000000000-count-one": "00 mrd'.'",
              "10000000000-count-other": "00 mrd'.'",
              "100000000000-count-one": "000 mrd'.'",
              "100000000000-count-other": "000 mrd'.'",
              "1000000000000-count-one": "0 bill'.'",
              "1000000000000-count-other": "0 bill'.'",
              "10000000000000-count-one": "00 bill'.'",
              "10000000000000-count-other": "00 bill'.'",
              "100000000000000-count-one": "000 bill'.'",
              "100000000000000-count-other": "000 bill'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤ #,##0.00",
          "accounting": "¤ #,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "¤ 0k",
              "1000-count-other": "¤ 0k",
              "10000-count-one": "¤ 00k",
              "10000-count-other": "¤ 00k",
              "100000-count-one": "¤ 000k",
              "100000-count-other": "¤ 000k",
              "1000000-count-one": "¤ 0 mill'.'",
              "1000000-count-other": "¤ 0 mill'.'",
              "10000000-count-one": "¤ 00 mill'.'",
              "10000000-count-other": "¤ 00 mill'.'",
              "100000000-count-one": "¤ 000 mill'.'",
              "100000000-count-other": "¤ 000 mill'.'",
              "1000000000-count-one": "¤ 0 mrd'.'",
              "1000000000-count-other": "¤ 0 mrd'.'",
              "10000000000-count-one": "¤ 00 mrd'.'",
              "10000000000-count-other": "¤ 00 mrd'.'",
              "100000000000-count-one": "¤ 000 mrd'.'",
              "100000000000-count-other": "¤ 000 mrd'.'",
              "1000000000000-count-one": "¤ 0 bill'.'",
              "1000000000000-count-other": "¤ 0 bill'.'",
              "10000000000000-count-one": "¤ 00 bill'.'",
              "10000000000000-count-other": "¤ 00 bill'.'",
              "100000000000000-count-one": "¤ 000 bill'.'",
              "100000000000000-count-other": "¤ 000 bill'.'"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} dag",
          "pluralMinimalPairs-count-other": "{0} dager",
          "other": "Ta {0}. sving til høyre."
        }
      }
    }
  }
}
