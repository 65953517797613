module.exports={
  "main": {
    "ur": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "ur"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "arabext"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-arabext": {
          "decimal": "٫",
          "group": "٬",
          "list": ";",
          "percentSign": "%",
          "plusSign": "‎+‎",
          "minusSign": "‎-‎",
          "exponential": "×۱۰^",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": "٫"
        },
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "‎+",
          "minusSign": "‎-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-arabext": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 ہزار",
              "1000-count-other": "0 ہزار",
              "10000-count-one": "00 ہزار",
              "10000-count-other": "00 ہزار",
              "100000-count-one": "0 لاکھ",
              "100000-count-other": "0 لاکھ",
              "1000000-count-one": "00 لاکھ",
              "1000000-count-other": "00 لاکھ",
              "10000000-count-one": "0 کروڑ",
              "10000000-count-other": "0 کروڑ",
              "100000000-count-one": "00 کروڑ",
              "100000000-count-other": "00 کروڑ",
              "1000000000-count-one": "0 ارب",
              "1000000000-count-other": "0 ارب",
              "10000000000-count-one": "00 ارب",
              "10000000000-count-other": "00 ارب",
              "100000000000-count-one": "0 کھرب",
              "100000000000-count-other": "0 کھرب",
              "1000000000000-count-one": "00 کھرب",
              "1000000000000-count-other": "00 کھرب",
              "10000000000000-count-one": "00 ٹریلین",
              "10000000000000-count-other": "00 ٹریلین",
              "100000000000000-count-one": "000 ٹریلین",
              "100000000000000-count-other": "000 ٹریلین"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 ہزار",
              "1000-count-other": "0 ہزار",
              "10000-count-one": "00 ہزار",
              "10000-count-other": "00 ہزار",
              "100000-count-one": "0 لاکھ",
              "100000-count-other": "0 لاکھ",
              "1000000-count-one": "00 لاکھ",
              "1000000-count-other": "00 لاکھ",
              "10000000-count-one": "0 کروڑ",
              "10000000-count-other": "0 کروڑ",
              "100000000-count-one": "00 کروڑ",
              "100000000-count-other": "00 کروڑ",
              "1000000000-count-one": "0 ارب",
              "1000000000-count-other": "0 ارب",
              "10000000000-count-one": "00 ارب",
              "10000000000-count-other": "00 ارب",
              "100000000000-count-one": "0 کھرب",
              "100000000000-count-other": "0 کھرب",
              "1000000000000-count-one": "00 کھرب",
              "1000000000000-count-other": "00 کھرب",
              "10000000000000-count-one": "00 ٹریلین",
              "10000000000000-count-other": "00 ٹریلین",
              "100000000000000-count-one": "000 ٹریلین",
              "100000000000000-count-other": "000 ٹریلین"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 ہزار",
              "1000-count-other": "0 ہزار",
              "10000-count-one": "00 ہزار",
              "10000-count-other": "00 ہزار",
              "100000-count-one": "0 لاکھ",
              "100000-count-other": "0 لاکھ",
              "1000000-count-one": "00 لاکھ",
              "1000000-count-other": "00 لاکھ",
              "10000000-count-one": "0 کروڑ",
              "10000000-count-other": "0 کروڑ",
              "100000000-count-one": "00 کروڑ",
              "100000000-count-other": "00 کروڑ",
              "1000000000-count-one": "0 ارب",
              "1000000000-count-other": "0 ارب",
              "10000000000-count-one": "00 ارب",
              "10000000000-count-other": "00 ارب",
              "100000000000-count-one": "0 کھرب",
              "100000000000-count-other": "0 کھرب",
              "1000000000000-count-one": "00 کھرب",
              "1000000000000-count-other": "00 کھرب",
              "10000000000000-count-one": "00 ٹریلین",
              "10000000000000-count-other": "00 ٹریلین",
              "100000000000000-count-one": "000 ٹریلین",
              "100000000000000-count-other": "000 ٹریلین"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 ہزار",
              "1000-count-other": "0 ہزار",
              "10000-count-one": "00 ہزار",
              "10000-count-other": "00 ہزار",
              "100000-count-one": "0 لاکھ",
              "100000-count-other": "0 لاکھ",
              "1000000-count-one": "00 لاکھ",
              "1000000-count-other": "00 لاکھ",
              "10000000-count-one": "0 کروڑ",
              "10000000-count-other": "0 کروڑ",
              "100000000-count-one": "00 کروڑ",
              "100000000-count-other": "00 کروڑ",
              "1000000000-count-one": "0 ارب",
              "1000000000-count-other": "0 ارب",
              "10000000000-count-one": "00 ارب",
              "10000000000-count-other": "00 ارب",
              "100000000000-count-one": "0 کھرب",
              "100000000000-count-other": "0 کھرب",
              "1000000000000-count-one": "00 کھرب",
              "1000000000000-count-other": "00 کھرب",
              "10000000000000-count-one": "00 ٹریلین",
              "10000000000000-count-other": "00 ٹریلین",
              "100000000000000-count-one": "000 ٹریلین",
              "100000000000000-count-other": "000 ٹریلین"
            }
          }
        },
        "scientificFormats-numberSystem-arabext": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-arabext": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-arabext": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-one": "¤ 0 ہزار",
              "1000-count-other": "¤ 0 ہزار",
              "10000-count-one": "¤ 00 ہزار",
              "10000-count-other": "¤ 00 ہزار",
              "100000-count-one": "¤ 0 لاکھ",
              "100000-count-other": "¤ 0 لاکھ",
              "1000000-count-one": "¤ 00 لاکھ",
              "1000000-count-other": "¤ 00 لاکھ",
              "10000000-count-one": "¤ 0 کروڑ",
              "10000000-count-other": "¤ 0 کروڑ",
              "100000000-count-one": "¤ 00 کروڑ",
              "100000000-count-other": "¤ 00 کروڑ",
              "1000000000-count-one": "¤ 0 ارب",
              "1000000000-count-other": "¤ 0 ارب",
              "10000000000-count-one": "¤ 00 ارب",
              "10000000000-count-other": "¤ 00 ارب",
              "100000000000-count-one": "¤ 0 کھرب",
              "100000000000-count-other": "¤ 0 کھرب",
              "1000000000000-count-one": "¤0 ٹریلین",
              "1000000000000-count-other": "¤0 ٹریلین",
              "10000000000000-count-one": "¤ 00 ٹریلین",
              "10000000000000-count-other": "¤ 00 ٹریلین",
              "100000000000000-count-one": "¤ 000 ٹریلین",
              "100000000000000-count-other": "¤ 000 ٹریلین"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤ #,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-one": "¤ 0 ہزار",
              "1000-count-other": "¤ 0 ہزار",
              "10000-count-one": "¤ 00 ہزار",
              "10000-count-other": "¤ 00 ہزار",
              "100000-count-one": "¤ 0 لاکھ",
              "100000-count-other": "¤ 0 لاکھ",
              "1000000-count-one": "¤ 00 لاکھ",
              "1000000-count-other": "¤ 00 لاکھ",
              "10000000-count-one": "¤ 0 کروڑ",
              "10000000-count-other": "¤ 0 کروڑ",
              "100000000-count-one": "¤ 00 کروڑ",
              "100000000-count-other": "¤ 00 کروڑ",
              "1000000000-count-one": "¤ 0 ارب",
              "1000000000-count-other": "¤ 0 ارب",
              "10000000000-count-one": "¤ 00 ارب",
              "10000000000-count-other": "¤ 00 ارب",
              "100000000000-count-one": "¤ 0 کھرب",
              "100000000000-count-other": "¤ 0 کھرب",
              "1000000000000-count-one": "¤0 ٹریلین",
              "1000000000000-count-other": "¤0 ٹریلین",
              "10000000000000-count-one": "¤ 00 ٹریلین",
              "10000000000000-count-other": "¤ 00 ٹریلین",
              "100000000000000-count-one": "¤ 000 ٹریلین",
              "100000000000000-count-other": "¤ 000 ٹریلین"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-arabext": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} گھنٹہ",
          "pluralMinimalPairs-count-other": "{0} گھنٹے",
          "other": "دایاں موڑ نمبر {0} مڑیں۔"
        }
      }
    }
  }
}
