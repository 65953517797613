import Big from "big.js";

/**
 * Cut Speed and pierce time calculations.
 *
 * NOTES:
 * Good scientific calculator - https://www.meta-calculator.com/scientific-calculator.php?panel-201-calculator
 * Conversion from Big to number - https://github.com/MikeMcl/big.js/issues/21
 */
export class CutCalculation {
	/**
	 * Quality Index calculation - Q(i)
	 *
	 * @param materialThickness - T(m)
	 * @param cutQuality - integer value 1 - 5
	 */
	qualityIndex(materialThickness:number, cutQuality:number):number {
		return 1 + (cutQuality - 1) * (0.4 + materialThickness / (1 + materialThickness));
	}

	/**
	 * Cutting Water Pressure calculation - P(kw)
	 *
	 * @param waterPressure - water pressure in pounds per square inch (psi)
	 * @return water Pressure in Kilopounds per square inch (ksi)
	 */
	cuttingWaterPressure(waterPressure:number):number {
		return waterPressure / 1000;
	}

	/**
	 * Efficiency Variable calculation - E(v)
	 *
	 * @param abrasiveFlowRate - m(abrasive)
	 * @param cuttingWaterPressure P(kw)
	 * @param orificeDiameter - D(o)
	 * @param nozzleDiameter - D(n)
	 */
	efficiencyVariable(abrasiveFlowRate:number,  cuttingWaterPressure:number,
		orificeDiameter:number, nozzleDiameter:number):number {
			return 1 + (
				abrasiveFlowRate / (
					2000 * Math.sqrt(cuttingWaterPressure) * Math.pow(nozzleDiameter, 2) * Math.pow(orificeDiameter, 0.45)
				)
			);
	}

	/**
	 * Total Efficiecy calculation - E
	 *
	 * @param efficiencyVariable
	 */
	totalEfficiency(efficiencyVariable:number):number {
		return 0.2 + (0.7 / efficiencyVariable);
	}

	/**
	 * Abrasive Velocity calculation - V(a)
	 *
	 * @param orificeDiameter - D(o)
	 * @param cuttingWaterPressure P(kw)
	 * @param abrasiveFlowRate m(abrasive)
	 */
	abrasiveVelocity(orificeDiameter:number, cuttingWaterPressure:number, abrasiveFlowRate:number):number {
		const orificeDiameterPower = Math.pow(orificeDiameter, 2);
		return (2000000 * orificeDiameterPower * cuttingWaterPressure) /
			   (5391 * orificeDiameterPower * Math.sqrt(cuttingWaterPressure) + abrasiveFlowRate);
	}

	/**
	 * Feed rate variable calculation - F(v)
	 *
	 * @param materialThickness - T(m)
	 * @param totalEfficiency - E
	 * @param abrasiveVelocity - V(a)
	 * @param orificeDiameter - D(o)
	 * @param nozzleDiameter - D(n)
	 */
	feedRateVariable(materialThickness:number,
					 totalEfficiency:number,
					 abrasiveVelocity:number,
					 orificeDiameter:number,
					 nozzleDiameter:number):number {
		return -1.16 * Math.log(materialThickness) + 2.2 * Math.log(totalEfficiency * abrasiveVelocity) + 0.3 * Math.log(orificeDiameter / nozzleDiameter);
	}

	/**
	 * Generate abrasive flow value. Used when creating a custom orifice/nozzle combination.
	 *
	 * @param orificeDiameter - D(o)
	 * @param nozzleDiameter - D(n)
	 */
	generateAbrasiveFlowValue(orificeDiameter:number, nozzleDiameter:number, isMetric:boolean) {
	    const result = 1253 * nozzleDiameter * Math.pow(orificeDiameter, 0.8732);
	    if (isMetric) {
	    	return result / 1000;
		} else {
	    	return result;
		}
	}

	/**
	 * Cut speed calculation in inches per minute.
	 *
	 * @param machinabilityIndex - I(m)
	 * @param abrasiveFlowRate - m(abrasive)
	 * @param feedRateVariable - F(v)
	 * @param qualityIndex - Q(i)
	 * @param nozzleDiameter - D(n)
	 */
	cutSpeed(machinabilityIndex:number,
			 abrasiveFlowRate:number,
			 feedRateVariable:number,
			 qualityIndex:number,
			 nozzleDiameter:number):number {
		return 1.03 * ((0.000000020624 * machinabilityIndex * abrasiveFlowRate * (Math.exp(feedRateVariable))) /
					(qualityIndex * nozzleDiameter));
	}

	/**
	 * Inches cut per hour based on cut speed.
	 *
	 * @param cutSpeed
	 */
	inchesPerHour(cutSpeed:number) {
		return cutSpeed * 60;
	}

	/**
	 * Standard Pierce time calculation.
	 * Excel formula: (30.55*(B3^0.6))/(((0.000000021*B2*B7*EXP(E11))/(J11*B6))*J11)
	 *
	 * @param machinabilityIndex - I(m) - B2
	 * @param materialThickness - T(m) - B3
	 * @param nozzleDiameter - D(n) - B6
	 * @param abrasiveFlowRate - m(abrasive) - B7
	 * @param feedRateVariable - F(v) - E11
	 * @param qualityIndex - Q(i) - J11
	 */
	pierceTime(machinabilityIndex:number,
			   materialThickness:number,
			   nozzleDiameter:number,
			   abrasiveFlowRate:number,
			   feedRateVariable:number,
			   qualityIndex:number):number {
		return (30.55 * Math.pow(materialThickness, 0.6)) / (
					((0.000000021 * machinabilityIndex * abrasiveFlowRate * Math.exp(feedRateVariable)) / (qualityIndex * nozzleDiameter))
					* qualityIndex
				)
	}

	/**
	 * Stationary Pierce time calculation.
	 * Excel formula: 38*50*EXP(LN(B3)*2.2)/(B2*EXP(LN(B4/1000)*1))
	 *
	 * @param machinabilityIndex - I(m) - B2
	 * @param materialThickness - T(m) - B3
	 * @param piercingPressure - P(w) - B4
	 */
	stationaryPierceTime(machinabilityIndex:number,
						 materialThickness:number,
						 piercingPressure:number):number {

		return 38 * 50 * Math.exp(Math.log(materialThickness) * 2.2) /
							(machinabilityIndex * Math.exp(Math.log(piercingPressure / 1000)* 1))
	}

	/**
	 * Dynamic Pierce time calculation.
	 * Excel formula: (30.55*(B3^0.6))/((0.000000021)*B2*B7*EXP((-1.16*LN(B3)+0.3*LN(B5/B6)+2.2*LN(((0.2+(0.9-0.2)/(1+B7/(2000*SQRT(G11)*B6^2*(B5^0.45)))))*(2000000*(B5^2)*(G11)/(5391*B5^2*SQRT(G11)+B7)))))/(J11*B6)*J11)
	 *
	 * @param machinabilityIndex - I(m) - B2
	 * @param materialThickness - T(m) - B3
	 * @param orificeDiameter - D(o) - B5
	 * @param nozzleDiameter - D(n) - B6
	 * @param abrasiveFlowRate - m(abrasive) - B7
	 * @param cuttingWaterPressure - P(kw) - G11
	 * @param qualityIndex - Q(i) - J11
	 */
	dynamicPierceTime(machinabilityIndex:number,
					  materialThickness:number,
					  orificeDiameter:number,
					  nozzleDiameter:number,
					  abrasiveFlowRate:number,
					  cuttingWaterPressure:number,
					  qualityIndex:number):number {

		return (30.55*Math.pow(materialThickness, 0.6)) / (
				(0.000000021)*machinabilityIndex*abrasiveFlowRate*Math.exp(
					-1.16*Math.log(materialThickness)+0.3*Math.log(orificeDiameter/nozzleDiameter)+2.2*Math.log(
						(0.2+(0.9-0.2)/(1+abrasiveFlowRate/(2000*Math.sqrt(cuttingWaterPressure)*Math.pow(nozzleDiameter, 2)*Math.pow(orificeDiameter, 0.45))))
						*(2000000*Math.pow(orificeDiameter, 2)*(cuttingWaterPressure)/(5391*Math.pow(orificeDiameter, 2)*Math.sqrt(cuttingWaterPressure)+abrasiveFlowRate))
					)
				) / (qualityIndex*nozzleDiameter)*qualityIndex
		 );
	}
}

/**
 * Cost calculation methods used when determine total costs of waterjet operation.
 */
export class CostCalculation {
	/**
	 * Cutting water gallons per minute calculation.
	 * Formula: orificeNumber * 19.9 * orificeDiameter^2 * sqrt(waterPressure)
	 *
	 * @param orificeNumber
	 * @param orificeDiameter
	 * @param waterPressure - water pressure in pounds per square inch (psi)
	 */
	cuttingWaterGPM(orificeNumber:number, orificeDiameter:number, waterPressure:number):Big {
		Big.RM = 0;
		const orificeDiameterSquared = new Big(orificeDiameter).pow(2);
		const squareRootWaterPressure = new Big(waterPressure).sqrt();

		return new Big(orificeNumber).times(19.9).times(orificeDiameterSquared).times(squareRootWaterPressure);
	}

	/**
	 * Nozzle Power calculation.
	 *
	 * Formula: (CuttingWater GPM * Pressure / 1714) * 0.745699872
	 *
	 * @param cuttingWaterGallonsPerMinutes - cutting water gallons per minute
	 * @param waterPressure - water pressure in pounds per square inch (psi)
	 * @returns nozzle power in kw
	 */
	nozzlePower(cuttingWaterGPM:Big, waterPressure:number):Big {
		Big.RM = 0;
		const nozzlePowerInHorsePower = cuttingWaterGPM.times(waterPressure).div(1714);
		return nozzlePowerInHorsePower.times(0.745699872);
	}

	/**
	 * Energy Cost calculation.
	 *
	 * Formula: (0.4 + (0.6 * (nozzleKWH / pumpKWH))) * (pumpKWH * energyCostKWH)
	 *
	 * @param nozzleKWH
	 * @param pumpKWH
	 * @param energyCostKWH
	 */
	energyCost(nozzleKWH:Big, pumpKWH:number, energyCostKWH:number):Big {
		Big.RM = 0;
		const power = new Big(0.4).plus(new Big(0.6).times(nozzleKWH.div(pumpKWH)));
		const cost = new Big(pumpKWH).times(energyCostKWH);

		return power.times(cost);
	}

	/**
	 * Replacement Part Cost calculation.
	 *
	 * Formula: pumpReplacementCost + (headReplacementCost * orificeNumber)
	 *
	 * @param pumpReplacementCost $ per hour
	 * @param headReplacementCost  $ per hour
	 * @param orificeNumber
	 */
	replacementPartsCost(pumpReplacementCost:number, headReplacementCost:number, orificeNumber:number):Big {
		Big.RM = 0;
		return new Big(pumpReplacementCost).plus(new Big(headReplacementCost).times(orificeNumber));
	}

	/**
	 * Water Cost calculation.
	 *
	 * Formula: (waterCostPerThousandHours / 1000) * (pumpCoolingGPM + cuttingWaterGPM) * 60
	 *
	 * @param waterCostPerThousandHours
	 * @param pumpCoolingGPM
	 * @param cuttingWaterGPM
	 */
	waterCost(waterCostPerThousandHours:number, pumpCoolingGPM:number, cuttingWaterGPM:Big):Big {
		Big.RM = 0;
		return new Big(new Big(waterCostPerThousandHours).div(1000)).times(new Big(pumpCoolingGPM).plus(cuttingWaterGPM)).times(60);
	}

	/**
	 * Abrasive Cost calculation.
	 *
	 * Formula: abrasiveCost * abrasiveFlowRate * 60 * orificeNumber
	 *
	 * @param abrasiveCost // per pound
	 * @param abrasiveFlowRate // lb per minute
	 * @param orificeNumber
	 */
	abrasiveCost(abrasiveCost:number, abrasiveFlowRate:number, orificeNumber:number):Big {
		Big.RM = 0;
		return new Big(abrasiveCost).times(abrasiveFlowRate).times(60).times(orificeNumber);
	}

	/**
	 * Cost per hour calculation.
	 *
	 * @param replacementPartsCost
	 * @param waterCost
	 * @param laborCost
	 * @param energyCost
	 * @param abrasiveCost
	 */
	costPerHour(replacementPartsCost:Big, waterCost:Big, laborCost:Big, energyCost:Big, abrasiveCost:Big):Big {
		return replacementPartsCost.plus(waterCost).plus(laborCost).plus(energyCost).plus(abrasiveCost);
	}

	/**
	 * Total Cost calculation.
	 *
	 * @param costPerHour
	 * @param cutSpeed
	 */
	costPerInch(costPerHour:Big, cutSpeed:Big):Big {
		Big.RM = 0;
		const cutSpeedPerHour = cutSpeed.times(60);
		return costPerHour.div(cutSpeedPerHour);
	}
}

export class Validations {
	/**
	 * Validate the specified orifice diameter minimum.
     *
	 * @param orificeDiameter - in inches
	 */
	invalidOrificeMinimumDiameter(orificeDiameter:number):boolean {
	    return orificeDiameter < 0.004;
	}

	/**
	 * Validate the specified orifice diameter maximum.
	 *
	 * @param orificeDiameter
	 */
	invalidOrificeMaximumDiameter(orificeDiameter:number):boolean {
	    return orificeDiameter > 0.03;
	}

	/**
	 * Validate the specified orifice/nozzle diameter ratio.
	 *
	 * @param nozzleDiameter - in inches
	 * @param orificeDiameter - in inches
	 */
	invalidNozzleOrificeRatio(nozzleDiameter:number, orificeDiameter:number):boolean {
		return new Big(nozzleDiameter).div(orificeDiameter).lt(1.5);
	}

	/**
	 * Validate that cutting water GPM is less than maximum pump GPM.
	 *
	 * @param cuttingWaterGPM
	 * @param maximumPumpGPM
	 */
	invalidCuttingWaterGPM(cuttingWaterGPM:number, maximumPumpGPM:number):boolean {
	    return cuttingWaterGPM > maximumPumpGPM;
	}

	/**
	 * Validate minimum pressure.
	 *
	 * @param pressure
	 * @param minimumPumpPressure
	 */
	invalidMinPressure(pressure:number, minimumPumpPressure:number):boolean {
		return pressure < minimumPumpPressure;
	}

	/**
	 * Validate maximum pressure.
	 *
	 * @param pressure
	 * @param maximumPumpPressure
	 */
	invalidMaxPressure(pressure:number, maximumPumpPressure:number):boolean {
		return pressure > maximumPumpPressure;
	}
}

/**
 * Helper class with general unit conversion methods.
 */
export class UnitConversion {
	/**
	 * Convert from kilograms to pounds.
	 *
	 * @param kilograms
	 */
	convertKgToPound(grams:number):Big {
		Big.RM = 1;
		return new Big(grams).div(0.453592);
	}

	/**
	 * Convert from pounds to kilograms.
	 *
	 * @param pounds
	 */
	convertPoundsToKg(pounds:number):Big {
		Big.RM = 1;
		return new Big(pounds).times(0.453592);
	}

	/**
	 * Convert per-kilogram cost to per-pound cost.
	 * Delegates to {@link UnitConversion#convertPoundsToKg} as same formula.
	 *
	 * @param price
	 */
	convertPerKgToPerPound(price:number):Big {
	    return this.convertPoundsToKg(price);
	}

	/**
	 * Convert per-pound cost to per-kilogram cost.
	 * Delegates to {@link UnitConversion#convertKgToPound} as same formula.
	 *
	 * @param price
	 */
	convertPerPoundToPerKg(price:number):Big {
		return this.convertKgToPound(price);
	}

	/**
	 * Convert from PSI to BAR.
	 *
	 * @param psi
	 */
	convertPSIToBar(psi:number):Big {
		Big.RM = 1;
		return new Big(psi).times(0.0689476);
	}

	/**
	 * Convert from BAR to PSI.
	 *
	 * @param bar
	 */
	convertBarToPSI(bar:number):Big {
		Big.RM = 1;
		return new Big(bar).div(0.0689476);
	}

	/**
	 * Convert from mm to inches.
	 * @param millimeters
	 */
	convertMillimetersToInches(millimeters:number):Big {
		Big.RM = 1;
		return new Big(millimeters).div(25.4);
	}

	convertInchesToCentimeters(inches:number):Big {
	    Big.RM = 1;
	    return new Big(inches).times(2.54);
	}

	convertPerInchToPerCentimeter(inches:number):Big {
		Big.RM = 1;
		return new Big(inches).div(2.54);
	}

	/**
	 * Convert inches to mm.
	 * @param inches
	 */
	convertInchesToMillimeters(inches:number):Big {
		Big.RM = 1;
		return new Big(inches).times(25.4);
	}

	/**
	 * Convert litres to gallons.
     *
	 * @param litres
	 */
	convertLitresToGallons(litres:number):Big {
		Big.RM = 1;
		return new Big(litres).div(3.785411784);
	}

	/**
	 * Convert gallons to litres.
     *
	 * @param gallons
	 */
	convertGallonsToLitres(gallons:number):Big {
		Big.RM = 1;
		return new Big(gallons).times(3.785411784);
	}

	/**
	 * Convert per litre to per gallon.
	 * Delegates to {@link UnitConversion#convertGallonsToLitres} as same formula.
	 *
	 * @param price
	 */
	convertPerLiterToPerGallon(price:number):Big {
		return this.convertGallonsToLitres(price);
	}

	/**
	 * Convert per gallons to per litres
	 * Delegates to {@link UnitConversion#convertLitresToGallons} as same formula.
	 *
	 * @param price
	 */
	convertPerGallonsToPerLitre(price:number):Big {
	    return this.convertLitresToGallons(price);
	}

	/**
	 * Convert from horse power (hp) to kilowatt (kW)
	 * @param horsePower
	 */
	convertHorsePowerToKiloWatt(horsePower: number):Big {
		Big.RM = 0;
		return new Big(horsePower).times(0.745699872);
	}

	/**
	 * Convert currency using supplied exchange rate.
	 *
	 * @param cost
	 * @param exchangeRate
	 */
	convertCurrency(cost:number, exchangeRate:number):Big {
		Big.RM = 0;
		return new Big(cost).times(exchangeRate);
	}
}