module.exports={
  "main": {
    "si": {
      "identity": {
        "version": {
          "_number": "$Revision: 14817 $",
          "_cldrVersion": "35.1"
        },
        "language": "si"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": "."
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "දහස 0",
              "1000-count-other": "දහස 0",
              "10000-count-one": "දහස 00",
              "10000-count-other": "දහස 00",
              "100000-count-one": "දහස 000",
              "100000-count-other": "දහස 000",
              "1000000-count-one": "මිලියන 0",
              "1000000-count-other": "මිලියන 0",
              "10000000-count-one": "මිලියන 00",
              "10000000-count-other": "මිලියන 00",
              "100000000-count-one": "මිලියන 000",
              "100000000-count-other": "මිලියන 000",
              "1000000000-count-one": "බිලියන 0",
              "1000000000-count-other": "බිලියන 0",
              "10000000000-count-one": "බිලියන 00",
              "10000000000-count-other": "බිලියන 00",
              "100000000000-count-one": "බිලියන 000",
              "100000000000-count-other": "බිලියන 000",
              "1000000000000-count-one": "ට්‍රිලියන 0",
              "1000000000000-count-other": "ට්‍රිලියන 0",
              "10000000000000-count-one": "ට්‍රිලියන 00",
              "10000000000000-count-other": "ට්‍රිලියන 00",
              "100000000000000-count-one": "ට්‍රිලියන 000",
              "100000000000000-count-other": "ට්‍රිලියන 000"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "ද0",
              "1000-count-other": "ද0",
              "10000-count-one": "ද00",
              "10000-count-other": "ද00",
              "100000-count-one": "ද000",
              "100000-count-other": "ද000",
              "1000000-count-one": "මි0",
              "1000000-count-other": "මි0",
              "10000000-count-one": "මි00",
              "10000000-count-other": "මි00",
              "100000000-count-one": "මි000",
              "100000000-count-other": "මි000",
              "1000000000-count-one": "බි0",
              "1000000000-count-other": "බි0",
              "10000000000-count-one": "බි00",
              "10000000000-count-other": "බි00",
              "100000000000-count-one": "බි000",
              "100000000000-count-other": "බි000",
              "1000000000000-count-one": "ට්‍රි0",
              "1000000000000-count-other": "ට්‍රි0",
              "10000000000000-count-one": "ට්‍රි00",
              "10000000000000-count-other": "ට්‍රි00",
              "100000000000000-count-one": "ට්‍රි000",
              "100000000000000-count-other": "ට්‍රි000"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-one": "¤ද0",
              "1000-count-other": "¤ද0",
              "10000-count-one": "¤ද00",
              "10000-count-other": "¤ද00",
              "100000-count-one": "¤ද000",
              "100000-count-other": "¤ද000",
              "1000000-count-one": "¤මි0",
              "1000000-count-other": "¤මි0",
              "10000000-count-one": "¤මි00",
              "10000000-count-other": "¤මි00",
              "100000000-count-one": "¤මි000",
              "100000000-count-other": "¤මි000",
              "1000000000-count-one": "¤බි0",
              "1000000000-count-other": "¤බි0",
              "10000000000-count-one": "¤බි00",
              "10000000000-count-other": "¤බි00",
              "100000000000-count-one": "¤බි000",
              "100000000000-count-other": "¤බි000",
              "1000000000000-count-one": "¤ට්‍රි0",
              "1000000000000-count-other": "¤ට්‍රි0",
              "10000000000000-count-one": "¤ට්‍රි00",
              "10000000000000-count-other": "¤ට්‍රි00",
              "100000000000000-count-one": "¤ට්‍රි000",
              "100000000000000-count-other": "¤ට්‍රි000"
            }
          },
          "unitPattern-count-one": "{1}{0}",
          "unitPattern-count-other": "{1}{0}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} පොතක් ඇත. එය කියවීමි.",
          "pluralMinimalPairs-count-other": "පොත් {0}ක් ඇත. ඒවා කියවීමි.",
          "other": "{0} වන හැරවුම දකුණට"
        }
      }
    }
  }
}
