module.exports={
  "main": {
    "ja": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "ja"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn",
          "traditional": "jpan",
          "finance": "jpanfin"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0億",
              "1000000000-count-other": "00億",
              "10000000000-count-other": "000億",
              "100000000000-count-other": "0000億",
              "1000000000000-count-other": "0兆",
              "10000000000000-count-other": "00兆",
              "100000000000000-count-other": "000兆"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0",
              "10000-count-other": "0万",
              "100000-count-other": "00万",
              "1000000-count-other": "000万",
              "10000000-count-other": "0000万",
              "100000000-count-other": "0億",
              "1000000000-count-other": "00億",
              "10000000000-count-other": "000億",
              "100000000000-count-other": "0000億",
              "1000000000000-count-other": "0兆",
              "10000000000000-count-other": "00兆",
              "100000000000000-count-other": "000兆"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-other": "0",
              "10000-count-other": "¤0万",
              "100000-count-other": "¤00万",
              "1000000-count-other": "¤000万",
              "10000000-count-other": "¤0000万",
              "100000000-count-other": "¤0億",
              "1000000000-count-other": "¤00億",
              "10000000000-count-other": "¤000億",
              "100000000000-count-other": "¤0000億",
              "1000000000000-count-other": "¤0兆",
              "10000000000000-count-other": "¤00兆",
              "100000000000000-count-other": "¤000兆"
            }
          },
          "unitPattern-count-other": "{0}{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "約 {0}",
          "atLeast": "{0} 以上",
          "atMost": "{0} 以下",
          "range": "{0}～{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-other": "{0}日",
          "other": "{0} 番目の角を右折します。"
        }
      }
    }
  }
}
