module.exports={
  "main": {
    "az": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "az"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 min",
              "1000-count-other": "0 min",
              "10000-count-one": "00 min",
              "10000-count-other": "00 min",
              "100000-count-one": "000 min",
              "100000-count-other": "000 min",
              "1000000-count-one": "0 milyon",
              "1000000-count-other": "0 milyon",
              "10000000-count-one": "00 milyon",
              "10000000-count-other": "00 milyon",
              "100000000-count-one": "000 milyon",
              "100000000-count-other": "000 milyon",
              "1000000000-count-one": "0 milyard",
              "1000000000-count-other": "0 milyard",
              "10000000000-count-one": "00 milyard",
              "10000000000-count-other": "00 milyard",
              "100000000000-count-one": "000 milyard",
              "100000000000-count-other": "000 milyard",
              "1000000000000-count-one": "0 trilyon",
              "1000000000000-count-other": "0 trilyon",
              "10000000000000-count-one": "00 trilyon",
              "10000000000000-count-other": "00 trilyon",
              "100000000000000-count-one": "000 trilyon",
              "100000000000000-count-other": "000 trilyon"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0K",
              "1000-count-other": "0K",
              "10000-count-one": "00K",
              "10000-count-other": "00K",
              "100000-count-one": "000K",
              "100000-count-other": "000K",
              "1000000-count-one": "0M",
              "1000000-count-other": "0M",
              "10000000-count-one": "00M",
              "10000000-count-other": "00M",
              "100000000-count-one": "000M",
              "100000000-count-other": "000M",
              "1000000000-count-one": "0G",
              "1000000000-count-other": "0G",
              "10000000000-count-one": "00G",
              "10000000000-count-other": "00G",
              "100000000000-count-one": "000G",
              "100000000000-count-other": "000G",
              "1000000000000-count-one": "0T",
              "1000000000000-count-other": "0T",
              "10000000000000-count-one": "00T",
              "10000000000000-count-other": "00T",
              "100000000000000-count-one": "000T",
              "100000000000000-count-other": "000T"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0K ¤",
              "1000-count-other": "0K ¤",
              "10000-count-one": "00K ¤",
              "10000-count-other": "00K ¤",
              "100000-count-one": "000K ¤",
              "100000-count-other": "000K ¤",
              "1000000-count-one": "0M ¤",
              "1000000-count-other": "0M ¤",
              "10000000-count-one": "00M ¤",
              "10000000-count-other": "00M ¤",
              "100000000-count-one": "000M ¤",
              "100000000-count-other": "000M ¤",
              "1000000000-count-one": "0G ¤",
              "1000000000-count-other": "0G ¤",
              "10000000000-count-one": "00G ¤",
              "10000000000-count-other": "00G ¤",
              "100000000000-count-one": "000G ¤",
              "100000000000-count-other": "000G ¤",
              "1000000000000-count-one": "0T ¤",
              "1000000000000-count-other": "0T ¤",
              "10000000000000-count-one": "00T ¤",
              "10000000000000-count-other": "00T ¤",
              "100000000000000-count-one": "000T ¤",
              "100000000000000-count-other": "000T ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "Alış-veriş katınızda {0} X var. Almaq istəyirsiniz?",
          "pluralMinimalPairs-count-other": "Alış-veriş kartınızda {0} X var. Almaq istəyirsiniz?",
          "few": "{0}-cü sağ döngəni seçin.",
          "many": "{0}-cı sağ döngəni seçin.",
          "one": "{0}-ci sağ döngəni seçin.",
          "other": "{0}-cu sağ döngəni seçin."
        }
      }
    }
  }
}
