module.exports={
  "main": {
    "bn": {
      "identity": {
        "version": {
          "_number": "$Revision: 14836 $",
          "_cldrVersion": "35.1"
        },
        "language": "bn"
      },
      "numbers": {
        "defaultNumberingSystem": "beng",
        "otherNumberingSystems": {
          "native": "beng"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-beng": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-beng": {
          "standard": "#,##,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 হাজার",
              "1000-count-other": "0 হাজার",
              "10000-count-one": "00 হাজার",
              "10000-count-other": "00 হাজার",
              "100000-count-one": "0 লাখ",
              "100000-count-other": "0 লাখ",
              "1000000-count-one": "00 লাখ",
              "1000000-count-other": "00 লাখ",
              "10000000-count-one": "0 কোটি",
              "10000000-count-other": "0 কোটি",
              "100000000-count-one": "00 কোটি",
              "100000000-count-other": "00 কোটি",
              "1000000000-count-one": "000 কোটি",
              "1000000000-count-other": "000 কোটি",
              "10000000000-count-one": "0000 কোটি",
              "10000000000-count-other": "0000 কোটি",
              "100000000000-count-one": "00000 কোটি",
              "100000000000-count-other": "00000 কোটি",
              "1000000000000-count-one": "0 লাখ কোটি",
              "1000000000000-count-other": "0 লাখ কোটি",
              "10000000000000-count-one": "00 লাখ কোটি",
              "10000000000000-count-other": "00 লাখ কোটি",
              "100000000000000-count-one": "000 লাখ কোটি",
              "100000000000000-count-other": "000 লাখ কোটি"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 হা",
              "1000-count-other": "0 হা",
              "10000-count-one": "00 হা",
              "10000-count-other": "00 হা",
              "100000-count-one": "0 লা",
              "100000-count-other": "0 লা",
              "1000000-count-one": "00 লা",
              "1000000-count-other": "00 লা",
              "10000000-count-one": "0 কো",
              "10000000-count-other": "0 কো",
              "100000000-count-one": "00 কো",
              "100000000-count-other": "00 কো",
              "1000000000-count-one": "000 কো",
              "1000000000-count-other": "000 কো",
              "10000000000-count-one": "0000 কো",
              "10000000000-count-other": "0000 কো",
              "100000000000-count-one": "00000 কো",
              "100000000000-count-other": "00000 কো",
              "1000000000000-count-one": "0 লা'.'কো'.'",
              "1000000000000-count-other": "0 লা'.'কো'.'",
              "10000000000000-count-one": "00 লা'.'কো'.'",
              "10000000000000-count-other": "00 লা'.'কো'.'",
              "100000000000000-count-one": "000 লা'.'কো'.'",
              "100000000000000-count-other": "000 লা'.'কো'.'"
            }
          }
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 হাজার",
              "1000-count-other": "0 হাজার",
              "10000-count-one": "00 হাজার",
              "10000-count-other": "00 হাজার",
              "100000-count-one": "0 লাখ",
              "100000-count-other": "0 লাখ",
              "1000000-count-one": "00 লাখ",
              "1000000-count-other": "00 লাখ",
              "10000000-count-one": "0 কোটি",
              "10000000-count-other": "0 কোটি",
              "100000000-count-one": "00 কোটি",
              "100000000-count-other": "00 কোটি",
              "1000000000-count-one": "000 কোটি",
              "1000000000-count-other": "000 কোটি",
              "10000000000-count-one": "0000 কোটি",
              "10000000000-count-other": "0000 কোটি",
              "100000000000-count-one": "00000 কোটি",
              "100000000000-count-other": "00000 কোটি",
              "1000000000000-count-one": "0 লাখ কোটি",
              "1000000000000-count-other": "0 লাখ কোটি",
              "10000000000000-count-one": "00 লাখ কোটি",
              "10000000000000-count-other": "00 লাখ কোটি",
              "100000000000000-count-one": "000 লাখ কোটি",
              "100000000000000-count-other": "000 লাখ কোটি"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 হা",
              "1000-count-other": "0 হা",
              "10000-count-one": "00 হা",
              "10000-count-other": "00 হা",
              "100000-count-one": "0 লা",
              "100000-count-other": "0 লা",
              "1000000-count-one": "00 লা",
              "1000000-count-other": "00 লা",
              "10000000-count-one": "0 কো",
              "10000000-count-other": "0 কো",
              "100000000-count-one": "00 কো",
              "100000000-count-other": "00 কো",
              "1000000000-count-one": "000 কো",
              "1000000000-count-other": "000 কো",
              "10000000000-count-one": "0000 কো",
              "10000000000-count-other": "0000 কো",
              "100000000000-count-one": "00000 কো",
              "100000000000-count-other": "00000 কো",
              "1000000000000-count-one": "0 লা'.'কো'.'",
              "1000000000000-count-other": "0 লা'.'কো'.'",
              "10000000000000-count-one": "00 লা'.'কো'.'",
              "10000000000000-count-other": "00 লা'.'কো'.'",
              "100000000000000-count-one": "000 লা'.'কো'.'",
              "100000000000000-count-other": "000 লা'.'কো'.'"
            }
          }
        },
        "scientificFormats-numberSystem-beng": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-beng": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##,##0%"
        },
        "currencyFormats-numberSystem-beng": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##,##0.00¤",
          "accounting": "#,##,##0.00¤;(#,##,##0.00¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 হা¤",
              "1000-count-other": "0 হা¤",
              "10000-count-one": "00 হা¤",
              "10000-count-other": "00 হা¤",
              "100000-count-one": "0 লা¤",
              "100000-count-other": "0 লা¤",
              "1000000-count-one": "00 লা¤",
              "1000000-count-other": "00 লা¤",
              "10000000-count-one": "0 কো¤",
              "10000000-count-other": "0 কো¤",
              "100000000-count-one": "00 কো¤",
              "100000000-count-other": "00 কো¤",
              "1000000000-count-one": "000 কো¤",
              "1000000000-count-other": "000 কো¤",
              "10000000000-count-one": "0000 কো¤",
              "10000000000-count-other": "0000 কো¤",
              "100000000000-count-one": "00000 কো¤",
              "100000000000-count-other": "00000 কো¤",
              "1000000000000-count-one": "0 লা'.'কো'.'¤",
              "1000000000000-count-other": "0 লা'.'কো'.'¤",
              "10000000000000-count-one": "00 লা'.'কো'.'¤",
              "10000000000000-count-other": "00 লা'.'কো'.'¤",
              "100000000000000-count-one": "000 লা'.'কো'.'¤",
              "100000000000000-count-other": "000 লা'.'কো'.'¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##,##0.00¤",
          "accounting": "#,##,##0.00¤;(#,##,##0.00¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 হা¤",
              "1000-count-other": "0 হা¤",
              "10000-count-one": "00 হা¤",
              "10000-count-other": "00 হা¤",
              "100000-count-one": "0 লা¤",
              "100000-count-other": "0 লা¤",
              "1000000-count-one": "00 লা¤",
              "1000000-count-other": "00 লা¤",
              "10000000-count-one": "0 কো¤",
              "10000000-count-other": "0 কো¤",
              "100000000-count-one": "00 কো¤",
              "100000000-count-other": "00 কো¤",
              "1000000000-count-one": "000 কো¤",
              "1000000000-count-other": "000 কো¤",
              "10000000000-count-one": "0000 কো¤",
              "10000000000-count-other": "0000 কো¤",
              "100000000000-count-one": "00000 কো¤",
              "100000000000-count-other": "00000 কো¤",
              "1000000000000-count-one": "0 লা'.'কো'.'¤",
              "1000000000000-count-other": "0 লা'.'কো'.'¤",
              "10000000000000-count-one": "00 লা'.'কো'.'¤",
              "10000000000000-count-other": "00 লা'.'কো'.'¤",
              "100000000000000-count-one": "000 লা'.'কো'.'¤",
              "100000000000000-count-other": "000 লা'.'কো'.'¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-beng": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "সসে {0}টি আপেল খেল, সেটা ভাল",
          "pluralMinimalPairs-count-other": "সসে {0}টি আপেল খেল, সেগুলি ভাল",
          "few": "ডান দিকে {0}র্থ বাঁকটি নিন।",
          "many": "ডান দিকে {0}ষ্ঠ বাঁকটি নিন।",
          "one": "ডান দিকে {0}ম বাঁকটি নিন।",
          "other": "ডান দিকে {0}তম বাঁকটি নিন।",
          "two": "ডান দিকে {0}য় বাঁকটি নিন।"
        }
      }
    }
  }
}
