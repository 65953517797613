export default class NumberUtil {
	public static limit(val:number, min:number, max:number):number {
		return Math.max(min, Math.min(max, val));
	}
	public static round(val:number, decimalPoints:number = 0):number {
		if (decimalPoints < 1) {
			return Math.round(val);
		}
		const rounder = 10 * decimalPoints;
		return Math.round(val * rounder) / rounder;
	}
}