import PersistenceManager from "./persistance";
import NumberUtil from "./numberUtil";

const selectorClass = ".accordion";
const activeClass = "accordion--active";
const persistenceKey = "active_accordions";

class Accordions {
	constructor() {}

	/**
	 * Setup all accordion elements.
	 */
	init() {
		if (this.getAllAccordions().length > 0) {
			// Open or close all active accordions from value saved in localStorage
			this.restoreAccordionStates();

			const _updateAllHeights = this.updateAllHeights.bind(this);
			window.addEventListener("load", _updateAllHeights, false);
			window.addEventListener("resize", _updateAllHeights, false);
		}
	}

	/**
	 * Toggle accordions open and closed.
	 *
	 * @param headingElem
	 */
	public toggleAccordion(headingElem:HTMLElement) {
		const containerElem = headingElem.parentElement;
		const hasAccordionParent = containerElem.classList.contains(selectorClass.substring(1));
		if (!hasAccordionParent) {
			return;
		}

		if (this.hasActiveClass(containerElem)) {
			this.closeAccordion(containerElem, false);
		} else {
			this.openAccordion(containerElem, false);
		}

		this.saveAllActive();
	}

	private closeAccordion(containerElem:HTMLElement, saveState:boolean=true) {
		containerElem.classList.remove(activeClass);
		this.updateHeight(containerElem, 0);
		if (saveState) {
			this.saveAllActive();
		}
	}
	private openAccordion(containerElem:HTMLElement, saveState:boolean=true) {
		containerElem.classList.add(activeClass);
		this.updateHeight(containerElem);
		if (saveState) {
			this.saveAllActive();
		}
	}

	private updateAllHeights() {
		const accordions = this.getAllAccordions();
		for (let i = 0; i < accordions.length; i++) {
			const accordion = accordions[i] as HTMLElement;
			if (this.hasActiveClass(accordion)) {
				this.updateHeight(accordion);
			} else {
				this.updateHeight(accordion, 0);
			}
		}
	}
	private updateHeight(containerElem:HTMLElement, height:number = null) {
		let bodyWrapElem:HTMLElement = containerElem.querySelector(".accordion__bodyWrap");
		let bodyElem:HTMLElement = bodyWrapElem.querySelector(".accordion__body");

		// Determine new height
		let newHeight:number = height === null
			? bodyElem.scrollHeight
			: 0;

		// Set speed of transition (if support by browser, otherwise it will fallback to what is in the CSS)
		if (bodyWrapElem.style.transitionDuration !== undefined) {
			let currentHeight = parseInt(window.getComputedStyle(bodyWrapElem).height);
			let transitionOption = {
				speed : 1.2,
				minDuration : 250,
				maxDuration : 750
			};

			let duration:number = 0;
			let heightDelta = Math.abs(newHeight - currentHeight);
			if (heightDelta !== 0) {
				duration = NumberUtil.limit(
					heightDelta * transitionOption.speed,
					transitionOption.minDuration,
					transitionOption.maxDuration
				);
			}
			
			bodyWrapElem.style.transitionDuration = NumberUtil.round(duration) + 'ms';
		}

		// Update height of element
		bodyWrapElem.style.height = newHeight + 'px';
	}
	

	/**
	 * Open all active accordions from value saved in localStorage.
	 */
	private restoreAccordionStates() {
		let activeAccordions = PersistenceManager.readArray(persistenceKey);

		// Default open values if nothing saved to local storage
		if (activeAccordions === null) {
		    activeAccordions = [0, 1, 2, 3, 4];
		    PersistenceManager.saveArray(persistenceKey, activeAccordions);
		}

		if (activeAccordions) {
			const accordions = this.getAllAccordions();
			for (let i = 0; i < accordions.length; i++) {
				const accordion = accordions[i] as HTMLElement;
				if (activeAccordions.indexOf(i) !== -1) {
					this.openAccordion(accordion, false);
				} else {
					this.closeAccordion(accordion, false);
				}
			}
		}
	}

	/**
	 * Update localStorage with the current active accordions.
	 */
	private saveAllActive() {
		const accordions = this.getAllAccordions();
		let activeAccordions = new Array<number>();

		for (let i = 0; i < accordions.length; i++)  {
			const accordion = accordions[i] as HTMLElement;
			if (this.hasActiveClass(accordion)){
				activeAccordions.push(i);
			}
		}

		PersistenceManager.saveArray(persistenceKey, activeAccordions);
	}

	private getAllAccordions() {
		return document.querySelectorAll(selectorClass);
	}

	/**
	 * Helper class to determine active state.
	 * @param accordion
	 */
	private hasActiveClass(accordion:HTMLElement):boolean {
		return accordion.classList.contains(activeClass);
	}
}

export default new Accordions();
