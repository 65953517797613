module.exports={
  "main": {
    "hy": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "hy"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn",
          "traditional": "armn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "ՈչԹ",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 հազար",
              "1000-count-other": "0 հազար",
              "10000-count-one": "00 հազար",
              "10000-count-other": "00 հազար",
              "100000-count-one": "000 հազար",
              "100000-count-other": "000 հազար",
              "1000000-count-one": "0 միլիոն",
              "1000000-count-other": "0 միլիոն",
              "10000000-count-one": "00 միլիոն",
              "10000000-count-other": "00 միլիոն",
              "100000000-count-one": "000 միլիոն",
              "100000000-count-other": "000 միլիոն",
              "1000000000-count-one": "0 միլիարդ",
              "1000000000-count-other": "0 միլիարդ",
              "10000000000-count-one": "00 միլիարդ",
              "10000000000-count-other": "00 միլիարդ",
              "100000000000-count-one": "000 միլիարդ",
              "100000000000-count-other": "000 միլիարդ",
              "1000000000000-count-one": "0 տրիլիոն",
              "1000000000000-count-other": "0 տրիլիոն",
              "10000000000000-count-one": "00 տրիլիոն",
              "10000000000000-count-other": "00 տրիլիոն",
              "100000000000000-count-one": "000 տրիլիոն",
              "100000000000000-count-other": "000 տրիլիոն"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 հզր",
              "1000-count-other": "0 հզր",
              "10000-count-one": "00 հզր",
              "10000-count-other": "00 հզր",
              "100000-count-one": "000 հզր",
              "100000-count-other": "000 հզր",
              "1000000-count-one": "0 մլն",
              "1000000-count-other": "0 մլն",
              "10000000-count-one": "00 մլն",
              "10000000-count-other": "00 մլն",
              "100000000-count-one": "000 մլն",
              "100000000-count-other": "000 մլն",
              "1000000000-count-one": "0 մլրդ",
              "1000000000-count-other": "0 մլրդ",
              "10000000000-count-one": "00 մլրդ",
              "10000000000-count-other": "00 մլրդ",
              "100000000000-count-one": "000 մլրդ",
              "100000000000-count-other": "000 մլրդ",
              "1000000000000-count-one": "0 տրլն",
              "1000000000000-count-other": "0 տրլն",
              "10000000000000-count-one": "00 տրլն",
              "10000000000000-count-other": "00 տրլն",
              "100000000000000-count-one": "000 տրլն",
              "100000000000000-count-other": "000 տրլն"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 հզր ¤",
              "1000-count-other": "0 հզր ¤",
              "10000-count-one": "00 հզր ¤",
              "10000-count-other": "00 հզր ¤",
              "100000-count-one": "000 հզր ¤",
              "100000-count-other": "000 հզր ¤",
              "1000000-count-one": "0 մլն ¤",
              "1000000-count-other": "0 մլն ¤",
              "10000000-count-one": "00 մլն ¤",
              "10000000-count-other": "00 մլն ¤",
              "100000000-count-one": "000 մլն ¤",
              "100000000-count-other": "000 մլն ¤",
              "1000000000-count-one": "0 մլրդ ¤",
              "1000000000-count-other": "0 մլրդ ¤",
              "10000000000-count-one": "00 մլրդ ¤",
              "10000000000-count-other": "00 մլրդ ¤",
              "100000000000-count-one": "000 մլրդ ¤",
              "100000000000-count-other": "000 մլրդ ¤",
              "1000000000000-count-one": "0 տրլն ¤",
              "1000000000000-count-other": "0 տրլն ¤",
              "10000000000000-count-one": "00 տրլն ¤",
              "10000000000000-count-other": "00 տրլն ¤",
              "100000000000000-count-one": "000 տրլն ¤",
              "100000000000000-count-other": "000 տրլն ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "այդ {0} ժամը",
          "pluralMinimalPairs-count-other": "այդ {0} ժամերը",
          "one": "Թեքվեք աջ {0}-ին խաչմերուկից:",
          "other": "Թեքվեք աջ {0}-րդ խաչմերուկից:"
        }
      }
    }
  }
}
