module.exports={
  "main": {
    "uk": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "uk"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "Е",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 тисяча",
              "1000-count-few": "0 тисячі",
              "1000-count-many": "0 тисяч",
              "1000-count-other": "0 тисячі",
              "10000-count-one": "00 тисяча",
              "10000-count-few": "00 тисячі",
              "10000-count-many": "00 тисяч",
              "10000-count-other": "00 тисячі",
              "100000-count-one": "000 тисяча",
              "100000-count-few": "000 тисячі",
              "100000-count-many": "000 тисяч",
              "100000-count-other": "000 тисячі",
              "1000000-count-one": "0 мільйон",
              "1000000-count-few": "0 мільйони",
              "1000000-count-many": "0 мільйонів",
              "1000000-count-other": "0 мільйона",
              "10000000-count-one": "00 мільйон",
              "10000000-count-few": "00 мільйони",
              "10000000-count-many": "00 мільйонів",
              "10000000-count-other": "00 мільйона",
              "100000000-count-one": "000 мільйон",
              "100000000-count-few": "000 мільйони",
              "100000000-count-many": "000 мільйонів",
              "100000000-count-other": "000 мільйона",
              "1000000000-count-one": "0 мільярд",
              "1000000000-count-few": "0 мільярди",
              "1000000000-count-many": "0 мільярдів",
              "1000000000-count-other": "0 мільярда",
              "10000000000-count-one": "00 мільярд",
              "10000000000-count-few": "00 мільярди",
              "10000000000-count-many": "00 мільярдів",
              "10000000000-count-other": "00 мільярда",
              "100000000000-count-one": "000 мільярд",
              "100000000000-count-few": "000 мільярди",
              "100000000000-count-many": "000 мільярдів",
              "100000000000-count-other": "000 мільярда",
              "1000000000000-count-one": "0 трильйон",
              "1000000000000-count-few": "0 трильйони",
              "1000000000000-count-many": "0 трильйонів",
              "1000000000000-count-other": "0 трильйона",
              "10000000000000-count-one": "00 трильйон",
              "10000000000000-count-few": "00 трильйони",
              "10000000000000-count-many": "00 трильйонів",
              "10000000000000-count-other": "00 трильйона",
              "100000000000000-count-one": "000 трильйон",
              "100000000000000-count-few": "000 трильйони",
              "100000000000000-count-many": "000 трильйонів",
              "100000000000000-count-other": "000 трильйона"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 тис'.'",
              "1000-count-few": "0 тис'.'",
              "1000-count-many": "0 тис'.'",
              "1000-count-other": "0 тис'.'",
              "10000-count-one": "00 тис'.'",
              "10000-count-few": "00 тис'.'",
              "10000-count-many": "00 тис'.'",
              "10000-count-other": "00 тис'.'",
              "100000-count-one": "000 тис'.'",
              "100000-count-few": "000 тис'.'",
              "100000-count-many": "000 тис'.'",
              "100000-count-other": "000 тис'.'",
              "1000000-count-one": "0 млн",
              "1000000-count-few": "0 млн",
              "1000000-count-many": "0 млн",
              "1000000-count-other": "0 млн",
              "10000000-count-one": "00 млн",
              "10000000-count-few": "00 млн",
              "10000000-count-many": "00 млн",
              "10000000-count-other": "00 млн",
              "100000000-count-one": "000 млн",
              "100000000-count-few": "000 млн",
              "100000000-count-many": "000 млн",
              "100000000-count-other": "000 млн",
              "1000000000-count-one": "0 млрд",
              "1000000000-count-few": "0 млрд",
              "1000000000-count-many": "0 млрд",
              "1000000000-count-other": "0 млрд",
              "10000000000-count-one": "00 млрд",
              "10000000000-count-few": "00 млрд",
              "10000000000-count-many": "00 млрд",
              "10000000000-count-other": "00 млрд",
              "100000000000-count-one": "000 млрд",
              "100000000000-count-few": "000 млрд",
              "100000000000-count-many": "000 млрд",
              "100000000000-count-other": "000 млрд",
              "1000000000000-count-one": "0 трлн",
              "1000000000000-count-few": "0 трлн",
              "1000000000000-count-many": "0 трлн",
              "1000000000000-count-other": "0 трлн",
              "10000000000000-count-one": "00 трлн",
              "10000000000000-count-few": "00 трлн",
              "10000000000000-count-many": "00 трлн",
              "10000000000000-count-other": "00 трлн",
              "100000000000000-count-one": "000 трлн",
              "100000000000000-count-few": "000 трлн",
              "100000000000000-count-many": "000 трлн",
              "100000000000000-count-other": "000 трлн"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤",
          "short": {
            "standard": {
              "1000-count-one": "0 тис'.' ¤",
              "1000-count-few": "0 тис'.' ¤",
              "1000-count-many": "0 тис'.' ¤",
              "1000-count-other": "0 тис'.' ¤",
              "10000-count-one": "00 тис'.' ¤",
              "10000-count-few": "00 тис'.' ¤",
              "10000-count-many": "00 тис'.' ¤",
              "10000-count-other": "00 тис'.' ¤",
              "100000-count-one": "000 тис'.' ¤",
              "100000-count-few": "000 тис'.' ¤",
              "100000-count-many": "000 тис'.' ¤",
              "100000-count-other": "000 тис'.' ¤",
              "1000000-count-one": "0 млн ¤",
              "1000000-count-few": "0 млн ¤",
              "1000000-count-many": "0 млн ¤",
              "1000000-count-other": "0 млн ¤",
              "10000000-count-one": "00 млн ¤",
              "10000000-count-few": "00 млн ¤",
              "10000000-count-many": "00 млн ¤",
              "10000000-count-other": "00 млн ¤",
              "100000000-count-one": "000 млн ¤",
              "100000000-count-few": "000 млн ¤",
              "100000000-count-many": "000 млн ¤",
              "100000000-count-other": "000 млн ¤",
              "1000000000-count-one": "0 млрд ¤",
              "1000000000-count-few": "0 млрд ¤",
              "1000000000-count-many": "0 млрд ¤",
              "1000000000-count-other": "0 млрд ¤",
              "10000000000-count-one": "00 млрд ¤",
              "10000000000-count-few": "00 млрд ¤",
              "10000000000-count-many": "00 млрд ¤",
              "10000000000-count-other": "00 млрд ¤",
              "100000000000-count-one": "000 млрд ¤",
              "100000000000-count-few": "000 млрд ¤",
              "100000000000-count-many": "000 млрд ¤",
              "100000000000-count-other": "000 млрд ¤",
              "1000000000000-count-one": "0 трлн ¤",
              "1000000000000-count-few": "0 трлн ¤",
              "1000000000000-count-many": "0 трлн ¤",
              "1000000000000-count-other": "0 трлн ¤",
              "10000000000000-count-one": "00 трлн ¤",
              "10000000000000-count-few": "00 трлн ¤",
              "10000000000000-count-many": "00 трлн ¤",
              "10000000000000-count-other": "00 трлн ¤",
              "100000000000000-count-one": "000 трлн ¤",
              "100000000000000-count-few": "000 трлн ¤",
              "100000000000000-count-many": "000 трлн ¤",
              "100000000000000-count-other": "000 трлн ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-many": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} день",
          "pluralMinimalPairs-count-few": "{0} дні",
          "pluralMinimalPairs-count-many": "{0} днів",
          "pluralMinimalPairs-count-other": "{0} дня",
          "few": "{0}-я дивізія, {0}-є коло",
          "other": "{0}-а дивізія, {0}-е коло"
        }
      }
    }
  }
}
