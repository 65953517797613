module.exports={
  "main": {
    "sq": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "sq"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "2",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": " ",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 mijë",
              "1000-count-other": "0 mijë",
              "10000-count-one": "00 mijë",
              "10000-count-other": "00 mijë",
              "100000-count-one": "000 mijë",
              "100000-count-other": "000 mijë",
              "1000000-count-one": "0 milion",
              "1000000-count-other": "0 milion",
              "10000000-count-one": "00 milion",
              "10000000-count-other": "00 milion",
              "100000000-count-one": "000 milion",
              "100000000-count-other": "000 milion",
              "1000000000-count-one": "0 miliard",
              "1000000000-count-other": "0 miliard",
              "10000000000-count-one": "00 miliard",
              "10000000000-count-other": "00 miliard",
              "100000000000-count-one": "000 miliard",
              "100000000000-count-other": "000 miliard",
              "1000000000000-count-one": "0 bilion",
              "1000000000000-count-other": "0 bilion",
              "10000000000000-count-one": "00 bilion",
              "10000000000000-count-other": "00 bilion",
              "100000000000000-count-one": "000 bilion",
              "100000000000000-count-other": "000 bilion"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 mijë",
              "1000-count-other": "0 mijë",
              "10000-count-one": "00 mijë",
              "10000-count-other": "00 mijë",
              "100000-count-one": "000 mijë",
              "100000-count-other": "000 mijë",
              "1000000-count-one": "0 mln",
              "1000000-count-other": "0 mln",
              "10000000-count-one": "00 mln",
              "10000000-count-other": "00 mln",
              "100000000-count-one": "000 mln",
              "100000000-count-other": "000 mln",
              "1000000000-count-one": "0 mld",
              "1000000000-count-other": "0 mld",
              "10000000000-count-one": "00 mld",
              "10000000000-count-other": "00 mld",
              "100000000000-count-one": "000 mld",
              "100000000000-count-other": "000 mld",
              "1000000000000-count-one": "0 bln",
              "1000000000000-count-other": "0 bln",
              "10000000000000-count-one": "00 bln",
              "10000000000000-count-other": "00 bln",
              "100000000000000-count-one": "000 bln",
              "100000000000000-count-other": "000 bln"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 mijë ¤",
              "1000-count-other": "0 mijë ¤",
              "10000-count-one": "00 mijë ¤",
              "10000-count-other": "00 mijë ¤",
              "100000-count-one": "000 mijë ¤",
              "100000-count-other": "000 mijë ¤",
              "1000000-count-one": "0 mln ¤",
              "1000000-count-other": "0 mln ¤",
              "10000000-count-one": "00 mln ¤",
              "10000000-count-other": "00 mln ¤",
              "100000000-count-one": "000 mln ¤",
              "100000000-count-other": "000 mln ¤",
              "1000000000-count-one": "0 mld ¤",
              "1000000000-count-other": "0 mld ¤",
              "10000000000-count-one": "00 mld ¤",
              "10000000000-count-other": "00 mld ¤",
              "100000000000-count-one": "000 mld ¤",
              "100000000000-count-other": "000 mld ¤",
              "1000000000000-count-one": "0 bln ¤",
              "1000000000000-count-other": "0 bln ¤",
              "10000000000000-count-one": "00 bln ¤",
              "10000000000000-count-other": "00 bln ¤",
              "100000000000000-count-one": "000 bln ¤",
              "100000000000000-count-other": "000 bln ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "≈{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} libër",
          "pluralMinimalPairs-count-other": "{0} libra",
          "many": "Merrni kthesën e {0}-t në të djathtë.",
          "one": "Merrni kthesën e {0}-rë në të djathtë.",
          "other": "Merrni kthesën e {0}-të në të djathtë."
        }
      }
    }
  }
}
