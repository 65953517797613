module.exports={
  "main": {
    "sl": {
      "identity": {
        "version": {
          "_number": "$Revision: 14842 $",
          "_cldrVersion": "35.1"
        },
        "language": "sl"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ",",
          "group": ".",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "−",
          "exponential": "e",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 tisoč",
              "1000-count-two": "0 tisoč",
              "1000-count-few": "0 tisoč",
              "1000-count-other": "0 tisoč",
              "10000-count-one": "00 tisoč",
              "10000-count-two": "00 tisoč",
              "10000-count-few": "00 tisoč",
              "10000-count-other": "00 tisoč",
              "100000-count-one": "000 tisoč",
              "100000-count-two": "000 tisoč",
              "100000-count-few": "000 tisoč",
              "100000-count-other": "000 tisoč",
              "1000000-count-one": "0 milijon",
              "1000000-count-two": "0 milijona",
              "1000000-count-few": "0 milijone",
              "1000000-count-other": "0 milijonov",
              "10000000-count-one": "00 milijon",
              "10000000-count-two": "00 milijona",
              "10000000-count-few": "00 milijoni",
              "10000000-count-other": "00 milijonov",
              "100000000-count-one": "000 milijon",
              "100000000-count-two": "000 milijona",
              "100000000-count-few": "000 milijoni",
              "100000000-count-other": "000 milijonov",
              "1000000000-count-one": "0 milijarda",
              "1000000000-count-two": "0 milijardi",
              "1000000000-count-few": "0 milijarde",
              "1000000000-count-other": "0 milijard",
              "10000000000-count-one": "00 milijarda",
              "10000000000-count-two": "00 milijardi",
              "10000000000-count-few": "00 milijarde",
              "10000000000-count-other": "00 milijard",
              "100000000000-count-one": "000 milijarda",
              "100000000000-count-two": "000 milijardi",
              "100000000000-count-few": "000 milijarde",
              "100000000000-count-other": "000 milijard",
              "1000000000000-count-one": "0 bilijon",
              "1000000000000-count-two": "0 bilijona",
              "1000000000000-count-few": "0 bilijoni",
              "1000000000000-count-other": "0 bilijonov",
              "10000000000000-count-one": "00 bilijon",
              "10000000000000-count-two": "00 bilijona",
              "10000000000000-count-few": "00 bilijoni",
              "10000000000000-count-other": "00 bilijonov",
              "100000000000000-count-one": "000 bilijon",
              "100000000000000-count-two": "000 bilijona",
              "100000000000000-count-few": "000 bilijoni",
              "100000000000000-count-other": "000 bilijonov"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 tis'.'",
              "1000-count-two": "0 tis'.'",
              "1000-count-few": "0 tis'.'",
              "1000-count-other": "0 tis'.'",
              "10000-count-one": "00 tis'.'",
              "10000-count-two": "00 tis'.'",
              "10000-count-few": "00 tis'.'",
              "10000-count-other": "00 tis'.'",
              "100000-count-one": "000 tis'.'",
              "100000-count-two": "000 tis'.'",
              "100000-count-few": "000 tis'.'",
              "100000-count-other": "000 tis'.'",
              "1000000-count-one": "0 mio'.'",
              "1000000-count-two": "0 mio'.'",
              "1000000-count-few": "0 mio'.'",
              "1000000-count-other": "0 mio'.'",
              "10000000-count-one": "00 mio'.'",
              "10000000-count-two": "00 mio'.'",
              "10000000-count-few": "00 mio'.'",
              "10000000-count-other": "00 mio'.'",
              "100000000-count-one": "000 mio'.'",
              "100000000-count-two": "000 mio'.'",
              "100000000-count-few": "000 mio'.'",
              "100000000-count-other": "000 mio'.'",
              "1000000000-count-one": "0 mrd'.'",
              "1000000000-count-two": "0 mrd'.'",
              "1000000000-count-few": "0 mrd'.'",
              "1000000000-count-other": "0 mrd'.'",
              "10000000000-count-one": "00 mrd'.'",
              "10000000000-count-two": "00 mrd'.'",
              "10000000000-count-few": "00 mrd'.'",
              "10000000000-count-other": "00 mrd'.'",
              "100000000000-count-one": "000 mrd'.'",
              "100000000000-count-two": "000 mrd'.'",
              "100000000000-count-few": "000 mrd'.'",
              "100000000000-count-other": "000 mrd'.'",
              "1000000000000-count-one": "0 bil'.'",
              "1000000000000-count-two": "0 bil'.'",
              "1000000000000-count-few": "0 bil'.'",
              "1000000000000-count-other": "0 bil'.'",
              "10000000000000-count-one": "00 bil'.'",
              "10000000000000-count-two": "00 bil'.'",
              "10000000000000-count-few": "00 bil'.'",
              "10000000000000-count-other": "00 bil'.'",
              "100000000000000-count-one": "000 bil'.'",
              "100000000000000-count-two": "000 bil'.'",
              "100000000000000-count-few": "000 bil'.'",
              "100000000000000-count-other": "000 bil'.'"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0 %"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "#,##0.00 ¤",
          "accounting": "#,##0.00 ¤;(#,##0.00 ¤)",
          "short": {
            "standard": {
              "1000-count-one": "0 tis'.' ¤",
              "1000-count-two": "0 tis'.' ¤",
              "1000-count-few": "0 tis'.' ¤",
              "1000-count-other": "0 tis'.' ¤",
              "10000-count-one": "00 tis'.' ¤",
              "10000-count-two": "00 tis'.' ¤",
              "10000-count-few": "00 tis'.' ¤",
              "10000-count-other": "00 tis'.' ¤",
              "100000-count-one": "000 tis'.' ¤",
              "100000-count-two": "000 tis'.' ¤",
              "100000-count-few": "000 tis'.' ¤",
              "100000-count-other": "000 tis'.' ¤",
              "1000000-count-one": "0 mio'.' ¤",
              "1000000-count-two": "0 mio'.' ¤",
              "1000000-count-few": "0 mio'.' ¤",
              "1000000-count-other": "0 mio'.' ¤",
              "10000000-count-one": "00 mio'.' ¤",
              "10000000-count-two": "00 mio'.' ¤",
              "10000000-count-few": "00 mio'.' ¤",
              "10000000-count-other": "00 mio'.' ¤",
              "100000000-count-one": "000 mio'.' ¤",
              "100000000-count-two": "000 mio'.' ¤",
              "100000000-count-few": "000 mio'.' ¤",
              "100000000-count-other": "000 mio'.' ¤",
              "1000000000-count-one": "0 mrd'.' ¤",
              "1000000000-count-two": "0 mrd'.' ¤",
              "1000000000-count-few": "0 mrd'.' ¤",
              "1000000000-count-other": "0 mrd'.' ¤",
              "10000000000-count-one": "00 mrd'.' ¤",
              "10000000000-count-two": "00 mrd'.' ¤",
              "10000000000-count-few": "00 mrd'.' ¤",
              "10000000000-count-other": "00 mrd'.' ¤",
              "100000000000-count-one": "000 mrd'.' ¤",
              "100000000000-count-two": "000 mrd'.' ¤",
              "100000000000-count-few": "000 mrd'.' ¤",
              "100000000000-count-other": "000 mrd'.' ¤",
              "1000000000000-count-one": "0 bil'.' ¤",
              "1000000000000-count-two": "0 bil'.' ¤",
              "1000000000000-count-few": "0 bil'.' ¤",
              "1000000000000-count-other": "0 bil'.' ¤",
              "10000000000000-count-one": "00 bil'.' ¤",
              "10000000000000-count-two": "00 bil'.' ¤",
              "10000000000000-count-few": "00 bil'.' ¤",
              "10000000000000-count-other": "00 bil'.' ¤",
              "100000000000000-count-one": "000 bil'.' ¤",
              "100000000000000-count-two": "000 bil'.' ¤",
              "100000000000000-count-few": "000 bil'.' ¤",
              "100000000000000-count-other": "000 bil'.' ¤"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-two": "{0} {1}",
          "unitPattern-count-few": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} ura",
          "pluralMinimalPairs-count-two": "{0} uri",
          "pluralMinimalPairs-count-few": "{0} ure",
          "pluralMinimalPairs-count-other": "{0} ur",
          "other": "V {0}. križišču zavijte desno."
        }
      }
    }
  }
}
