module.exports={
  "main": {
    "th": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "th"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "thai"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "symbols-numberSystem-thai": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0 พัน",
              "10000-count-other": "0 หมื่น",
              "100000-count-other": "0 แสน",
              "1000000-count-other": "0 ล้าน",
              "10000000-count-other": "00 ล้าน",
              "100000000-count-other": "000 ล้าน",
              "1000000000-count-other": "0 พันล้าน",
              "10000000000-count-other": "0 หมื่นล้าน",
              "100000000000-count-other": "0 แสนล้าน",
              "1000000000000-count-other": "0 ล้านล้าน",
              "10000000000000-count-other": "00 ล้านล้าน",
              "100000000000000-count-other": "000 ล้านล้าน"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0K",
              "10000-count-other": "00K",
              "100000-count-other": "000K",
              "1000000-count-other": "0M",
              "10000000-count-other": "00M",
              "100000000-count-other": "000M",
              "1000000000-count-other": "0B",
              "10000000000-count-other": "00B",
              "100000000000-count-other": "000B",
              "1000000000000-count-other": "0T",
              "10000000000000-count-other": "00T",
              "100000000000000-count-other": "000T"
            }
          }
        },
        "decimalFormats-numberSystem-thai": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-other": "0 พัน",
              "10000-count-other": "0 หมื่น",
              "100000-count-other": "0 แสน",
              "1000000-count-other": "0 ล้าน",
              "10000000-count-other": "00 ล้าน",
              "100000000-count-other": "000 ล้าน",
              "1000000000-count-other": "0 พันล้าน",
              "10000000000-count-other": "0 หมื่นล้าน",
              "100000000000-count-other": "0 แสนล้าน",
              "1000000000000-count-other": "0 ล้านล้าน",
              "10000000000000-count-other": "00 ล้านล้าน",
              "100000000000000-count-other": "000 ล้านล้าน"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-other": "0K",
              "10000-count-other": "00K",
              "100000-count-other": "000K",
              "1000000-count-other": "0M",
              "10000000-count-other": "00M",
              "100000000-count-other": "000M",
              "1000000000-count-other": "0B",
              "10000000000-count-other": "00B",
              "100000000000-count-other": "000B",
              "1000000000000-count-other": "0T",
              "10000000000000-count-other": "00T",
              "100000000000000-count-other": "000T"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "scientificFormats-numberSystem-thai": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "percentFormats-numberSystem-thai": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-other": "¤0K",
              "10000-count-other": "¤00K",
              "100000-count-other": "¤000K",
              "1000000-count-other": "¤0M",
              "10000000-count-other": "¤00M",
              "100000000-count-other": "¤000M",
              "1000000000-count-other": "¤0B",
              "10000000000-count-other": "¤00B",
              "100000000000-count-other": "¤000B",
              "1000000000000-count-other": "¤0T",
              "10000000000000-count-other": "¤00T",
              "100000000000000-count-other": "¤000T"
            }
          },
          "unitPattern-count-other": "{0} {1}"
        },
        "currencyFormats-numberSystem-thai": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-other": "¤0K",
              "10000-count-other": "¤00K",
              "100000-count-other": "¤000K",
              "1000000-count-other": "¤0M",
              "10000000-count-other": "¤00M",
              "100000000-count-other": "¤000M",
              "1000000000-count-other": "¤0B",
              "10000000000-count-other": "¤00B",
              "100000000000-count-other": "¤000B",
              "1000000000000-count-other": "¤0T",
              "10000000000000-count-other": "¤00T",
              "100000000000000-count-other": "¤000T"
            }
          },
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "miscPatterns-numberSystem-thai": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}-{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-other": "{0} วัน",
          "other": "เลี้ยวขวาที่ทางเลี้ยวที่ {0}"
        }
      }
    }
  }
}
