module.exports={
  "main": {
    "am": {
      "identity": {
        "version": {
          "_number": "$Revision: 14982 $",
          "_cldrVersion": "35.1"
        },
        "language": "am"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn",
          "traditional": "ethi"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 ሺ",
              "1000-count-other": "0 ሺ",
              "10000-count-one": "00 ሺ",
              "10000-count-other": "00 ሺ",
              "100000-count-one": "000 ሺ",
              "100000-count-other": "000 ሺ",
              "1000000-count-one": "0 ሚሊዮን",
              "1000000-count-other": "0 ሚሊዮን",
              "10000000-count-one": "00 ሚሊዮን",
              "10000000-count-other": "00 ሚሊዮን",
              "100000000-count-one": "000 ሚሊዮን",
              "100000000-count-other": "000 ሚሊዮን",
              "1000000000-count-one": "0 ቢሊዮን",
              "1000000000-count-other": "0 ቢሊዮን",
              "10000000000-count-one": "00 ቢሊዮን",
              "10000000000-count-other": "00 ቢሊዮን",
              "100000000000-count-one": "000 ቢሊዮን",
              "100000000000-count-other": "000 ቢሊዮን",
              "1000000000000-count-one": "0 ትሪሊዮን",
              "1000000000000-count-other": "0 ትሪሊዮን",
              "10000000000000-count-one": "00 ትሪሊዮን",
              "10000000000000-count-other": "00 ትሪሊዮን",
              "100000000000000-count-one": "000 ትሪሊዮን",
              "100000000000000-count-other": "000 ትሪሊዮን"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 ሺ",
              "1000-count-other": "0 ሺ",
              "10000-count-one": "00 ሺ",
              "10000-count-other": "00 ሺ",
              "100000-count-one": "000 ሺ",
              "100000-count-other": "000 ሺ",
              "1000000-count-one": "0 ሜትር",
              "1000000-count-other": "0 ሜትር",
              "10000000-count-one": "00 ሜትር",
              "10000000-count-other": "00 ሜትር",
              "100000000-count-one": "000ሜ",
              "100000000-count-other": "000ሜ",
              "1000000000-count-one": "0 ቢ",
              "1000000000-count-other": "0 ቢ",
              "10000000000-count-one": "00 ቢ",
              "10000000000-count-other": "00 ቢ",
              "100000000000-count-one": "000 ቢ",
              "100000000000-count-other": "000 ቢ",
              "1000000000000-count-one": "0 ት",
              "1000000000000-count-other": "0 ት",
              "10000000000000-count-one": "00 ት",
              "10000000000000-count-other": "00 ት",
              "100000000000000-count-one": "000 ት",
              "100000000000000-count-other": "000 ት"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤#,##0.00",
          "accounting": "¤#,##0.00;(¤#,##0.00)",
          "short": {
            "standard": {
              "1000-count-one": "¤0 ሺ",
              "1000-count-other": "¤0 ሺ",
              "10000-count-one": "¤00 ሺ",
              "10000-count-other": "¤00 ሺ",
              "100000-count-one": "¤000 ሺ",
              "100000-count-other": "¤000 ሺ",
              "1000000-count-one": "¤0 ሜትር",
              "1000000-count-other": "¤0 ሜትር",
              "10000000-count-one": "¤00 ሜትር",
              "10000000-count-other": "¤00 ሜትር",
              "100000000-count-one": "¤000 ሜትር",
              "100000000-count-other": "¤000 ሜትር",
              "1000000000-count-one": "¤0 ቢ",
              "1000000000-count-other": "¤0 ቢ",
              "10000000000-count-one": "¤00 ቢ",
              "10000000000-count-other": "¤00 ቢ",
              "100000000000-count-one": "¤000 ቢ",
              "100000000000-count-other": "¤000 ቢ",
              "1000000000000-count-one": "¤0 ት",
              "1000000000000-count-other": "¤0 ት",
              "10000000000000-count-one": "¤00 ት",
              "10000000000000-count-other": "¤00 ት",
              "100000000000000-count-one": "¤000 ት",
              "100000000000000-count-other": "¤000 ት"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "{0}+",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "{0} ቀን",
          "pluralMinimalPairs-count-other": "{0} ቀናት",
          "other": "በቀኝ በኩል ባለው በ{0}ኛው መታጠፊያ ግባ።"
        }
      }
    }
  }
}
