module.exports={
  "main": {
    "mn": {
      "identity": {
        "version": {
          "_number": "$Revision: 14817 $",
          "_cldrVersion": "35.1"
        },
        "language": "mn"
      },
      "numbers": {
        "defaultNumberingSystem": "latn",
        "otherNumberingSystems": {
          "native": "latn"
        },
        "minimumGroupingDigits": "1",
        "symbols-numberSystem-latn": {
          "decimal": ".",
          "group": ",",
          "list": ";",
          "percentSign": "%",
          "plusSign": "+",
          "minusSign": "-",
          "exponential": "E",
          "superscriptingExponent": "×",
          "perMille": "‰",
          "infinity": "∞",
          "nan": "NaN",
          "timeSeparator": ":"
        },
        "decimalFormats-numberSystem-latn": {
          "standard": "#,##0.###",
          "long": {
            "decimalFormat": {
              "1000-count-one": "0 мянга",
              "1000-count-other": "0 мянга",
              "10000-count-one": "00 мянга",
              "10000-count-other": "00 мянга",
              "100000-count-one": "000 мянга",
              "100000-count-other": "000 мянга",
              "1000000-count-one": "0 сая",
              "1000000-count-other": "0 сая",
              "10000000-count-one": "00 сая",
              "10000000-count-other": "00 сая",
              "100000000-count-one": "000 сая",
              "100000000-count-other": "000 сая",
              "1000000000-count-one": "0 тэрбум",
              "1000000000-count-other": "0 тэрбум",
              "10000000000-count-one": "00 тэрбум",
              "10000000000-count-other": "00 тэрбум",
              "100000000000-count-one": "000 тэрбум",
              "100000000000-count-other": "000 тэрбум",
              "1000000000000-count-one": "0 их наяд",
              "1000000000000-count-other": "0 их наяд",
              "10000000000000-count-one": "00 их наяд",
              "10000000000000-count-other": "00 их наяд",
              "100000000000000-count-one": "000 их наяд",
              "100000000000000-count-other": "000 их наяд"
            }
          },
          "short": {
            "decimalFormat": {
              "1000-count-one": "0 мянга",
              "1000-count-other": "0 мянга",
              "10000-count-one": "00 мянга",
              "10000-count-other": "00 мянга",
              "100000-count-one": "000 мянга",
              "100000-count-other": "000 мянга",
              "1000000-count-one": "0 сая",
              "1000000-count-other": "0 сая",
              "10000000-count-one": "00 сая",
              "10000000-count-other": "00 сая",
              "100000000-count-one": "000 сая",
              "100000000-count-other": "000 сая",
              "1000000000-count-one": "0 тэрбум",
              "1000000000-count-other": "0 тэрбум",
              "10000000000-count-one": "00 тэрбум",
              "10000000000-count-other": "00 тэрбум",
              "100000000000-count-one": "000Т",
              "100000000000-count-other": "000Т",
              "1000000000000-count-one": "0ИН",
              "1000000000000-count-other": "0ИН",
              "10000000000000-count-one": "00ИН",
              "10000000000000-count-other": "00ИН",
              "100000000000000-count-one": "000ИН",
              "100000000000000-count-other": "000ИН"
            }
          }
        },
        "scientificFormats-numberSystem-latn": {
          "standard": "#E0"
        },
        "percentFormats-numberSystem-latn": {
          "standard": "#,##0%"
        },
        "currencyFormats-numberSystem-latn": {
          "currencySpacing": {
            "beforeCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            },
            "afterCurrency": {
              "currencyMatch": "[:^S:]",
              "surroundingMatch": "[:digit:]",
              "insertBetween": " "
            }
          },
          "standard": "¤ #,##0.00",
          "accounting": "¤ #,##0.00",
          "short": {
            "standard": {
              "1000-count-one": "¤ 0 мянга",
              "1000-count-other": "¤ 0 мянга",
              "10000-count-one": "¤ 00 мянга",
              "10000-count-other": "¤ 00 мянга",
              "100000-count-one": "¤000 мянга",
              "100000-count-other": "¤000 мянга",
              "1000000-count-one": "¤0 сая",
              "1000000-count-other": "¤0 сая",
              "10000000-count-one": "¤00 сая",
              "10000000-count-other": "¤00 сая",
              "100000000-count-one": "¤000 сая",
              "100000000-count-other": "¤000 сая",
              "1000000000-count-one": "¤0 тэрбум",
              "1000000000-count-other": "¤0 тэрбум",
              "10000000000-count-one": "¤ 00 тэрбум",
              "10000000000-count-other": "¤ 00 тэрбум",
              "100000000000-count-one": "¤ 000 тэрбум",
              "100000000000-count-other": "¤ 000 тэрбум",
              "1000000000000-count-one": "¤ 0 их наяд",
              "1000000000000-count-other": "¤ 0 их наяд",
              "10000000000000-count-one": "¤ 00 их наяд",
              "10000000000000-count-other": "¤ 00 их наяд",
              "100000000000000-count-one": "¤ 000 их наяд",
              "100000000000000-count-other": "¤ 000 их наяд"
            }
          },
          "unitPattern-count-one": "{0} {1}",
          "unitPattern-count-other": "{0} {1}"
        },
        "miscPatterns-numberSystem-latn": {
          "approximately": "~{0}",
          "atLeast": "≥{0}",
          "atMost": "≤{0}",
          "range": "{0}–{1}"
        },
        "minimalPairs": {
          "pluralMinimalPairs-count-one": "Картанд {0} Х байна. Үүнийг авах уу?",
          "pluralMinimalPairs-count-other": "Картанд {0} Х байна. Тэднийг авах уу?",
          "other": "{0}-р баруун эргэлтээр орно уу"
        }
      }
    }
  }
}
